export const getStripeConnectUrlReducer = (state = null, action) => {

    if (action.type === 'STRIPE_CONNECT_URL_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'STRIPE_CONNECT_URL_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    }
    return state;
};
