import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const authenticateUserAction = (data) => async dispatch => {
    Logger.info("authenticate user with data ", data);

    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);

    let response = await RestApiService.post("/login", formData, {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    });
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("user details received successfully");
        dispatch({
            type: "USER_AUTHENTICATION_SUCCESS",
            payload: response.data
        });
    }
    else {
        Logger.info("error in getting user details [" + response.data + "]");
        dispatch({type: "USER_AUTHENTICATION_FAILED", payload: response.data});
    }

    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch({type: "HIDE_LOADER_SPINNER"});
};