import React, {Component} from 'react';

import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import {getAdminGoogleAuthUrlAction} from "../../actions/admin/get-admin-google-auth-login-url";
import {getAdminUserDetailsByGoogleAuthCode} from "../../actions/admin/get-user-details-from-google-auth-code";
import queryString from 'query-string';
import Logger from "../../core/util/Logger";


class AdminLogin extends Component {


    componentDidMount() {
        this.props.getAdminAuthUrl();
        let url = this.props.location.search;
        let params = queryString.parse(url);

        let code = params.code;

        if (code !== undefined) {
            Logger.info("making a request to get admin user details by google auth token [" + code + "]");
            this.props.getAdminUserDetailsByAuthCode(code);
        }
    }


    componentDidUpdate() {
        if (this.props.user.authenticated) {
            Logger.info("adding the token for the local-store");
            localStorage.setItem("user-token", this.props.user.data.auth.token);

            Logger.info("redirecting to admin user dashboard");
            this.props.history.push("/admin/home");
        }
    }


    render() {
        return (
            <div id="app">

                <LoadingSpinner/>

                <main className="py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div
                                    style={(this.props.user.data === undefined || this.props.user.data.message) === undefined ? {display: 'none'} : {display: 'block'}}
                                    className="alert alert-danger">

                                    {
                                        (this.props.user.data !== undefined
                                            && this.props.user.data.message) ? this.props.user.data.message : ''
                                    }

                                </div>


                                <div className="card">

                                    <div className="card-header">MacroActive Admin User Login</div>

                                    <div className="card-body">

                                        <div className="form-group row mb-0">
                                            <div className="col-md-8 offset-md-4">
                                                <a href={this.props.admin_auth.data !== undefined ? this.props.admin_auth.data.google_auth_url : ''}
                                                   className="btn btn-primary">
                                                    Login with Google
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        admin_auth: state.admin_auth,
        user: state.user
    }
};

let actions = {
    getAdminAuthUrl: getAdminGoogleAuthUrlAction,
    getAdminUserDetailsByAuthCode: getAdminUserDetailsByGoogleAuthCode
};

export default withRouter(connect(mapStateToProps, actions)(AdminLogin));