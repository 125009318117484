import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const sendNewMemberInvitationAction = (token, data) => async dispatch => {

    Logger.info("sending new member invitation to email [" + data.email + "] with  token [" + token + "]");

    let formData = new FormData();
    formData.append("subject", data.subject);
    formData.append("email", data.email);
    formData.append("name", data.name);
    formData.append("message", data.message);
    formData.append("instagram_handle", data.instagram_handle);
    formData.append("trello_card_url", data.trello_card);
    formData.append("service_fee", data.service_fee);
    formData.append("app_user", (data.app_customer === true ? 1 : 0));

    let response = await RestApiService.post("admin/members/invite", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    //wait for 2 seconds
    await new Promise(resolve => setTimeout(resolve, 2000));

    if (response.status === 200) {
        Logger.info("new member invitation sent");
        dispatch({
            type: "NEW_MEMBER_INVITATION_SUCCESS",
            payload: {
                message: "Invitation has been sent to " + data.email + " successfully!"
            }
        });
    } else {
        dispatch({
            type: "NEW_MEMBER_INVITATION_FAILED",
            payload: {
                message: "Error occurred while sending the member invitation. Please try again shortly"
            }
        });
    }
};