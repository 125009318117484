import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const getEmailForwardersListAction = (token, domainName) => async dispatch => {

    //wait for 3 seconds until the newly added email provider is updated in the name.com server
    await new Promise(resolve => setTimeout(resolve, 3000));

    let response = await RestApiService.get("/admin/domains/" + domainName + "/email-forwarders", {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("successfully got a list of email forwarders");
        dispatch({
            type: "EMAIL_FORWARDER_LIST_SUCCESS",
            payload: response.data
        });
    } else {
        Logger.info("error in getting email forwarders");
        dispatch({
            type: "EMAIL_FORWARDER_LIST_FAILED", payload: []
        });
    }
};