export const updateMemberSubDomainReducer = (state = null, action) => {

    if (action.type === 'MEMBER_DNS_UPDATE_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'MEMBER_DNS_UPDATE_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    } else if (action.type === 'MEMBER_DNS_UPDATE_RESET') {
        return null;
    }
    return state;
};
