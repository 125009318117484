import React, {Component} from 'react';
import './member-file-uploading.css';
import {uploadMemberFilesAction} from "../../actions/user/upload-member-files-action";
import {connect} from "react-redux";
import {resetReduxStoreAction} from "../../actions/reset-redux-store-action";
import {getFileUploadMenuDetailsAction} from "../../actions/user/get-file-upload-menu-details-action";
import {popupDisplayHandlerAction} from "../../actions/popup-display-action";

class FileCategorySampleImage extends Component {

    render() {
        return (
            <div>
                <div tabIndex="-1" role="dialog"
                     style={this.props.popup.data.id === 'file_category_sample_image_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                     className="modal fade show"
                     aria-modal="true">
                    <div className="overlay">
                        <div role="document" className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 id="exampleModalLabel" className="modal-title text-center">
                                        Sample Image
                                    </h5>

                                    <button type="button" data-dismiss="modal" aria-label="Close"
                                            onClick={() => this.setPopupVisibility(false)}
                                            className="close">
                                        <span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    <img src={this.props.category != null && this.props.category.sample_image}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-footer">
                    <button type="button"
                            onClick={() => this.setPopupVisibility(true)}
                            className="btn btn-primary">View Sample Image
                    </button>
                </div>
            </div>
        );
    }


    setPopupVisibility = (display) => {
        let payload = {
            id: "file_category_sample_image_popup",
            shouldDisplay: false
        };
        if (display) {
            payload.shouldDisplay = true;
        }
        this.props.handlePopupVisibility(payload);
    }
}


const actions = {
    uploadMemberFilesData: uploadMemberFilesAction,
    handlePopupVisibility: popupDisplayHandlerAction,
    resetReduxStore: resetReduxStoreAction,
    getFileUploadMenuDetailsForUser: getFileUploadMenuDetailsAction
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        popup: state.popup,
        uploaded_member_files: state.uploaded_member_files
    }
};


export default connect(mapStateToProps, actions)(FileCategorySampleImage);
