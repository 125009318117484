export const askHelpFromTechPersonReducer = (state = null, action) => {

    if (action.type === 'ASK_HELP_FROM_TECH_PERSON_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'ASK_HELP_FROM_TECH_PERSON_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    }
    else if (action.type === 'RESET_ASK_HELP_FROM_TECH_PERSON') {
        return null;
    }
    return state;
};
