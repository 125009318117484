import React, {Component} from 'react';
import AuthenticatedComponent from '../common/AuthenticatedComponent';
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from '../top-menu-bar/TopMenuBar';
import ReleaseTicker from "./ReleaseTicker";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getAllRegisteredMembersAction} from "../../actions/admin/get-registered-members-action";
import {getMemberProfileDetailsByUserIdAction} from "../../actions/admin/get-member-details-by-id-action";
import MemberList from './members/MembersList';
import Logger from "../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../actions/popup-display-action";
import InviteNewMember from "./invite-member/InviteNewMember";
import ArchiveMemberConfirmationPopup from "./members/ArchiveMemberConfirmationPopup";
import ActivateMemberConfirmationPopup from "./members/ActivateMemberConfirmationPopup";
import SelfMemberOnboard from "./self-onboarding/SelfMemberOnboard";
import {userLogoutAction} from "../../actions/logout-action";
import {ToastContainer} from "react-toastify";

class AdminDashboard extends Component {

    constructor() {
        super();
        this.state = {
            selected_filter: 'me',
            selected_status: 1
        };
    }

    render() {
        return (
            <div id="app">

                <AuthenticatedComponent/>

                <LoadingSpinner/>
                <ToastContainer/>

                <TopMenuBar/>
                <ReleaseTicker/>

                <InviteNewMember/>

                <SelfMemberOnboard/>

                <div className="container-fluid">
                    <div className="card mt-5 pl-5 pr-5 pb-5">
                        <div style={{padding: '10px'}}>

                            <div className={'filter-wrap'}>
                                <p className={'filter-title'}>Show trainers of</p>
                                <div className="form-group">
                                    <div className="btn-group" role="group"
                                         aria-label="Basic example">
                                        <a onClick={() => this.onClickFilterMembers('me', this.state.selected_status)}
                                           className={this.state.selected_filter === 'me' ? "btn btn-primary" : "btn btn-default second-btn"}>Me
                                        </a>
                                        <a onClick={() => this.onClickFilterMembers('all', this.state.selected_status)}
                                           className={this.state.selected_filter === 'all' ? "btn btn-primary" : "btn btn-default second-btn"}>All
                                            Managers
                                        </a>
                                    </div>
                                </div>
                                <br/>
                                <div className="form-group">
                                    <div className="form-check">
                                        <input
                                            onClick={(e) => this.onClickFilterMembers(this.state.selected_filter, ((e.target && e.target.checked === true) ? 0 : 1))}
                                            className="form-check-input" type="checkbox" value="0"
                                            id="archived" defaultChecked={this.state.selected_status == 0}/>
                                        <label className="form-check-label" htmlFor="archived">
                                            Archived
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <a className={'btn btn-primary new-member-btn'}
                               onClick={this.onClickInviteNewMember.bind(this)}>Invite Trainer</a>

                            <a className={'btn btn-primary new-member-btn mr-2'}
                               onClick={this.onClickShowSelfMemberInvitationForm.bind(this)}>Self Sign Up</a>

                        </div>

                        <main className="py-4">

                            <div
                                style={this.props.all_members !== null
                                && this.props.all_members.data.message !== undefined ? {display: 'block'} : {display: 'none'}}
                                className="alert alert-danger"
                                role="alert">
                                {
                                    (this.props.all_members !== null
                                        && this.props.all_members.data.message !== undefined)
                                        ? this.props.all_members.data.message : ''
                                }
                            </div>
                            <MemberList filter={this.state.selected_filter}/>
                        </main>
                    </div>
                </div>
                <ArchiveMemberConfirmationPopup filter={{
                    'type': this.state.selected_filter,
                    'status': this.state.selected_status
                }}/>
                <ActivateMemberConfirmationPopup filter={{
                    'type': this.state.selected_filter,
                    'status': this.state.selected_status
                }}/>
            </div>
        );
    }


    onClickFilterMembers(filter, status) {
        Logger.info("filter the members [" + filter + "]");
        this.setState({selected_filter: filter, selected_status: status});
        this.props.getAllMembers(this.props.user.data.auth.token, filter, status);
    }


    onClickInviteNewMember() {
        Logger.info("inviting new member");
        let payload = {
            id: "invite_new_member_popup",
            shouldDisplay: true
        };
        this.props.handlePopupVisibility(payload);
    }


    onClickShowSelfMemberInvitationForm() {
        Logger.info("self registering new member");

        let payload = {
            id: "self_member_onboarding_popup",
            shouldDisplay: true
        };
        this.props.handlePopupVisibility(payload);
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        all_members: state.all_members,
        member_profile: state.member_profile
    };
};

const actions = {
    getAllMembers: getAllRegisteredMembersAction,
    getMemberDetailsById: getMemberProfileDetailsByUserIdAction,
    handlePopupVisibility: popupDisplayHandlerAction,
    userSignOut: userLogoutAction
};


export default withRouter(connect(mapStateToProps, actions)(AdminDashboard));
