export const shareDnsSetupInstructionsReducer = (state = null, action) => {

    if (action.type === 'SHARE_DNS_SETUP_INSTRUCTIONS_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'SHARE_DNS_SETUP_INSTRUCTIONS_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    }
    else if (action.type === 'RESET_SHARE_DNS_SETUP_INSTRUCTIONS') {
        return null;
    }
    return state;
};
