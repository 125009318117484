import React, {Component} from 'react';
import './style.css';
import Logger from "../../core/util/Logger";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {popupDisplayHandlerAction} from "../../actions/popup-display-action";


class NotificationPopup extends Component {


    render() {
        return (
            <div tabIndex="-1" role="dialog"
                 className="modal fade show"
                 style={this.props.popup.data.id === 'dashboard_notification_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                 aria-modal="true">
                <div className="overlay">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content notification-popup-wrap">
                            <div className="modal-header notification-popup-header-text">
                                {/*<button type="button" data-dismiss="modal" aria-label="Close"*/}
                                {/*        onClick={this.onClosePopup.bind(this)}*/}
                                {/*        className="close">*/}
                                {/*    <span aria-hidden="true">×</span></button>*/}
                                <h5 id="exampleModalLabel" className="modal-title text-center">
                                    {
                                        this.props.popup.data !== undefined && this.props.popup.data.data !== undefined && this.props.popup.data.data.message.header

                                    }
                                </h5>
                            </div>
                            <div className="modal-body jrf-popup">
                                {/*<h5 id="exampleModalLabel" className="modal-title text-center">*/}
                                {/*    {*/}
                                {/*        this.props.popup.data !== undefined && this.props.popup.data.data !== undefined && this.props.popup.data.data.message.header*/}

                                {/*    }*/}
                                {/*</h5>*/}
                                <p className="text-center">
                                    {
                                        this.props.popup.data !== undefined && this.props.popup.data.data !== undefined && this.props.popup.data.data.message.body
                                    }
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={this.onClosePopup.bind(this)}
                                    type="button" data-dismiss="modal" className="btn btn-secondary">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClosePopup() {
        Logger.info("closing popup");
        let payload = {
            id: "dashboard_notification_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }
}

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction
};

const mapStateToProps = (state) => {
    return {
        popup: state.popup
    }
};

export default withRouter(connect(mapStateToProps, actions)(NotificationPopup));
