import React, {Component} from 'react';
import '../sign-up/sign-up.css';
import PersonalDetails from '../sign-up/personal-details/PersonalDetails';
import CompanyDetails from '../sign-up/company-details/CompanyDetails';
import ProgramDetails from '../sign-up/program-customization-details/ProgramDetails';
import {reduxForm, getFormValues} from 'redux-form';
import {connect} from 'react-redux'
import {uploadProfilePicture} from '../../actions/index';
import Logger from "../../core/util/Logger";
import validator from 'validator';
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import {withRouter} from 'react-router-dom';
import TopMenuBar from '../top-menu-bar/TopMenuBar';
import AuthenticatedComponent from '../common/AuthenticatedComponent';
import {updateUserProfileAction} from "../../actions/user/update-user-profile-action";
import {getUserDetailsByTokenAction} from "../../actions/user/get-userdetails-by-token-action";


let displayForms = {
    "personal_details": false,
    "company_details": false,
    "program_details": false
};

class ProfileEditForm extends Component {

    constructor() {
        super();
        this.state = {
            "personal_details": true,
            "company_details": false,
            "program_details": false,
            "display_dns_setup_view": false
        };
    }


    componentDidMount() {
        // localStorage.setItem('initial-load', true);
        // Logger.info("initial props", this.props);
        if (this.props.user.auth.token !== undefined) {
            this.props.getUserDetails(this.props.user.auth.token);
        }
    }

    componentDidUpdate() {

        Logger.info("member profile updated status", this.props.profile);

        if (this.props.profile.data !== undefined && this.props.profile.data.dns_verification_required && this.state.display_dns_setup_view) {
            Logger.info("redirecting the user for DNS set up view");
            this.props.history.push("/dns-setup", {message: this.props.profile.data.message});
        }
    }

    render() {
        return (
            <div id="app">

                <AuthenticatedComponent/>
                <LoadingSpinner/>

                <TopMenuBar/>

                <main className="py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div><h3>Update Your Profile</h3></div>

                                {/*display if there is any erorr message*/}
                                <div className="alert alert-danger"
                                     style={!this.props.profile.success && this.props.profile.data !== undefined ? {display: 'flex'} : {display: 'none'}}
                                     role="alert">
                                    {this.props.profile.data !== undefined ? this.props.profile.data.message : ''}
                                </div>

                                {/*display notification message to inform profile update was successful */}
                                <div className="alert alert-success"
                                     style={this.props.profile.success && this.props.profile.data !== undefined ? {display: 'flex'} : {display: 'none'}}
                                     role="alert">
                                    {this.props.profile.data !== undefined ? this.props.profile.data.message : ''}
                                </div>

                                <div>
                                    <form onSubmit={this.props.handleSubmit(this.onSubmitHandler.bind(this))}
                                          encType={"multipart/form-data"}>

                                        <div id="accordionExample" className="accordion reg">

                                            <PersonalDetails onAccordionClick={this.onClickAccordionHandler.bind(this)}
                                                             profile_edit={true}
                                                             expandOnSubmit={this.state.personal_details}
                                                             persistFormData={persistFormData}/>

                                            <CompanyDetails onAccordionClick={this.onClickAccordionHandler.bind(this)}
                                                            expandOnSubmit={this.state.company_details}
                                                            domain={this.props.domain}
                                                            persistFormData={persistFormData}/>

                                            <ProgramDetails persistFormData={persistFormData}
                                                            appName={this.props.app_name}
                                                            onAccordionClick={this.onClickAccordionHandler.bind(this)}
                                                            expandOnSubmit={this.state.program_details}
                                                            onClickCompleteBtn={this.onClickCompleteBtn.bind(this)}/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }

    onSubmitHandler = (data) => {
        Logger.info("submit form to update user profile", data);
        this.props.updateUserProfile(this.props.user.auth.token, data);
    };


    onClickAccordionHandler(accordionName) {
        Logger.info("display accordion [" + accordionName + "] as active");
        displayForms = {
            "personal_details": false,
            "company_details": false,
            "program_details": false
        };
        if (accordionName === 'personal_details') {
            displayForms.personal_details = !this.state.personal_details;
        }
        if (accordionName === 'company_details') {
            displayForms.company_details = !this.state.company_details;
        }
        if (accordionName === 'program_details') {
            displayForms.program_details = !this.state.program_details;
        }
        this.setState(displayForms);
    }

    onClickCompleteBtn() {
        Logger.info("on click complete btn. set to itnial state", this.props.form_values);
        //hide all the accordions on click submit complete btn
        displayForms = {
            "personal_details": false,
            "company_details": false,
            "program_details": false,
            "display_dns_setup_view": true
        };
        this.setState(displayForms);

        validate(this.props.form_values);
    }
}


const persistFormData = (event) => {
    localStorage.setItem('initial-load', false);
    let key = "form-data";

    let objectString = localStorage.getItem(key);
    let storeData = {};
    if (objectString !== undefined && objectString !== null) {
        storeData = JSON.parse(objectString);
    }
    console.log("==== [" + event.currentTarget.name + "] +++ [" + event.currentTarget.value + "]");

    storeData[event.currentTarget.name] = event.currentTarget.value;

    //if the latest value is empty, we will remove it from local store
    if ((event.currentTarget.value).trim() === "") {
        delete storeData[event.currentTarget.name];
    }

    localStorage.setItem(key, JSON.stringify(storeData));
};


const validate = val => {
    const errors = {};
    Logger.info("validation started");
    if (!val.full_name) {
        Logger.info('Name is required');
        errors.full_name = 'The name field is required';
        displayForms.personal_details = true;
    }

    if (!val.phone_number) {
        Logger.info("phone number is required");
        errors.phone_number = 'phone number is required';
        displayForms.personal_details = true;
    }

    if (!val.website) {
        Logger.info("website is required");
        errors.website = 'Website is required';
        displayForms.personal_details = true;
    }
    else if (val.website !== undefined && !validator.isURL(val.website)) {
        Logger.info("invalid website");
        errors.website = 'Invalid website';
        displayForms.personal_details = true;
    }

    if (!val.company_name) {
        Logger.info("company name is required");
        errors.company_name = 'Company name is Required';
        displayForms.company_details = true;
    }

    if (!val.company_address) {
        Logger.info("company address is required");
        errors.company_address = 'Company address is required';
        displayForms.company_details = true;
    }

    if (!val.subdomain) {
        Logger.info("subdomain is required", displayForms);
        errors.subdomain = 'Subdomain is required';
        displayForms.company_details = true;
        Logger.info(displayForms);
    }

    // if (!val.hosting_provider) {
    //     Logger.info("hosting provider is required");
    //     errors.hosting_provider = 'Hosting provider is required';
    //     displayForms.company_details = true;
    // }

    // if (!val.hosting_username) {
    //     Logger.info("hosting username is required");
    //     errors.hosting_username = 'Hosting username is required';
    //     displayForms.company_details = true;
    // }
    //
    // if (!val.hosting_password) {
    //     Logger.info("hosting password is required");
    //     errors.hosting_password = 'Hosting password is required';
    //     displayForms.company_details = true;
    // }

    if (!val.program_name) {
        Logger.info("program name is required");
        errors.program_name = 'Program name is required';
        displayForms.program_details = true;
    }

    if (!val.selling_price) {
        Logger.info("selling price is required");
        errors.selling_price = 'selling price is required';
        displayForms.program_details = true;
    }

    if (!val.stripe) {
        Logger.info("stripe field is required");
        errors.stripe = 'The stripe selection is required';
        displayForms.program_details = true;
    }

    if (!val.hours_delay) {
        Logger.info("hours delay is required");
        errors.hours_delay = 'hours delay is required';
        displayForms.program_details = true;
    }
    else if (!validator.isInt(val.hours_delay, {min: 0, max: 72})) {
        errors.hours_delay = 'hours delay must be between 0 and 72';
        displayForms.program_details = true;
    }
    Logger.info("validation completed");
    return errors;
};


const mapStateToProps = (state) => {

    let formValues = getFormValues('signUp')(state);
    return {
        initialValues: state.user.data.profile,
        app_name: formValues !== undefined ? formValues.app_name : '',
        domain: formValues !== undefined ? formValues.website : '',
        form_values: getFormValues('signUp')(state),
        // token: state.user.data.auth.token,
        user: state.user.data,
        profile: state.profile
    }
};


let actions = {
    uploadProfileImage: uploadProfilePicture,
    updateUserProfile: updateUserProfileAction,
    getUserDetails: getUserDetailsByTokenAction
};

export default withRouter(connect(mapStateToProps, actions)(reduxForm({
    form: 'signUp',
    validate,
    enableReinitialize: true
})(ProfileEditForm)));



