import Logger from "../../core/util/Logger";
import validator from "validator";

export class LoginFromValidation {

    validate = (val) => {
        const errors = {};
        if (!val.email) {
            Logger.info('email is required');
            errors.email = 'Email is Required';
        } else if (!validator.isEmail(val.email)) {
            Logger.info('email is invalid');
            errors.email = 'Invalid email address';
        }

        if (!val.password) {
            Logger.info("password is required");
            errors.password = 'password is required';
        }
        return errors;
    };
}