export const storeVideoProcessingProjectReducer = (state = null, action) => {

    if (action.type === 'STORE_VIDEO_PROCESSING_PROJECT_ID_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'STORE_VIDEO_PROCESSING_PROJECT_ID_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    }
    return state;
};
