import React, {Component} from 'react';
import {connect} from "react-redux";

class Group extends Component {

    render() {
        return (
            <div className="card bg-light mt-0 mb-5">
                <div className="card-body">
                    <h4 className="card-title">
                        {this.props.label}
                    </h4>
                    <div className="row justify-content-center text-center p-3">
                        {this.props.children}
                    </div>
                </div>
            </div>
    );
    }
}


const actions = {
};

const mapStateToProps = (state) => {
    return {
    }
};


export default connect(mapStateToProps, actions)(Group);
