import React, {Component} from 'react';
import {connect} from "react-redux";
import {getFileUploadMenuDetailsAction} from "../../../actions/user/get-file-upload-menu-details-action";
import '../member-file-uploading.css';
import MemberFileGridItem from "./MemberFileGridItem";
import {removeMemberUploadedFileAction} from "../../../actions/user/remove-member-uploaded-file-action";
import Logger from "../../../core/util/Logger";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";

class MemberFileGridListView extends Component {

    componentDidUpdate() {
        if (this.props.remove_member_file != null && this.props.remove_member_file.success) {
            Logger.info("reloading the member files information (including the categories)");
            this.props.resetReduxStore('RESET_REMOVE_MEMBER_UPLOADED_FILE');
            this.props.getFileUploadMenuDetailsForUser(this.props.user.data.auth.token);
        }
    }

    render() {
        return (
            this.props.file_upload_categories != null && this.props.category != null &&
            this.props.file_upload_categories.data.find(category => category.id === this.props.category.id).files.map(((file) => {
                        return (
                            <MemberFileGridItem
                                removeFileHandler={this.removeFileHandler}
                                file={file}/>
                        );
                    }
                )
            )
        );
    }


    removeFileHandler = (fileId) => {
        let token = this.props.user.data.auth.token;
        this.props.removeFile(fileId, token);
    }
}


const actions = {
    getFileUploadMenuDetailsForUser: getFileUploadMenuDetailsAction,
    removeFile: removeMemberUploadedFileAction,
    resetReduxStore: resetReduxStoreAction,
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        file_upload_categories: state.file_upload_categories,
        remove_member_file: state.remove_member_file
    }
};


export default connect(mapStateToProps, actions)(MemberFileGridListView);