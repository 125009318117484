import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const activateUserAction = (userId, token) => async dispatch => {
    Logger.info("activate member [" + userId + "]  with auth token [" + token + "] ");

    let response = await RestApiService.post("/admin/members/" + userId + "/activate",{},
        {Authorization: "Bearer " + token});
    Logger.info(response);

    //wait for 3 second
    await new Promise(resolve => setTimeout(resolve, 3000));

    if (response.status === 200) {
        Logger.info("user was successfully activated");
        dispatch({
            type: "MEMBER_ACTIVATE_SUCCESS",
            payload: {
                message: "Member has been activated successfully"
            }
        });
    } else {
        Logger.info("error in activating the user");
        dispatch({
            type: "MEMBER_ACTIVATE_FAILED",
            payload: {
                message: "Error while activating the member"
            }
        });
    }
};
