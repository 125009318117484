import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const getMemberProfileDetailsByUserIdAction = (token, userId, popupDetails = null) => async dispatch => {
    Logger.info("getting user details by user id [" + userId + "] and token[" + token + "] ");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await RestApiService.get("/admin/members/" + userId, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("member details received successfully");
        dispatch({
            type: "MEMBER_PROFILE_DETAILS_SUCCESS",
            payload: response.data
        });

        //display the related popup once the data is received
        if (popupDetails != null) {
            dispatch({
                type: "POPUP_HANDLER",
                payload: popupDetails
            });
        }
    }
    else {
        Logger.info("error in getting member details [" + response.data + "]");
        dispatch({type: "MEMBER_PROFILE_DETAILS_FAILED", payload: response.data});
    }
    dispatch({type: "HIDE_LOADER_SPINNER"});
};
