import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const getDnsConfigurationDetailsByMemberAction = (token, displayLoader = true) => async dispatch => {
    Logger.info("getting DNS details by token [" + token + "] ");
    dispatch({type: "RESET_DNS_DETAILS_RECEIVED"});

    if (displayLoader) {
        dispatch({type: "DISPLAY_LOADER_SPINNER"});
    }

    let response = await RestApiService.get("/members/dns", {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("DNS details received successfully");
        dispatch({
            type: "DNS_DETAILS_RECEIVED_SUCCESS",
            payload: response.data
        });
    }
    else {
        Logger.info("error in getting DNS details [" + response.data + "]");
        dispatch({type: "DNS_DETAILS_RECEIVED_FAILED", payload: response.data});
    }

    //loader need to be hidden, if the displayLoader is set yo true
    if (displayLoader) {
        //wait for 3 second
        // await new Promise(resolve => setTimeout(resolve, 3000));
        dispatch({type: "HIDE_LOADER_SPINNER"});
        Logger.info("hiding spinner")
    }
};