import React, {Component} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Logger from "../../../core/util/Logger";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";


export default class SelfMemberOnboardForm extends Component {


    render() {
        return (
            <Formik
                ref={this.props.formRef}
                initialValues={{
                    email: ''
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email("Invalid email")
                        .required('Email is required')
                })}
                onSubmit={fields => {
                    Logger.info("handle submission");
                    this.props.handleSelfMemberOnBoard(fields);
                }}
                render={({errors, status, touched}) => (
                    <div className={'invite-popup-wrap'}>

                        <Form>
                            <Container>

                                <Row className={"mb-2"}>
                                    <Col md={2}>
                                        <div className="form-group">
                                            <label>Email</label>
                                        </div>
                                    </Col>
                                    <Col md={10}>
                                        <Field name="email" type="text"
                                               style={{paddingRight: 'none !important'}}
                                               className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="email" component="div"
                                                      className="invalid-feedback"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>
                                            If you don't want emails to be sent to the customer, use your own email with
                                            a suffix.
                                            <br/> Eg: ken+client_name@macroactive.com
                                        </p>

                                    </Col>
                                </Row>

                                <Button
                                    type={"submit"}
                                    variant="primary mb-2 mt-2 float-right">Begin Onboarding</Button>
                            </Container>
                        </Form>
                    </div>
                )}
            />
        );
    }
}
