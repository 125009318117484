import React, {Component} from 'react';
import {Field} from "redux-form";

export class FormField extends Component {

    static renderInputField = ({input, label, type, value, disabled, className, meta: {touched, error, warning}}) => (
        <span>
        <input style={touched && error ? {borderColor: '#e3342f'} : {}} className={className} {...input}
               disabled={disabled}
               placeholder={label} type={type}/>
            {touched && error && <span className="validation-error">{error}</span>}
            {/*{error && <span className="validation-error">{error+"["+(localStorage.getItem('initial-load')==='false')+"]"}</span>}*/}
    </span>
    );


    static renderFileUploadField = ({input: {value: omitValue, ...inputProps}, meta: omitMeta, ...props}) => (
        <div>
            <input type='file' {...inputProps} {...props} />
            {omitMeta.touched && <span className="validation-error">{omitMeta.error}</span>}
        </div>
    );

    static renderSubmitButton = ({label, input: {value: omitValue, ...inputProps}, meta: omitMeta, ...props}) => (
        <button {...inputProps} {...props}>
            {label}
        </button>
    );


    static renderSelectField = ({input, label,className, meta: {touched, error}, children}) => (
        <div>
            <div>
                <select className={className} {...input} >
                    {children.map((object, index) => (
                        <option key={object.key} value={object.key}>{object.value}</option>
                    ))}
                </select>
                {/*{touched && (error && <p className="help is-danger">{error}</p>)}*/}
                {/*{<span className="validation-error">{error}</span>}*/}
            </div>
            {<span className="validation-error">{error}</span>}
        </div>
    );
}