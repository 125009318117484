import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const getStripeConnectUrlAction = (token) => async dispatch => {
    Logger.info("getting stripe connect url for the user with token [" + token + "] ");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await RestApiService.get("/stripe/connect/url", {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("stripe connect url received successfully");
        dispatch({
            type: "STRIPE_CONNECT_URL_SUCCESS",
            payload: response.data
        });
    } else {
        Logger.info("error in getting stripe connect url details ", response.data);
        dispatch({type: "STRIPE_CONNECT_URL_FAILED", payload: response.data});
    }

    // wait for 0.5 second
    await new Promise(resolve => setTimeout(resolve, 500));
    dispatch({type: "HIDE_LOADER_SPINNER"});
};