import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const getFileUploadMenuDetailsAction = (token) => async dispatch => {
    Logger.info("getting the file upload menu details for user's by token [" + token + "] ");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});
    // dispatch({type: "RESET_REMOVE_MEMBER_UPLOADED_FILE"});

    let response = await RestApiService.get("/member-files", {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("file menu details received successfully");
        dispatch({
            type: "FILE_UPLOAD_MENU_DETAILS_RECEIVED_SUCCESS",
            payload: response.data
        });
    }
    else {
        Logger.info("error in getting file menu details [" + response.data + "]");
        dispatch({type: "FILE_UPLOAD_MENU_DETAILS_RECEIVED_FAILED", payload: response.data});
    }
    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch({type: "HIDE_LOADER_SPINNER"});
    Logger.info("hiding spinner")
};