import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const shareDnsSetUpInstructionsAction = (userId, data, token) => async dispatch => {

    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("note", data.note);
    formData.append("user_id", userId);

    let response = await RestApiService.post("/admin/share_dns_instructions", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        dispatch({
            type: "SHARE_DNS_SETUP_INSTRUCTIONS_SUCCESS",
            payload: {
                message: "Instructions has been sent"
            }
        });
    } else {
        dispatch({
            type: "SHARE_DNS_SETUP_INSTRUCTIONS_FAILED",
            payload: {
                message: response.data.message
            }
        });
    }
};
