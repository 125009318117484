import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {newMemberRegistrationReducer} from "./member-registration-reducer";
import {handleSpinnerLoadingViewReducer} from "./loader-spinner-reducer";
import {userAuthenticationReducer} from "./user-authentication-reducer";
import {getDnsDetailsReducer} from "./dns-details-reducer";
import {memberProfileUpdateReducer} from "./member-profile-update-reducer";
import {getDnsProviderDetailsByIdReducer} from "./get-dns-provider-details-byid-reducer";
import {handleAdminGoogleAuthUrlReducer} from "./get-admin-google-oauth-url-reducer";
import {allRegisteredMembersReducer} from "./get-all-registered-members-reducer";
import {memberProfileDetailsByIdReducer} from "./get-member-profile-details-by-id-reducer";
import {memberTotalExerciseVideoSizeReducer} from "./get-member-total-exercise-video-size-reducer";
import {handlePopupDisplayReducer} from "./popup-display-handler-reducer";
import {checkDomainAvailabilityReducer} from "./check-domain-availability-reducer";
import {purchasingNewDomainReducer} from "./purchasing-new-domain-reducer";
import {addExistingDomainDetailsReducer} from "./add-existing-domain-details-reducer";
import {resendMandrillDomainVerificationReducer} from "./resend-mandrill-domain-verification-reducer";
import {reserveNewDomainReducer} from "./reserve-new-domain-reducer";
import {addMemberHostingDetailsReducer} from "./add-member-hosting-details-reducer";
import {askHelpFromTechPersonReducer} from "./ask-help-from-tech-person-reducer";
import {getStripeConnectUrlReducer} from "./get-stripe-connect-url-reducer";
import {completeStripeConnectReducer} from "./complete-stripe-connect-reducer";
import {getUserDashboardSettingsReducer} from "./get-user-dashboard-settings-reducer";
import {newMemberInvitationReducer} from "./send-member-invitation-reducer";
import {validateMemberRegistrationLinkHashReducer} from "./validate-member-registration-link-reducer";
import {getMemberDomainDetailsReducer} from "./get-member-domain-details-reducer";
import {userArchiveReducer} from "./user-archive-reducer";
import {userActivateReducer} from "./user-activate-reducer";
import {shareDnsSetupInstructionsReducer} from "./share-dns-setup-intructions-reducer";
import {adminMemberPasswordResetReducer} from "./admin-member-password-reset-reducer";
import {updateMemberSubDomainReducer} from "./update-member-subdomain-reducer";
import {createNewEmailForwarderReducer} from "./create-new-email-forwarder-reducer";
import {getEmailForwarderListReducer} from "./get-email-forwarder-list-reducer";
import {removeEmailForwarderReducer} from "./remove-email-forwarder-reducer";
import {getFileUploadMenuDetailsReducer} from "./get-file-upload-menu-details-reducer";
import {removeMemberUploadedFilesReducer} from "./remove-member-uploaded-files-reducer";
import {uploadMemberFilesReducer} from "./updalod-member-files-reducer";
import {exerciseVideoUploadReducer} from "./exercise-video-upload-reducer";
import {selfMemberInvitationReducer} from "./self-member-invitation-reducer";
import {releasesReducer} from "./get-releases-reducer";
import {adminCreateVideoProcessingProjectReducer} from "./admin-create-video-processing-project-reducer";
import {initiateVideoProcessingReducer} from "./initiate-video-processing-reducer"
import {getVideoProcessingActionQueueReducer} from "./get-video-processing-action-queue-reducer";
import {getVideoProcessingProjectReducer} from "./get-video-processing-project-reducer";
import {getVideoProcessingJobLogsReducer} from "./get-video-processing-job-logs-reducer";
import {storeVideoProcessingProjectReducer} from "./store-video-processing-project-reducer";
import {getVideoProcessingPrepareMatchReportReducer} from "./get-video-processing-prepare-match-report-reducer";
import {getVideoProcessingProcessMatchReportReducer} from "./get-video-processing-process-match-report-reducer";
import {getMemberExerciseVideosReducer} from "./get-member-exercise-videos-reducer";
import {deleteMemberExerciseVideoReducer} from "./delete-member-exercise-videos-reducer";
import {deleteMemberAllExerciseVideosReducer} from "./delete-member-all-exercise-videos-reducer";
import {updateMemberExerciseVideoReducer} from "./update-member-exercise-videos-reducer";
import {userExerciseVideosStepReducer} from "./user-exercise-videos-step-reducer";

export default combineReducers({
    form: formReducer,
    member: newMemberRegistrationReducer,
    loader_spinner: handleSpinnerLoadingViewReducer,
    user: userAuthenticationReducer,
    dns: getDnsDetailsReducer,
    profile: memberProfileUpdateReducer,
    dns_provider: getDnsProviderDetailsByIdReducer,
    admin_auth: handleAdminGoogleAuthUrlReducer,
    all_members: allRegisteredMembersReducer,
    member_profile: memberProfileDetailsByIdReducer,
    member_total_video_size: memberTotalExerciseVideoSizeReducer,
    popup: handlePopupDisplayReducer,
    domain_availability: checkDomainAvailabilityReducer,
    reserve_domain: reserveNewDomainReducer,
    purchased_domain: purchasingNewDomainReducer,
    existing_domain: addExistingDomainDetailsReducer,
    mandrill_verification: resendMandrillDomainVerificationReducer,
    member_hosting: addMemberHostingDetailsReducer,
    ask_help: askHelpFromTechPersonReducer,
    stripe_url: getStripeConnectUrlReducer,
    stripe_details: completeStripeConnectReducer,
    user_dashboard: getUserDashboardSettingsReducer,
    new_email_forwarder: createNewEmailForwarderReducer,
    email_forwarders_list: getEmailForwarderListReducer,
    remove_email_forwarder: removeEmailForwarderReducer,
    member_invitation: newMemberInvitationReducer,
    member_invitation_link: validateMemberRegistrationLinkHashReducer,
    member_domain: getMemberDomainDetailsReducer,
    user_archive: userArchiveReducer,
    user_activate: userActivateReducer,
    share_dns_instructions: shareDnsSetupInstructionsReducer,
    admin_reset_member_password: adminMemberPasswordResetReducer,
    member_subdomain_update: updateMemberSubDomainReducer,
    file_upload_categories: getFileUploadMenuDetailsReducer,
    remove_member_file: removeMemberUploadedFilesReducer,
    uploaded_member_files: uploadMemberFilesReducer,
    exercise_video_uploader: exerciseVideoUploadReducer,
    self_member_invitation: selfMemberInvitationReducer,
    releases: releasesReducer,
    admin_create_video_processing_project: adminCreateVideoProcessingProjectReducer,
    initiate_video_processing_action: initiateVideoProcessingReducer,
    video_processing_action_queue: getVideoProcessingActionQueueReducer,
    video_processing_project: getVideoProcessingProjectReducer,
    store_video_processing_project: storeVideoProcessingProjectReducer,
    video_processing_job_logs: getVideoProcessingJobLogsReducer,
    video_processing_prepare_match_report: getVideoProcessingPrepareMatchReportReducer,
    video_processing_process_match_report: getVideoProcessingProcessMatchReportReducer,
    member_exercise_videos: getMemberExerciseVideosReducer,
    delete_member_exercise_video: deleteMemberExerciseVideoReducer,
    delete_all_member_exercise_video: deleteMemberAllExerciseVideosReducer,
    update_member_exercise_video: updateMemberExerciseVideoReducer,
    user_exercise_videos_step: userExerciseVideosStepReducer
});
