export const resendMandrillDomainVerificationReducer = (state = null, action) => {

    if (action.type === 'RESEND_MANDRILL_VERIFICATION_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'RESEND_MANDRILL_VERIFICATION_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    }
    else if(action.type === 'RESET_MANDRILL_VERIFICATION_STORE'){
        return null;
    }
    return state;
};
