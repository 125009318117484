import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";
import Logger from "../../../core/util/Logger";
import {getEmailForwardersListAction} from "../../../actions/admin/get-email-forwarders-list-action";
import {deleteEmailProviderAction} from "../../../actions/admin/delete-email-forwarder-action";

let defaultState = {
    start_fetching_email_providers_list: false,
    start_removing_email_provider: false,

};

class EmailForwardersList extends Component {

    constructor() {
        super();
        this.state = defaultState;
    }


    componentDidUpdate() {

        if (this.props.domain_details !== null &&
            this.props.domain_details.domain_type === 'new_domain' &&
            this.props.email_forwarders_list === null &&
            this.state.start_fetching_email_providers_list === false) {
            Logger.info("loading the list of email providers");
            this.setState({start_fetching_email_providers_list: true});
            let token = this.props.user.data.auth.token;
            this.props.getEmailForwardersList(token, this.props.domain_details.domain);
        }

        if (this.props.email_forwarders_list != null &&
            this.state.start_fetching_email_providers_list === true) {
            Logger.info("email forwarding list received", this.props.email_forwarders_list);
            this.setState({start_fetching_email_providers_list: false});
        }

        //handling the removal of the domain
        if (this.props.remove_email_forwarder != null &&
            this.props.remove_email_forwarder.success === true) {
            Logger.info("successfully remove the email forwarder");
            this.setState({start_removing_email_provider: false});
            this.props.resetReduxStore("EMAIL_FORWARDER_DELETE_RESET");
            this.props.resetReduxStore("EMAIL_FORWARDER_LIST_RESET");
        }
    }

    render() {
        return (

            <div>
                <div className="modal-body jrf-popup"
                     style={this.state.start_fetching_email_providers_list === true ? {display: 'block'} : {display: 'none'}}>
                    <p className="text-center">
                        Please wait... email forwarders are being retrieved
                    </p>
                    <img src="../../../images/spinner.gif" className="loder"/>
                </div>

                <div className="modal-body jrf-popup"
                     style={this.state.start_removing_email_provider === true ? {display: 'block'} : {display: 'none'}}>
                    <p className="text-center">
                        Please wait... email forwarder is being removed
                    </p>
                    <img src="../../../images/spinner.gif" className="loder"/>
                </div>

                <div id={"email-forwarding-list"}
                     style={(this.state.start_fetching_email_providers_list === true || this.state.start_removing_email_provider === true) ? {display: 'none'} : {display: 'block'}}
                     className="list-group">
                    <table className="table table-striped text-center">
                        <thead>
                        <tr>
                            <th scope="col">Mailbox</th>
                            <th scope="col">Forwarding Email</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.showEmailForwardersList()
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }


    showEmailForwardersList() {
        if (this.props.email_forwarders_list !== null && this.props.email_forwarders_list.success) {
            return this.props.email_forwarders_list.data.map((forwarding, index) => {
                return (
                    <tr key={index}>
                        <td>{forwarding.emailBox}</td>
                        <td>{forwarding.emailTo}</td>
                        <td>
                            <button className="btn btn-primary mb-2"
                                    onClick={() => this.onclickRemoveEmailForwarder(forwarding.emailBox, this.props.domain_details.domain)}>
                                Delete
                            </button>
                        </td>
                    </tr>
                );
            });
        }
    }


    onclickRemoveEmailForwarder(mailBoxId, domainName) {
        let confirmation = window.confirm("Are you sure to remove " + mailBoxId + "@" + domainName + "?");
        if(confirmation) {
            Logger.info("removing the email forwarder");
            let token = this.props.user.data.auth.token;
            this.setState({start_removing_email_provider: true});
            this.props.deleteEmailProvider(token, domainName, mailBoxId);
        }
    }

}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        email_forwarders_list: state.email_forwarders_list,
        remove_email_forwarder: state.remove_email_forwarder
    };
};

const actions = {
    resetReduxStore: resetReduxStoreAction,
    getEmailForwardersList: getEmailForwardersListAction,
    deleteEmailProvider: deleteEmailProviderAction
};


export default withRouter(connect(mapStateToProps, actions)(EmailForwardersList));
