import React, {Component} from 'react';
import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {purchaseNewDomainAction} from "../../../actions/user/purchase-new-domain-action";
import {withRouter} from "react-router-dom";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";

class BuyNewDomainConfirmation extends Component {

    constructor() {
        super();
        this.formik = React.createRef();
        this.state = {purchase_domain_confirmed: false}
    }


    componentDidUpdate() {
        // if (this.state.purchase_domain_confirmed === true) {
        //     //waiting for two seconds to display the loader for giving more user experience
        //     await new Promise(resolve => setTimeout(resolve, 2000));
        // }

        if (this.props.purchased_domain !== null
            && this.state.purchase_domain_confirmed === true
            && this.props.purchased_domain.success === false) {
            Logger.info("displaying the purchase domain confirmation view to display error", this.props.purchased_domain);
            this.setState({purchase_domain_confirmed: false});
        }

        //redirect to success view
        if (this.props.purchased_domain !== null && this.props.purchased_domain.success === true) {
            Logger.info("well done! DNS set up is completed ");
            this.props.history.push("/domains/completed");
        }
    }


    render() {
        return (
            <div tabIndex="-1" role="dialog"
                 style={this.props.popup.data.id === 'buy_new_domain_confirmation'
                 && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                 className="modal fade show"
                 aria-modal="true">
                <div className="overlay">
                    <div className="modal-dialog modal-dialog-scrollable"
                         style={this.state.purchase_domain_confirmed !== true ? {display: 'flex'} : {display: 'none'}}
                         role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 id="exampleModalLabel" className="modal-title text-center">
                                    Confirm your domain
                                </h5>
                                <button type="button" data-dismiss="modal" aria-label="Close"
                                        onClick={this.onClickCloseBtnHandler.bind(this)}
                                        className="close">
                                    <span aria-hidden="true">×</span></button>
                            </div>

                            <div className="modal-body text-left">

                                <p>
                                    Confirm your new domain before continuing.
                                </p>
                                <div className="alert alert-danger"
                                     style={(this.props.purchased_domain != null
                                         && this.props.purchased_domain.success === false) ? {display: 'flex'} : {display: 'none'}}
                                     role="alert">
                                    {this.props.purchased_domain != null && this.props.purchased_domain.data.message}
                                </div>


                                <table className="table new-domain-table">
                                    <tbody>
                                    <tr>
                                        <td className={'tb-width'}><b>Domain</b></td>
                                        <td>{this.props.domain_details.domain}</td>
                                    </tr>
                                    <tr>
                                        <td className={'tb-width'}><b>Members Area</b></td>
                                        <td>{this.props.domain_details.sub_domain}.{this.props.domain_details.domain}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={this.onClickCloseBtnHandler.bind(this)} data-dismiss="modal">
                                    Cancel
                                </button>
                                <button type="button" className="btn btn-primary"
                                        onClick={this.onConfirmNewDomainPurchaseHandler.bind(this)}>
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>

                    <div id="purchase-progress"
                         style={this.state.purchase_domain_confirmed === true ? {display: 'flex'} : {display: 'none'}}
                         role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 id="exampleModalLabel" className="modal-title text-center">
                                    Please wait.....
                                </h5>
                            </div>
                            <div className="modal-body jrf-popup">
                                <p className="text-center">
                                    Domain <b> {this.props.domain_details.domain} </b> is being purchased
                                </p>
                                <img src="../../../images/spinner.gif" className="loder"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClickCloseBtnHandler() {
        Logger.info("buy new domain verification popup is closed");
        let payload = {
            id: "buy_new_domain_confirmation",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
        this.props.resetReduxStore('RESET_BUYING_NEW_DOMAIN');
    }

    async onConfirmNewDomainPurchaseHandler() {
        await this.props.resetReduxStore('RESET_BUYING_NEW_DOMAIN');
        Logger.info(" after clearing ", this.props.purchased_domain);
        // this.setState({purchase_domain_confirmed: true});
        let domain = this.props.domain_details.domain;
        let subDomain = this.props.domain_details.sub_domain;
        let token = this.props.user.data.auth.token;

        Logger.info("confirm to buy new domain [" + domain + "] and set up sub domain [" + subDomain + "]");
        this.props.purchaseNewDomain(domain, subDomain, token);
        this.setState({purchase_domain_confirmed: true});
    }
}

const mapStateToProps = (state) => {
    return {
        popup: state.popup,
        user: state.user,
        purchased_domain: state.purchased_domain
    }
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    purchaseNewDomain: purchaseNewDomainAction,
    resetReduxStore: resetReduxStoreAction
};


export default withRouter(connect(mapStateToProps, actions)(BuyNewDomainConfirmation));
