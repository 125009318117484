import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {activateUserAction} from "../../../actions/admin/activate-user-action";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";
import {getAllRegisteredMembersAction} from "../../../actions/admin/get-registered-members-action";
import Modal from "react-bootstrap/Modal";
import MessageBoxAlert from "../../common/MessageBoxAlert";
import ProgressLoader from "../../common/progress-loader/ProgressLoader";
import Button from "react-bootstrap/Button";

let defaultState = {start_activate_user: false, user_activate_success: false, message: null};

class ActivateMemberConfirmationPopup extends Component {


    constructor() {
        super();
        this.state = defaultState;
    }

    componentDidMount() {
        this.setState(defaultState);
    }

    componentDidUpdate() {
        if (this.props.user_activate != null && this.state.start_activate_user === true) {
            if (this.props.user_activate.success === true) {
                this.setState({
                    start_activate_user: false,
                    user_activate_success: true,
                    message: this.props.user_activate.message
                });
            } else {
                this.setState({
                    start_activate_user: false,
                    user_activate_success: false,
                    message: this.props.user_activate.message
                });
            }
        }
    }

    render() {
        return (
            <Modal
                show={(this.props.popup.data.id === 'activate_member_confirmation_popup' && this.props.popup.data.shouldDisplay)}
                backdrop="static"
                onHide={this.onClosePopupHandler.bind(this)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Restore
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <MessageBoxAlert show={this.state.message !== null}
                                     type={this.state.message != null && this.state.message.type}
                                     message={this.state.message !== null ? this.state.message.text : ''}/>

                    <div
                        style={this.state.start_activate_user === false && this.state.user_activate_success === false ? {display: 'block'} : {display: 'none'}}>
                        Are you sure that you want to
                        restore {this.props.member_profile != null ? this.props.member_profile.data.full_name : ''}?
                    </div>
                    <ProgressLoader
                        text={"Please wait... user is being restored"}
                        display={this.state.start_activate_user === true}/>

                </Modal.Body>

                <Modal.Footer>
                    <div style={this.state.start_activate_user === false &&
                        this.state.user_activate_success === true ? {display: 'block'} : {display: 'none'}}>
                        <Button
                            type={"button"}
                            onClick={this.onClosePopupHandler.bind(this)}
                            variant="secondary">Close</Button>
                    </div>

                    <div style={(this.state.start_activate_user === false &&
                        this.state.user_activate_success === false) ? {display: 'block'} : {display: 'none'}}>
                        <Button
                            type={"button"}
                            onClick={this.onClosePopupHandler.bind(this)}
                            variant="secondary mr-2">Cancel</Button>

                        <Button
                            type={"button"}
                            onClick={this.onClickActivateMember.bind(this)}
                            variant="primary">Restore</Button>

                    </div>

                </Modal.Footer>
            </Modal>
        );
    }

    onClosePopupHandler() {
        Logger.info("member activate verification popup is closed");
        this.setState(defaultState);
        this.props.resetReduxStore("MEMBER_PROFILE_RESET");

        let payload = {
            id: "activate_member_confirmation_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);

        //this is to reflect the latest activated changes in the list
        if (this.props.user_activate != null && this.props.user_activate.success === true) {
            Logger.info("reloading the latest member list");
            this.props.resetReduxStore("MEMBER_ACTIVATE_RESET");
            this.props.getAllMembers(this.props.user.data.auth.token, this.props.filter.type, this.props.filter.status);
        }
    }


    onClickActivateMember() {
        this.props.resetReduxStore("MEMBER_ACTIVATE_RESET");
        this.setState({start_activate_user: true});
        let userId = this.props.member_profile.data.user_id;
        let token = this.props.user.data.auth.token;
        Logger.info("request to activate member  [" + userId + "] ");
        this.props.activateUser(userId, token);
    }
}


const mapStateToProps = (state) => {
    return {
        member_profile: state.member_profile,
        popup: state.popup,
        user: state.user,
        user_activate: state.user_activate
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    activateUser: activateUserAction,
    resetReduxStore: resetReduxStoreAction,
    getAllMembers: getAllRegisteredMembersAction
};


export default withRouter(connect(mapStateToProps, actions)(ActivateMemberConfirmationPopup));
