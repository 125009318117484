import Logger from "../../core/util/Logger";
import {RestApiService} from "../../core/api/RestApiService";

export const userDownloadExerciseVideosFileAction = (token, type) => async dispatch => {

    Logger.info("downloading exercise video list");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let formData = new FormData();
    formData.append("type", type);
    let result = false;
    let response = await RestApiService.post("/members/exercise_videos/step/downloadExerciseList", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("Exercise Video List Downloading");
        result = response.data;
    } else {
        Logger.info("Exercise Video List Not Downloading");
    }
    dispatch({type: "HIDE_LOADER_SPINNER"});
    return result;
};