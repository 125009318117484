import React, {Component} from 'react';
import './style.css';
import {NavLink} from "react-router-dom";


export default class BackToHome extends Component {

    render() {
        return (
            <NavLink className={'btn btn-primary back-home-btn'} to={"/user/home"}><i className="fas fa-home"></i>Home</NavLink>
        );
    }
}


