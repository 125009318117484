import Logger from "../core/util/Logger";

export const getVideoProcessingActionQueueReducer = (state = null, action) => {

    if (action.type === 'GET_VIDEO_PROCESSING_ACTION_QUEUE_SUCCESS') {
        Logger.info("handling the success of data for job queue in reducer");
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'GET_VIDEO_PROCESSING_ACTION_QUEUE_FAILED') {
        Logger.info("handling the failure of data for job queue in reduce");
        return {
            success: false,
            data: action.payload
        };
    }
    return state;
};