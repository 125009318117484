import Logger from "../core/util/Logger";

export const adminCreateVideoProcessingProjectReducer = (state = null, action) => {

    if (action.type === 'ADMIN_VIDEO_PROCESSING_PROJECT_ADD_SUCCESS') {
        Logger.info("handling creating video process project success status in reducer");
        return {
            success: true,
            message: "Video Processing Project Created Successfully",
            data: action.payload
        };
    } else if (action.type === 'ADMIN_VIDEO_PROCESSING_PROJECT_ADD_FAILED') {
        Logger.info("handling creating video process project failed status in reducer");
        return {
            success: false,
            message: action.payload.errors,
            data: action.payload
        };
    }
    return state;
};
