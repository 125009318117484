import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const getAdminUserDetailsByGoogleAuthCode = (code) => async dispatch => {
    Logger.info("getting admin user details by google auth code [" + code + "] ");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    //converting request object  to form data
    const formData = new FormData();
    formData.append("code", code);

    let response = await RestApiService.post("/admin/users", formData);
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("admin user details received successfully");
        dispatch({
            type: "USER_AUTHENTICATION_SUCCESS",
            payload: response.data
        });
    }
    else {
        Logger.info("error in getting admin user details [" + response.data + "]");
        dispatch({type: "USER_AUTHENTICATION_FAILED", payload: response.data});
    }

    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch({type: "HIDE_LOADER_SPINNER"});
};