import React, {Component} from 'react';
import {connect} from "react-redux";
import AuthenticatedComponent from '../../common/AuthenticatedComponent';
import {createBrowserHistory} from 'history';
import {NavLink, withRouter} from "react-router-dom";
import Logger from "../../../core/util/Logger";

// const history = createBrowserHistory();

class SetupCompleted extends Component {


    /**
     * this is to stay in the same page even if the user back button pressed
     */
    componentWillUnmount() {
        if (this.props.history.action === "POP") {
            Logger.info("stay in the same page even if the back button pressed");
            this.props.history.push("/domains/completed");
        }
    }

    render() {
        return (
            <div id="app">

                <AuthenticatedComponent/>
                <div className="container text-center">
                    <div className="col-md-12 ">
                        <p className={"complete-text"}>
                            All done!
                        </p>
                        <div className={"complete-check"}>
                            <i className="fas fa-check-circle"></i>
                        </div>
                        {/*<p className={"complete-text2"}>*/}
                        {/*    All set. Our account manager will get in touch with you.*/}
                        {/*</p>*/}
                        <NavLink className={'btn btn-primary back-home-btn'}  to={"/user/home"}><i className="fas fa-home"></i>Home</NavLink>
                    </div>


                </div>

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        purchased_domain: state.purchased_domain
    }
};

export default withRouter(connect(mapStateToProps, {})(SetupCompleted));
