import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const checkDomainAvailabilityAction = (domainName, token, displayLoader = true) => async dispatch => {

    dispatch({type: "RESET_DOMAIN_AVAILABILITY"});

    if (displayLoader) {
        dispatch({type: "DISPLAY_LOADER_SPINNER"});
    }

    let formData = new FormData();
    formData.append("domain", domainName);

    let response = await RestApiService.post("/domains/check_availability", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        dispatch({
            type: "CHECK_DOMAIN_AVAILABILITY_SUCCESS",
            payload: response.data
        });
    }
    else {
        Logger.info("failed");
        dispatch({
            type: "CHECK_DOMAIN_AVAILABILITY_FAILED",
            payload: response.data
        });
    }

    if (displayLoader) {
        //loader need to be hidden, if the displayLoader is set yo true
        //wait for 1 second
        await new Promise(resolve => setTimeout(resolve, 1000));
        dispatch({type: "HIDE_LOADER_SPINNER"});
    }
};