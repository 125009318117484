import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const getUserDetailsByTokenAction = (token, loaderScreenHandling = true) => async dispatch => {
    Logger.info("getting user details by token [" + token + "] ");
    if(loaderScreenHandling) {
        dispatch({type: "DISPLAY_LOADER_SPINNER"});
    }

    let response = await RestApiService.get("/me", {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("user details received successfully");
        dispatch({
            type: "USER_AUTHENTICATION_SUCCESS",
            payload: response.data
        });
    } else {
        Logger.info("error in getting user details ", response.data);
        dispatch({type: "USER_AUTHENTICATION_FAILED", payload: response.data});
    }

    if(loaderScreenHandling) {
        //wait for 1 second
        await new Promise(resolve => setTimeout(resolve, 1000));
        dispatch({type: "HIDE_LOADER_SPINNER"});
    }
};