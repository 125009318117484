import Logger from "../core/util/Logger";

export const allRegisteredMembersReducer = (state = null, action) => {

    if (action.type === 'GET_ALL_REGISTERED_MEMBERS_SUCCESS') {
        Logger.info("handling the success of retrieving all registered members in reducer");
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'GET_ALL_REGISTERED_MEMBERS_FAILED') {
        Logger.info("handling the failure of retrieving all registered members in reducer");
        return {
            success: false,
            data: action.payload
        };
    }
    return state;
};
