import React, {Component} from 'react';

import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import GuestUserMenuBar from '../top-menu-bar/GuestUserMenuBar'
import Logger from "../../core/util/Logger";
import {reduxForm} from "redux-form";
import {Field} from 'redux-form';
import {LoginFromValidation} from './LoginFromValidation';
import {FormField} from "../../core/util/FormField";
import {authenticateUserAction} from "../../actions/user/user-authentication-action";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";


class Login extends Component {

    constructor() {
        super();
        this.state = {start: false};
    }


    async componentDidUpdate() {
        if (this.props.user.authenticated) {
            Logger.info("adding the token for the local-store");
            localStorage.setItem("user-token", this.props.user.data.auth.token);

            Logger.info("user successfully authenticated", this.props.user);
            this.props.history.push("/user/home");
        }
    }


    render() {
        return (
            <div id="app">

                <LoadingSpinner/>

                <nav className="navbar navbar-expand-md navbar-light navbar-laravel">
                    <GuestUserMenuBar/>
                </nav>

                <main className="py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">

                                {/*display if there is any error message (login failed)*/}
                                <div
                                    style={(this.props.user.data.message === undefined) ? {display: 'none'} : {display: 'block'}}
                                    className="alert alert-danger">
                                    {
                                        (this.props.user.data && this.props.user.data.message) !== undefined ? this.props.user.data.message : ''
                                    }
                                </div>

                                <div className="card">

                                    <div className="card-header">Login</div>

                                    <div className="card-body">
                                        <form onSubmit={this.props.handleSubmit(this.onSubmitHandler.bind(this))}>

                                            <div className="form-group row">
                                                <label htmlFor="email"
                                                       className="col-md-4 col-form-label text-md-right">E-Mail
                                                    Address</label>

                                                <div className="col-md-6">

                                                    <Field name="email" component={FormField.renderInputField}
                                                           type="text"
                                                           className="form-control"/>

                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="password"
                                                       className="col-md-4 col-form-label text-md-right">Password</label>

                                                <div className="col-md-6">
                                                    <Field name="password" component={FormField.renderInputField}
                                                           type="password"
                                                           className="form-control"/>

                                                </div>
                                            </div>

                                            <div className="form-group row mb-0">
                                                <div className="col-md-8 offset-md-4">
                                                    <button type="submit" className="btn btn-primary">
                                                        Login
                                                    </button>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }


    onSubmitHandler = (data) => {
        Logger.info("on submit login  form", data);
        this.props.authenticateUser(data);
    };
}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};

let actions = {
    authenticateUser: authenticateUserAction
};

let validate = new LoginFromValidation().validate;

export default withRouter(connect(mapStateToProps, actions)(reduxForm({
    form: 'loginForm',
    validate,
    enableReinitialize: true
})(Login)));