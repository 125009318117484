import React, {Component} from 'react';
import {connect} from "react-redux";
import Uppy from '@uppy/core'
import {Dashboard} from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import Tus from "@uppy/tus";
import GoldenRetriever from "@uppy/golden-retriever";
import './FileUploader.css';

const BASE_URL = process.env.REACT_APP_API_URL;

class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.uppy = Uppy({
            autoProceed: true,
            restrictions: {
                allowedFileTypes: ["image/*"]
            }
        });

    }

    componentDidMount() {
        this.setupUppy();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setupUppy();
    }

    componentWillUnmount() {
        this.uppy.close()
    }

    render() {
        if (this.props.user.authenticated && this.props.category != null) {
            return (
                <Dashboard width="100%" height="320px" uppy={this.uppy}/>
            )
        } else {
            return ('');
        }
    }

    setupUppy() {
        if (this.props.user.authenticated && this.uppy.getPlugin('Tus') == null && this.props.category !== undefined) {
            this.uppy
                .use(GoldenRetriever, {useWorker: true})
                .use(Tus, {
                    endpoint: BASE_URL + '/file/upload', // use your tus endpoint here
                    resume: false,
                    autoRetry: true,
                    retryDelays: [0, 1000, 3000, 5000],
                    chunkSize: 999999,
                    headers: {Authorization: "Bearer " + this.props.user.data.auth.token}
                })
                .on('complete', () => this.props.onSuccess());
            this.uppy
                .setMeta({category: this.props.category.id});
        }
    }
}

const actions = {};

const mapStoreToProps = (state) => {
    return {
        user: state.user
    }
};

export default connect(mapStoreToProps, actions)(FileUploader);
