import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const deleteEmailProviderAction = (token, domainName, mailBoxId) => async dispatch => {

    let response = await RestApiService.delete("/admin/domains/" + domainName + "/email-forwarders/" + mailBoxId, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("successfully removed the email forwarder");
        dispatch({
            type: "EMAIL_FORWARDER_DELETE_SUCCESS",
            payload: {
                message: "Email forwarder has been removed successfully!"
            }
        });
    } else {
        Logger.info("error in removing email forwarder");
        dispatch({
            type: "EMAIL_FORWARDER_DELETE_FAILED",
            payload: {
                message: "Error occurred while removing the email forwarder. Please try again shortly!"
            }
        });
    }
};