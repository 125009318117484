export const removeMemberUploadedFilesReducer = (state = null, action) => {

    if (action.type === 'REMOVE_MEMBER_UPLOADED_FILE_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'REMOVE_MEMBER_UPLOADED_FILE_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    } else if (action.type === 'RESET_REMOVE_MEMBER_UPLOADED_FILE') {
        return null;
    }
    return state;
};
