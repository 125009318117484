export const handleAdminGoogleAuthUrlReducer = (state = {data: ''}, action) => {

    if (action.type === 'ADMIN_AUTH_INITIATED_SUCCESS') {
        return {
            data: action.payload
        };
    }
    else if (action.type === 'ADMIN_AUTH_INITIATED_FAILED') {
        return {
            data: {
                "message": "Error while getting Google Authorization Url"
            }
        };
    }
    return state;
};
