import Logger from "../core/util/Logger";

export const checkDomainAvailabilityReducer = (state = null, action) => {

    if (action.type === 'CHECK_DOMAIN_AVAILABILITY_SUCCESS') {
        Logger.info("handling the domain search result in reducer");
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'CHECK_DOMAIN_AVAILABILITY_FAILED') {
        Logger.info("handling the failure of retrieving domain search result in reducer");
        return {
            success: false,
            data: action.payload
        };
    }
    else if (action.type === "RESET_DOMAIN_AVAILABILITY") {
        return null;
    }
    return state;
};
