import React, {Component} from 'react';
import Alert from "react-bootstrap/Alert";

export default class MessageBoxAlert extends Component {

    render() {
        return (
            <Alert show={this.props.show}
                   variant={this.props.type}>
                {
                    this.props.message !== undefined && this.props.message !== null && this.props.message
                }
            </Alert>
        );
    }

}
