import {VideoProcessingApiService} from "../../core/api/VideoProcessingApiService";
import Logger from "../../core/util/Logger";


export const getVideoProcessingProcessMatchReportAction = (token, projectId) => async dispatch => {
    Logger.info("getting details of ProcessMatchReport for project", projectId);

    let response = await VideoProcessingApiService.get("/projects/"+projectId+"/jobs?type=ProcessMatchReport&limit=1",{Authorization: "Bearer " + token});
    Logger.info(response.data);

    if (response.status === 200) {
        Logger.info("data for ProcessMatchReport received successfully");
        dispatch({
            type: "GET_VIDEO_PROCESSING_PROCESS_MATCH_REPORT_SUCCESS",
            payload: response.data
        });
    } else {
        Logger.info("error in getting ProcessMatchReport");
        dispatch({type: "GET_VIDEO_PROCESSING_PROCESS_MATCH_REPORT_FAILED", payload: response.data});
    }

    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
};