import React, {Component} from 'react';
import {connect} from "react-redux";
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import Logger from "../../../../core/util/Logger";
import {checkDomainAvailabilityAction} from "../../../../actions/user/check-domain-availability-action";
import {reserveNewDomainAction} from "../../../../actions/user/user-reserve-new-domain-action";


class DomainSearchPopUp extends Component {

    constructor() {
        super();
        this.state = {domain_search_started: false, message: ''};
        this.formik = React.createRef();
    }

    componentDidUpdate() {
        if (this.props.domain_availability != null && this.state.domain_search_started === true) {
            Logger.info("domain search result received. hiding the loading view ");
            this.setState({domain_search_started: false});
        }

        if (this.props.domain_availability != null &&
            this.state.message === '' &&
            this.props.domain_availability.success === false &&
            this.props.domain_availability.data.message !== undefined) {
            Logger.info("updating error message");
            this.setState({message: this.props.domain_availability.data.message});
        }
    }


    render() {
        return (
            <div id="new-domain-search">

                <div
                    style={(this.props.reserve_domain == null && this.state.domain_search_started === false) ? {display: 'block'} : {display: 'none'}}>
                    <span>
                    Instead of using your existing domain we can setup a new domain.
                    New domain will simplify the setup process for you.
                    You can always transfer it back. All available domains are non premium domains.
                </span>
                    <br/>

                    <div
                        style={(this.state.message !== '') ? {display: 'block'} : {display: 'none'}}
                        className="alert alert-danger" role="alert">
                        {this.state.message}
                    </div>

                    <Formik
                        ref={this.formik}
                        initialValues={{
                            domainName: '',
                        }}
                        validationSchema={Yup.object().shape({
                            domainName: Yup.string()
                                .required('domain name is required')
                                // .matches(/^[a-zA-Z0-9\\-]+.com$/,{message:'Only .com domains are allowed'})
                        })}
                        onSubmit={fields => {
                            this.handleDomainSearchSubmission(fields);
                        }}
                        render={({errors, status, touched}) => (
                            <div>
                                <Form>
                                    <div className="form align-items-center new-setup-domain">
                                        <div className="row">
                                            <div className="col-md-9">
                                                {/*<label htmlFor="domainName">First Name</label>*/}
                                                <Field name="domainName" type="text"
                                                       style={{paddingRight: 'none !important'}}
                                                       className={'form-control' + (errors.domainName && touched.domainName ? ' is-invalid' : '')}/>
                                                <ErrorMessage name="domainName" component="div"
                                                              className="invalid-feedback"/>
                                                <div
                                                    className={this.props.domain_availability != null
                                                    && this.props.domain_availability.data.domain_available ? "valid-feedback" : "invalid-feedback"}
                                                    style={this.props.domain_availability != null && this.props.domain_availability.success === true
                                                        ? {display: 'flex'} : {display: 'none'}}>
                                                    {
                                                        this.props.domain_availability != null && this.props.domain_availability.data.message
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <button type="submit" className="btn btn-primary">
                                                    Search
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </Form>
                                <div className={"get-domain-poup-btn"}>
                                    <button
                                        style={this.props.domain_availability != null && this.props.domain_availability.data.domain_available ? {display: 'block'} : {display: 'none'}}
                                        onClick={this.handleGetDomain}
                                        className="btn btn-primary">
                                        Get Domain
                                    </button>
                                </div>

                            </div>
                        )}
                    />
                </div>

                <div className="modal-body jrf-popup"
                     style={(this.props.domain_availability == null && this.state.domain_search_started === true) ? {display: 'block'} : {display: 'none'}}>
                    <p className="text-center">
                        Please wait... domain is being searched
                    </p>
                    <img src="../../../images/spinner.gif" className="loder"/>
                </div>

            </div>
        );
    }

    handleDomainSearchSubmission = (data) => {
        Logger.info("handling the domain search submission ", data);
        this.props.searchDomainName(data.domainName, this.props.user.data.auth.token, false);
        this.setState({domain_search_started: true});
        // clear if there is any previous error
        this.setState({message: ''});
    };

    handleGetDomain = () => {
        let domain = this.props.domain_availability.data.domain;
        Logger.info("request to get domain [" + domain + "] ");
        this.props.reserveNewDomain("reserve", {domainName: domain});
    }
}


const actions = {
    searchDomainName: checkDomainAvailabilityAction,
    reserveNewDomain: reserveNewDomainAction,
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        domain_availability: state.domain_availability,
        // purchased_domain: state.purchased_domain,
        reserve_domain: state.reserve_domain
    }
};


export default connect(mapStateToProps, actions)(DomainSearchPopUp);