import Logger from "../core/util/Logger";

export const userActivateReducer = (state = null, action) => {

    if (action.type === 'MEMBER_ACTIVATE_SUCCESS') {
        Logger.info("handling member activate success in reducer");
        return {
            success: true,
            message: {
                type: 'success',
                text: action.payload.message
            }
        };
    } else if (action.type === 'MEMBER_ACTIVATE_FAILED') {
        Logger.info("handling the member activate failed in reducer");
        return {
            success: false,
            message: {
                type: 'danger',
                text: action.payload.message
            }
        };
    } else if (action.type === 'MEMBER_ACTIVATE_RESET') {
        return null;
    }
    return state;
};
