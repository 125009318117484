import React, {Component} from 'react';
import {connect} from "react-redux";
import './dns-configurations.css';
import AuthenticatedComponent from '../../common/AuthenticatedComponent';
import TopMenuBar from '../../top-menu-bar/TopMenuBar'
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import Logger from "../../../core/util/Logger";
import {getDnsConfigurationDetailsByMemberAction} from "../../../actions/user/get-dns-configuration-details-by-member-action";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {getMemberProfileDetailsByUserIdAction} from "../../../actions/admin/get-member-details-by-id-action";
import HandleNewDomainPopup from './new-domain/HandleNewDomainPopup';
import HostingDetailsPopup from './HostingDetailsPopup';
import HelpFromTechPersonPopup from './HelpFromTechPersonPopup';
import RetrieveLatestDnsUpdate from './RetrieveLatestDnsUpdate';
import {getDnsProviderByIdAction} from "../../../actions/user/get-dns-provider-action";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";
import BackToHome from "../../user-dahsboard/BackToHome";
import AccountManagerHelp from "../../top-menu-bar/AccountManagerHelp";

class DomainConfigurations extends Component {


    constructor() {
        super();
        this.state = {
            fetching_dns_configurations: false,
            show_account_manager_details: false,
            error_message: null
        };
    }

    componentDidMount() {
        if (this.props.user != null && this.props.user.authenticated === true && this.state.fetching_dns_configurations === false) {
            Logger.info("getting user domain configurations details on loading the component");
            this.setState({fetching_dns_configurations: true});
            let token = this.props.user.data.auth.token;
            this.props.getDnsDetails(token);
        }
    }


    componentDidUpdate() {
        if (this.props.user != null && this.props.user.authenticated === true && this.state.fetching_dns_configurations === false) {
            Logger.info("getting user domain configurations details on loading the component");
            this.setState({fetching_dns_configurations: true});
            let token = this.props.user.data.auth.token;
            this.props.getDnsDetails(token);
        }

        if (this.props.dns.received === false && this.props.dns.status === "not-received" && this.state.error_message == null) {
            Logger.info("error updated ", this.props.dns.data);
            this.setState({error_message: this.props.dns.message});
        }
    }

    render() {
        return (
            <div id="app" className={"search-name"} onClick={() => this.onClickShowAccountManagerDetails(this, false)}>

                <AuthenticatedComponent display_loading_screen={false}/>

                <LoadingSpinner/>

                <TopMenuBar/>

                <div className="container">
                    <div className="card mt-5 pb-4">
                        <BackToHome/>

                        <AccountManagerHelp showAccountManager={this.onClickShowAccountManagerDetails.bind(this)}
                                            show={this.state.show_account_manager_details}/>

                        <div className={'pl-4 pr-4'}>


                            <main className="py-4">

                                <div style={this.state.error_message != null ? {display: 'flex'} : {display: 'none'}}
                                     className="alert alert-danger" role="alert">
                                    {this.state.error_message}
                                </div>

                                <div id="dns-configurations"
                                     style={this.props.dns.received === true ? {display: 'block'} : {display: 'none'}}>

                                    <h3>DNS set up </h3>

                                    <p>
                                        For our platform to work correctly you must setup the following DNS records in
                                        your
                                        domain.
                                    </p>


                                    <br/>
                                    <div
                                        style={this.props.dns_provider !== null && this.props.dns_provider.success === true ? {display: 'block'} : {display: 'none'}}>

                                        <a href={this.props.dns_provider != null ? this.props.dns_provider.data.instructions : '#'}
                                           target="_blank"> View instructions
                                            for {this.props.dns_provider != null && this.props.dns_provider.data.name}  </a>
                                    </div>
                                    <br/>


                                    <div className="table-responsive">

                                        <div>
                                            <p className={"domain-title"}>CNAME</p>
                                            <table className="table domain-table">
                                                <tbody>
                                                <tr>
                                                    <th>Domain</th>
                                                    <th>Value</th>
                                                    <th>TTL</th>
                                                    <th className={"color-white"}>.</th>
                                                </tr>
                                                <tr>
                                                    <td className={"input-td-1"}>
                                                        <div id="spf" className="input-group-prepend">
                                                            {
                                                                this.props.dns.data.urls.member_url
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={"input-td"}>
                                                        <input type="text"
                                                               value={(this.props.dns.data.settings.find(setting => setting.type === 'CNAME')).value}
                                                               disabled="disabled" className="form-control"/>
                                                    </td>
                                                    <td className={"input-td-2"}>
                                                        <input type="text"
                                                               value={(this.props.dns.data.settings.find(setting => setting.type === 'CNAME')).ttl}
                                                               disabled="disabled" className="form-control"/>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <i style={!(this.props.dns.data.settings.find(setting => setting.type === 'CNAME')).completed ? {display: 'block'} : {display: 'none'}}
                                                               className={'fas fa-times-circle text-danger'}/>
                                                            <i style={(this.props.dns.data.settings.find(setting => setting.type === 'CNAME')).completed ? {display: 'block'} : {display: 'none'}}
                                                               className={'fas fa-check-circle text-success'}/>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className={"input-td-1"}>
                                                        <div id="spf" className="input-group-prepend">
                                                            {
                                                                this.props.dns.data.urls.landing_page_url
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={"input-td"}>
                                                        <input type="text"
                                                               value={(this.props.dns.data.settings.find(setting => setting.type === 'CNAME_LANDING')).value}
                                                               disabled="disabled" className="form-control"/>
                                                    </td>
                                                    <td className={"input-td-2"}>
                                                        <input type="text"
                                                               value={(this.props.dns.data.settings.find(setting => setting.type === 'CNAME_LANDING')).ttl}
                                                               disabled="disabled" className="form-control"/>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <i style={!(this.props.dns.data.settings.find(setting => setting.type === 'CNAME_LANDING')).completed ? {display: 'block'} : {display: 'none'}}
                                                               className={'fas fa-times-circle text-danger'}/>
                                                            <i style={(this.props.dns.data.settings.find(setting => setting.type === 'CNAME_LANDING')).completed ? {display: 'block'} : {display: 'none'}}
                                                               className={'fas fa-check-circle text-success'}/>
                                                        </div>
                                                    </td>
                                                </tr>


                                                </tbody>
                                            </table>
                                        </div>

                                        <div>
                                            <p className={"domain-title-2"}>TXT</p>
                                            <table className="table">
                                                <tbody>
                                                <tr>
                                                    <th>Domain</th>
                                                    <th>Value</th>
                                                    <th>TTL</th>
                                                    <th className={"color-white"}>.</th>
                                                </tr>
                                                <tr>
                                                    <td className={"input-td-1"}>
                                                        <div id="spf" className="input-group-prepend">
                                                            {
                                                                this.props.dns.data.urls.domain
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className={"input-td"}>
                                                        <input type="text"
                                                               value={(this.props.dns.data.settings.find(setting => setting.type === 'TXT')).value}
                                                               disabled="disabled" className="form-control"/>
                                                    </td>
                                                    <td className={"input-td-2"}>
                                                        <input type="text"
                                                               value={(this.props.dns.data.settings.find(setting => setting.type === 'TXT')).ttl}
                                                               disabled="disabled" className="form-control"/>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <i style={!(this.props.dns.data.settings.find(setting => setting.type === 'TXT')).completed ? {display: 'block'} : {display: 'none'}}
                                                               className={'fas fa-times-circle text-danger'}/>
                                                            <i style={(this.props.dns.data.settings.find(setting => setting.type === 'TXT')).completed ? {display: 'block'} : {display: 'none'}}
                                                               className={'fas fa-check-circle text-success'}/>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div id="spf" className="input-group-prepend">
                                                            {
                                                                this.props.dns.data.urls.mandrill_url
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                            <textarea type="text" rows="6"
                                                      value={(this.props.dns.data.settings.find(setting => setting.type === 'MANDRILL')).value}
                                                      disabled="disabled" className="form-control"/>
                                                    </td>
                                                    <td>
                                                        <input type="text"
                                                               value={(this.props.dns.data.settings.find(setting => setting.type === 'MANDRILL')).ttl}
                                                               disabled="disabled" className="form-control"/>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <i style={!(this.props.dns.data.settings.find(setting => setting.type === 'MANDRILL')).completed ? {display: 'block'} : {display: 'none'}}
                                                               className={'fas fa-times-circle text-danger'}/>
                                                            <i style={(this.props.dns.data.settings.find(setting => setting.type === 'MANDRILL')).completed ? {display: 'block'} : {display: 'none'}}
                                                               className={'fas fa-check-circle text-success'}/>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="verify-email-button">
                                        <a onClick={this.onClickCheckLatestDnsDetails.bind(this)}
                                           className={'btn btn-primary mb-2'}>
                                            Check Records
                                        </a>
                                    </div>
                                    <p className={"text-center mt-0 mb-4"}>Check if records are set correctly</p>
                                    <hr/>
                                    <h4 className={"text-center mb-4 mt-4"}>There's an easy way</h4>
                                    <p className={"text-center"}>Feels complicated? Get a brand new domain on us and we
                                        will
                                        take care of the setup</p>
                                    <div className="verify-email-button secondary-color-btn">
                                        <a className={'btn btn-primary mb-4'}
                                           onClick={this.onClickGetNewDomainBtn.bind(this)}>
                                            Get a Domain
                                        </a>
                                    </div>
                                    <h4 className={"text-center mt-3 mb-0"}>or get some help</h4>
                                    <div className="domain-btn-wrap mt-0">
                                        <a onClick={this.onClickGetHelpFromTechPersonBtn.bind(this)}
                                           className={'btn btn-primary'}>
                                            <h5>Help from My Tech Person</h5>
                                            Share these instructions with your tech resource
                                        </a>

                                        <a onClick={this.onClickSubmitHostingDetailsBtn.bind(this)}
                                           className={'btn btn-primary'}>
                                            <h5>Help from MacroActive</h5>
                                            Request help from MacroActive
                                        </a>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>

                {/*<UserMandrillVerificationEmailPopup domain={this.props.dns.data.urls.domain}/>*/}
                <HandleNewDomainPopup/>
                <HostingDetailsPopup/>
                <HelpFromTechPersonPopup/>
                <RetrieveLatestDnsUpdate/>

            </div>
        );
    }


    onClickVerifyMandrillBtn() {
        Logger.info("displaying the mandrill verification view");
        let popupDetails = {
            id: "user_mandrill_verification_popup",
            shouldDisplay: true
        };
        this.props.popUpHandler(popupDetails);
    }


    onClickSubmitHostingDetailsBtn() {
        Logger.info("get help from tech person popup");
        let popupDetails = {
            id: "hosting_details_popup",
            shouldDisplay: true
        };
        this.props.popUpHandler(popupDetails);
    }


    onClickGetHelpFromTechPersonBtn() {
        Logger.info("displaying the hosting details submission popup");
        let popupDetails = {
            id: "help_from_tech_person_popup",
            shouldDisplay: true
        };
        this.props.popUpHandler(popupDetails);
    }


    onClickGetNewDomainBtn() {
        Logger.info("getting new domain");
        let popupDetails = {
            id: "new_domain_search_popup",
            shouldDisplay: true
        };
        this.props.popUpHandler(popupDetails);
    }

    onClickCheckLatestDnsDetails() {
        // Logger.info("retrieving the latest configuration details");
        this.setState({fetching_dns_configurations: true});
        let token = this.props.user.data.auth.token;
        this.props.getDnsDetails(token, false);
        let popupDetails = {
            id: "latest_dns_retrieval_popup",
            shouldDisplay: true
        };
        this.props.popUpHandler(popupDetails);
    }


    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }


    onSelectDnsProvider(event) {
        this.props.getDnsProviderById(event.target.value);
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        dns: state.dns,
        dns_provider: state.dns_provider
    }
};

const actions = {
    getDnsDetails: getDnsConfigurationDetailsByMemberAction,
    popUpHandler: popupDisplayHandlerAction,
    getMemberDetailsById: getMemberProfileDetailsByUserIdAction,
    getDnsProviderById: getDnsProviderByIdAction,
    resetReduxStore: resetReduxStoreAction
};

export default connect(mapStateToProps, actions)(DomainConfigurations);
