import Logger from "../core/util/Logger";

export const memberProfileUpdateReducer = (state = {success: false, data: undefined}, action) => {

    if (action.type === 'MEMBER_PROFILE_UPDATE_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'MEMBER_PROFILE_UPDATE_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    }
    return state;
};
