import React, {Component} from 'react';
import './member-file-uploading.css';
import Logger from "../../core/util/Logger";
import {uploadMemberFilesAction} from "../../actions/user/upload-member-files-action";
import {connect} from "react-redux";
import {resetReduxStoreAction} from "../../actions/reset-redux-store-action";
import {getFileUploadMenuDetailsAction} from "../../actions/user/get-file-upload-menu-details-action";
import MemberFileGridView from "./files-grid/MemberFileGridListView";
import FileCategorySampleImage from "./FileCategorySampleImage";
import FileUploader from "./uploader/FileUploader";


// //todo get the api key from the config
let apiKey = "AYTmzrLQEqXZtUOLrYytQz";

class FileCategoryDetails extends Component {

    //todo get the api key from the configuration
    constructor() {
        super();
        this.state = {api_key: apiKey};
    }

    componentDidUpdate() {
        if (this.props.uploaded_member_files != null) {
            Logger.info("re-fetching the uploaded member files ");
            this.props.resetReduxStore('UPLOADED_MEMBER_FILES_RESET');
            this.props.getFileUploadMenuDetailsForUser(this.props.user.data.auth.token);
        }
    }


    render() {
        return (
            <div style={this.props.category != null && this.props.category !== undefined ? {display: 'block'} : {display: 'none'}}>

                <div className="w3-container w3-teal">
                    <h1>{this.props.category != null ? this.props.category.instructions.title : ''}</h1>
                </div>

                <p>
                    {
                        this.props.category != null ? this.props.category.instructions.description : ''
                    }
                </p>
                {this.props.category != null && this.props.category !== undefined ?
                    <FileUploader key={this.props.category.id} category={this.props.category} onSuccess={this.fileStackUploadCallback} />
                    : ''
                }

                <div className={"row"}>
                    <MemberFileGridView category={this.props.category}/>
                </div>

                <div className="img-count"
                     style={this.props.category != null && this.props.category.min_files_limit > 0 ? {display: 'block'} : {display: 'none'}}>
                    <p>
                        {this.getFilesCount(this.props.category)}/{this.props.category != null && this.props.category.min_files_limit} image(s)
                    </p>
                </div>

                <div
                    style={(this.props.category != null && this.props.category.sample_image != null) ? {display: 'block'} : {display: 'none'}}
                    className="img-btn">
                    <img src={this.props.category != null && this.props.category.sample_image}/>
                    {/*<FileCategorySampleImage*/}
                    {/*    category={this.props.category}/>*/}
                </div>
            </div>
        );
    }


    getFilesCount = (categoryObject) => {
        if (categoryObject != null) {
            return this.props.file_upload_categories != null &&
                this.props.file_upload_categories.data.find(category => category.key === categoryObject.key).files_count;
        }
        return 0;
    };

    fileStackUploadCallback = () => {
        this.props.getFileUploadMenuDetailsForUser(this.props.user.data.auth.token);
    };
}


const actions = {
    uploadMemberFilesData: uploadMemberFilesAction,
    resetReduxStore: resetReduxStoreAction,
    getFileUploadMenuDetailsForUser: getFileUploadMenuDetailsAction
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        uploaded_member_files: state.uploaded_member_files,
        file_upload_categories: state.file_upload_categories
    }
};


export default connect(mapStateToProps, actions)(FileCategoryDetails);
