import React, {Component} from 'react';
import {Field} from 'redux-form';
import '../sign-up.css';
import {FormField} from "../../../core/util/FormField";
import SizeGuidePopUp from '../SizeGuidePopUp';
import {NavLink} from "react-router-dom";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";


let months = [
    {"key": "default", "value": "Select month"},
    {"key": "January", "value": "January"},
    {"key": "February", "value": "February"},
    {"key": "March", "value": "March"},
    {"key": "April", "value": "April"},
    {"key": "May", "value": "May"},
    {"key": "June", "value": "June"},
    {"key": "July", "value": "July"},
    {"key": "August", "value": "August"},
    {"key": "September", "value": "September"},
    {"key": "October", "value": "October"},
    {"key": "November", "value": "November"},
    {"key": "December", "value": "December"},
];

class PersonalDetails extends Component {

    render() {
        return (
            <div className="card">


                <div id="headingOne" className="card-header">
                    <h2 className="mb-0">
                        <button
                            onClick={() => this.props.onAccordionClick('personal_details')}
                            type="button" data-toggle="collapse"
                            aria-expanded=" true "
                            className="btn btn-link ">
                            About You
                        </button>
                    </h2>
                </div>
                <div id="collapseOne" aria-labelledby="headingOne"
                     data-parent="#accordionExample"
                     className={this.props.expandOnSubmit === true ? "accordion-expand" : 'accordion-collapse'}>
                    <div className="card-body">

                        <SizeGuidePopUp/>


                        <div className="form-group row">
                            <label htmlFor="full_name" className="col-md-4 col-form-label text-md-right">What
                                is your full name? <span
                                    className="text-danger"> *</span></label>

                            <div className="col-md-6">

                                <Field name="full_name" component={FormField.renderInputField} type="text"
                                       className="form-control"
                                       onChange={!this.props.profile_edit ? this.props.persistFormData : undefined}
                                       label="Full name"/>
                            </div>
                        </div>
                        <div className="form-group row"><label htmlFor="email"
                                                               className="col-md-4 col-form-label text-md-right">What
                            is your email?<span
                                className="text-danger"> *</span></label>
                            <div className="col-md-6">

                                <Field name="email" component={FormField.renderInputField} type="text"
                                       className="form-control"
                                       style={{display: 'none'}}
                                       disabled={this.props.profile_edit ? "disabled" : ""}
                                       onChange={this.props.persistFormData}
                                       label="Email"/>
                            </div>
                        </div>
                        <div className="form-group row"><label
                            className="col-md-4 col-form-label text-md-right">What is
                            your
                            best phone number? </label>

                            <div className="col-md-6">

                                <Field name="phone_number" component={FormField.renderInputField} type="text"
                                       className="form-control"
                                       onChange={this.props.persistFormData}
                                       label="Phone number"/>
                            </div>
                        </div>
                        <hr/>


                        <div className="form-group row"><label
                            className="col-md-4 col-form-label text-md-right">
                            Birthday
                        </label>


                            <div className="col-md-3">

                                <Field name="birthday_day" component={FormField.renderInputField} type="text"
                                       className="form-control"
                                       onChange={this.props.persistFormData}
                                       label="Day"/>
                            </div>

                            <div className="col-md-3">

                                <Field className={"form-control"}
                                       name="birthday_month"
                                       children={months}
                                       component={FormField.renderSelectField}/>

                            </div>
                        </div>


                        <div className="form-group row"><label
                            className="col-md-4 col-form-label text-md-right">
                            Your T-Shirt size
                        </label>

                            <div className="col-md-6">

                                <div className="row">
                                    <div className="col-md-6">
                                        <Field className={"form-control"} name="t_shirt_size" component="select">
                                            <option value="default">Select size</option>
                                            <option value="S">S</option>
                                            <option value="M">M</option>
                                            <option value="L">L</option>
                                            <option value="XL">XL</option>
                                            <option value="XXL">XXL</option>
                                        </Field>
                                    </div>
                                    <div className="col-md-6">
                                        <NavLink className={'size-guide'}
                                                 onClick={() => this.onClickSizeGuide('../images/unisexteesizechart.jpg')}>Size
                                            guide</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="form-group row"><label
                            className="col-md-4 col-form-label text-md-right">
                            Your jean size
                        </label>

                            <div className="col-md-6">

                                <div className="row">
                                    <div className="col-md-6">
                                        <Field className={"form-control"} name="jean_size" component="select">
                                            <option value="default">Select waist size</option>
                                            <option value="28 inch / 71 cm">28 inch / 71 cm</option>
                                            <option value="30 inch / 76 cm">30 inch / 76 cm</option>
                                            <option value="32 inch / 81 cm">32 inch / 81 cm</option>
                                            <option value="34 inch / 86 cm">34 inch / 86 cm</option>
                                            <option value="36 inch / 91 cm">36 inch / 91 cm</option>
                                            <option value="38 inch / 97 cm">38 inch / 97 cm</option>
                                            <option value="40 inch / 102 cm">40 inch / 102 cm</option>
                                            <option value="42 inch / 107 cm">42 inch / 107 cm</option>
                                            <option value="44 inch / 112 cm">44 inch / 112 cm</option>
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row"><label
                            className="col-md-4 col-form-label text-md-right">
                            Your shoe size
                        </label>

                            <div className="col-md-6">

                                <div className="row">
                                    <div className="col-md-6">
                                        <Field className={"form-control"} name="shoe_size" component="select">
                                            <option value="default">Select foot size</option>
                                            <option value="US 6">US 6</option>
                                            <option value="US 6.5">US 6.5</option>
                                            <option value="US 7">US 7</option>
                                            <option value="US 7.5">US 7.5</option>
                                            <option value="US 8">US 8</option>
                                            <option value="US 8.5">US 8.5</option>
                                            <option value="US 9">US 9</option>
                                            <option value="US 9.5">US 9.5</option>
                                            <option value="US 10">US 10</option>
                                            <option value="US 10.5">US 10.5</option>
                                            <option value="US 11">US 11</option>
                                            <option value="US 11.5">US 11.5</option>
                                            <option value="US 12">US 12</option>
                                            <option value="US 13">US 13</option>
                                            <option value="US 14">US 14</option>
                                            <option value="US 15">US 15</option>
                                        </Field>
                                    </div>
                                    <div className="col-md-6">
                                        <NavLink className={'size-guide'}
                                                 onClick={() => this.onClickSizeGuide('../images/shoe_size_guide.png')}>Size
                                            guide</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <button type="button" onClick={() => this.props.onAccordionClick('company_details')}
                                className="float-right btn btn-success  mb-5">
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    onClickSizeGuide(url) {
        Logger.info("display size guide  popup");
        let payload = {
            id: "t_shirt_size_guide_popup",
            shouldDisplay: true,
            url: url
        };
        this.props.handlePopupVisibility(payload);
    }
}

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction
};

const mapStateToProps = (state) => {
    return {
        popup: state.popup,
    };
};

export default withRouter(connect(mapStateToProps, actions)(PersonalDetails));

