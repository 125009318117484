import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const createNewEmailForwarderAction = (userId, token, data) => async dispatch => {

    Logger.info(" creating new email forwarder ", data);

    let formData = new FormData();

    formData.append("user_id", userId);
    formData.append("mailbox_id", data.mailbox_id);
    formData.append("email", data.email);

    let response = await RestApiService.post("admin/domains/new-email-forwarder", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    //wait for 2 seconds
    await new Promise(resolve => setTimeout(resolve, 2000));

    if (response.status === 200) {
        Logger.info("email forwarder created");
        dispatch({
            type: "CREATE_EMAIL_FORWARDER_SUCCESS",
            payload: {
                message: "Email forwarder has been successfully created!"
            }
        });
    } else {
        Logger.info("error in creating email forwarder");
        dispatch({
            type: "CREATE_EMAIL_FORWARDER_SUCCESS",
            payload: {
                message: "Error occurred while creating the email forwarder. Please try again shortly"
            }
        });
    }
};