import React, {Component} from 'react';
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import dumbbell from './dumbbell-hold-transparent.png'

class NoDefaultWorkouts extends Component {


    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div className="card card border-0 border-top">
                <div className="card-body my-5">
                    <div className="text-center">
                        <img src={dumbbell} className="img-fluid" width="200px" alt=""/>
                        <h3 className="py-2">
                            Our on-boarding specialist will help you

                        </h3>
                        <p>
                            One of our on-boarding specialist will get in touch with you soon to help build your custom workout program.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}


const actions = {
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};


export default connect(mapStateToProps, actions)(NoDefaultWorkouts);
