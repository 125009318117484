import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const archiveUserAction = (userId, token) => async dispatch => {
    Logger.info("archive member [" + userId + "]  with auth token [" + token + "] ");

    let response = await RestApiService.delete("/admin/members/" + userId,{Authorization: "Bearer " + token});
    Logger.info(response);

    //wait for 3 second
    await new Promise(resolve => setTimeout(resolve, 3000));

    if (response.status === 200) {
        Logger.info("user was successfully archived");
        dispatch({
            type: "MEMBER_ARCHIVE_SUCCESS",
            payload: {
                message: "Member has been archived successfully"
            }
        });
    } else {
        Logger.info("error in archiving  the user");
        dispatch({
            type: "MEMBER_ARCHIVE_FAILED",
            payload: {
                message: "Error while archiving the member"
            }
        });
    }
};