import {VideoProcessingApiService} from "../../core/api/VideoProcessingApiService";
import Logger from "../../core/util/Logger";

export const adminCreateVideoProcessingProjectAction = (token, userId, data) => async dispatch => {

    Logger.info("adding video processing project for member [" + userId + "]   with token [" + token + "]");
    Logger.info('create project', data);
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await VideoProcessingApiService.post("projects" , data, {Authorization: "Bearer " + token});
    Logger.info(response);

    //wait 3 seconds
    await new Promise(resolve => setTimeout(resolve, 3000));

    if (response.status === 201) {
        Logger.info("video processing project added");
        dispatch({
            type: "ADMIN_VIDEO_PROCESSING_PROJECT_ADD_SUCCESS",
            payload: response.data
        });
    } else {
        Logger.info("video processing project adding failed");
        dispatch({
            type: "ADMIN_VIDEO_PROCESSING_PROJECT_ADD_FAILED",
            payload: response.data
        });
    }
    dispatch({type: "HIDE_LOADER_SPINNER"});
};