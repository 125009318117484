import Logger from "../core/util/Logger";

let initialState = {
    status: "pending",
    received: false,
    data: {
        urls: {
            domain: '',
            sub_domain: '',
            mandrill: '',
            member_url: '',
            landing_page_url: '',
            mandrill_url: ''
        },
        settings: [
            {
                type: 'CNAME',
                value: '',
                ttl: ''
            },
            {
                type: 'CNAME_LANDING',
                value: '',
                ttl: ''
            },
            {
                type: 'TXT',
                value: '',
                ttl: ''
            },
            {
                type: 'MANDRILL',
                value: '',
                ttl: ''
            }
        ],
        progress: {
            dns_verification_attempted: false,
            verification_status: ''
        },
        dns_providers: []
    }
};

export const getDnsDetailsReducer = (state = initialState, action) => {
    if (action.type === 'DNS_DETAILS_RECEIVED_SUCCESS') {
        Logger.info("handle redux-store for storing dns details received....");
        Logger.info(action.payload);
        Logger.info("payload printed");
        return {
            status: "received",
            received: true,
            data: action.payload
        };
    }
    else if (action.type === 'DNS_DETAILS_RECEIVED_FAILED') {
        Logger.info("handle redux-store for displaying error message for not receiving the DNS Details");
        return {
            status: "not-received",
            received: false,
            message: action.payload.message,
            //adding the default state data for preventing UI rendering issues
            data: state.data
        };
    }
    else if (action.type === 'RESET_DNS_DETAILS_RECEIVED') {
        return initialState;
    }
    return state;
};
