import React, {Component} from 'react';
import Logger from "../../core/util/Logger";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getUserDetailsByTokenAction} from "../../actions/user/get-userdetails-by-token-action";

class AuthenticatedComponent extends Component {

    componentDidMount() {
        let token = localStorage.getItem('user-token');
        if (!this.props.user.authenticated && token !== null) {
            Logger.info("getting user details from api endpoint");
            this.props.getUserDetails(token,this.props.display_loading_screen);
        }
        else if (!this.props.user.authenticated && token === null) {
            Logger.info("AuthenticatedComponent: redirecting unauthenticated user to login view");
            this.props.history.push("/login");
        }
    }

    componentDidUpdate() {
        let token = localStorage.getItem('user-token');
        if (!this.props.user.authenticated && (token === null || this.props.user.data.message !== undefined)) {
            Logger.info("AuthenticatedComponent: redirecting unauthenticated user to login view ");
            this.props.history.push("/login");
        }
    }


    render() {
        return (
            <div style={{display: 'none'}}/>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};

const actions = {
    getUserDetails: getUserDetailsByTokenAction
};

export default withRouter(connect(mapStateToProps, actions)(AuthenticatedComponent));