export const createNewEmailForwarderReducer = (state = null, action) => {

    if (action.type === 'CREATE_EMAIL_FORWARDER_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'CREATE_EMAIL_FORWARDER_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    } else if (action.type === 'CREATE_EMAIL_FORWARDER_RESET') {
        return null;
    }
    return state;
};
