import Logger from "../../core/util/Logger";
import {RestApiService} from "../../core/api/RestApiService";

export const userGetExerciseVideosStepAction = (token) => async dispatch => {

    Logger.info("getting exercise video step information");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await RestApiService.get("/members/exercise_videos/step/info", {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("exercise video step information is received");
        dispatch({
            type: "GET_EXERCISE_VIDEO_STEP_SUCCESS",
            payload: {
                message: "Information received successfully!",
                data: response.data
            }
        });
    } else {
        Logger.info("exercise video step information is NOT received");
        dispatch({
            type: "GET_EXERCISE_VIDEO_STEP_FAILED",
            payload: {
                message: response.data.errors
            }
        });
    }
    dispatch({type: "HIDE_LOADER_SPINNER"});
};