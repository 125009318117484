import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../../actions/popup-display-action";
import AuthenticatedComponent from '../../../common/AuthenticatedComponent';
import DomainSearchPopUp from './DomainSearchPopUp'
import PurchaseSelectedDomainPopup from './PurchaseSelectedDomainPopup'

class HandleNewDomainPopup extends Component {

    constructor() {
        super();
        this.formik = React.createRef();
        this.state = {message: {type: '', text: ''}};
    }


    render() {
        return (
            <div tabIndex="-1" role="dialog"
                 style={this.props.popup.data.id === 'new_domain_search_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                 className="modal fade show"
                 aria-modal="true">
                <div className="overlay">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 id="exampleModalLabel" className="modal-title text-center">
                                    Search your new domain
                                </h5>

                                <button type="button" data-dismiss="modal" aria-label="Close"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="close">
                                    <span aria-hidden="true">×</span></button>
                            </div>

                            <div className="modal-body">

                                <div
                                    style={this.state.message != null && this.state.message.text !== '' ? {display: 'block'} : {display: 'none'}}
                                    className={this.state.message != null && this.state.message.type === 'success' ? "alert alert-success" : "alert alert-danger"}
                                    role="alert">
                                    {
                                        (this.state.message !== null) ? this.state.message.text : ''
                                    }
                                </div>


                                <div id={"get-new-domain"}>

                                    <div id="domain-search">
                                        <AuthenticatedComponent display_loading_screen={false}/>
                                        <DomainSearchPopUp/>
                                        <PurchaseSelectedDomainPopup/>
                                    </div>
                                </div>

                            </div>

                            {/*<div className="modal-footer">*/}
                                {/*<button type="button" data-dismiss="modal"*/}
                                        {/*onClick={this.onClosePopupHandler.bind(this)}*/}
                                        {/*className="btn btn-secondary">Close*/}
                                {/*</button>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClosePopupHandler() {
        Logger.info("new domain search popup is closed");
        //clear if there is any previous messages
        let message = {
            type: '',
            text: ''
        };
        this.setState({
            message: message
        });
        Logger.info(this.state);
        let payload = {
            id: "new_domain_search_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        popup: state.popup,
        reserve_domain: state.reserve_domain,
        domain_availability: state.domain_availability
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction
};


export default withRouter(connect(mapStateToProps, actions)(HandleNewDomainPopup));
