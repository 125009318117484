import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";
import { toast } from "react-toastify";

export const deleteMemberAllExerciseVideosAction = (userId, token, ) => async dispatch => {

    Logger.info("deleting member all exercise videos");

    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await RestApiService.delete("members/"+userId+"/exercise_videos", {Authorization: "Bearer " + token});
    Logger.info(response);

    //wait for 2 seconds
    await new Promise(resolve => setTimeout(resolve, 2000));

    if (response.status === 200) {
        Logger.info("member all exercise videos deleted");
        dispatch({
            type: "DELETE_MEMBER_ALL_EXERCISE_VIDEOS_SUCCESS",
            payload: response.data
        });
        toast.success(response.data.message);
    } else {
        Logger.info("error in deleting all member exercise videos");
        dispatch({
            type: "DELETE_MEMBER_ALL_EXERCISE_VIDEOS_FAILED",
            payload: {
                message: "Error occurred while deleting all exercise videos. Please try again shortly"
            }
        });
        toast.warn(response.data.message);
    }

    dispatch({type: "HIDE_LOADER_SPINNER"});
};
