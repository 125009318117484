import React, {Component} from 'react';
import './style.css';
import AuthenticatedComponent from "../common/AuthenticatedComponent";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from "../top-menu-bar/TopMenuBar";
import Logger from "../../core/util/Logger";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getUserDashBoardSettingsAction} from "../../actions/user/user-user-dashboard-settings-action";
import DashboardCompletedItem from "./items/DashboardCompletedItem";
import DashboardPendingItem from "./items/DashboardPendingItem";
import DashboardRestrictedItem from "./items/DashboardRestrictedItem";
import NotificationPopup from "./NotificationPopup";
import AccountManagerHelp from "../top-menu-bar/AccountManagerHelp";
import Button from "react-bootstrap/Button";
import {userLogoutAction} from "../../actions/logout-action";

let initialState = {
    notification_message: null,
    loading_user_dashboard: false,
    show_account_manager_details: false,
    show_back_to_admin: false
};

class UserDashboard extends Component {

    constructor() {
        super();
        this.state = initialState;
    }

    componentDidMount() {
        const {location, history} = this.props;
        if (location.state !== undefined && location.state.message !== undefined && this.state.notification_message === null) {
            this.setState({notification_message: location.state.message});
        }
        //clearing the location state message (with browser history)
        history.replace();

        this.retrieveUserDashboard();
    }


    componentDidUpdate() {
        this.retrieveUserDashboard();
        if (localStorage.getItem('is-admin-attempt') && this.state.show_back_to_admin === false) {
            Logger.info("displaying the back to admin button*********");
            this.setState({show_back_to_admin: true});
        }
    }


    render() {
        return (

            <div onClick={() => this.onClickShowAccountManagerDetails(this, false)}>

                <Button
                    onClick={() => this.backToAdmin()}
                    style={this.state.show_back_to_admin ? {display: 'block'} : {display: 'none'}}
                    className="back-to-admin" variant="success">Back to admin
                </Button>

                <AuthenticatedComponent/>

                <LoadingSpinner/>

                <TopMenuBar/>

                <div className="container help-wrap">


                    <div className="card mt-5 mb-2 pl-2 pr-2 pb-5">

                        <AccountManagerHelp showAccountManager={this.onClickShowAccountManagerDetails.bind(this)}
                                            show={this.state.show_account_manager_details}/>

                        <main>
                            <div className="container">

                                <div className="dashboard-head">
                                    <h4 className="macroactive-title">Welcome</h4>
                                    <p className="fc-ternary">You are just couple of steps away from launching your own
                                        fitness
                                        program.</p>
                                </div>

                                <div
                                    style={(this.state.notification_message !== null) ? {display: 'block'} : {display: 'none'}}
                                    className="alert alert-success" role="alert">
                                    {this.state.notification_message}
                                </div>


                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="dashboard-wrap">
                                            {
                                                this.showDashboard()
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="support-box">
                                            <p className="support-box-title">Need support?</p>
                                            <p className="support-box-subtext">Contact your account manager</p>
                                            <hr/>
                                            <p className="support-box-title">{this.props.user.data.account_manager_details && this.props.user.data.account_manager_details.name}</p>
                                            <p className="support-box-subtext mb-1">{this.props.user.data.account_manager_details && this.props.user.data.account_manager_details.email}</p>
                                            <p className="support-box-subtext mb-1">{this.props.user.data.account_manager_details && this.props.user.data.account_manager_details.contact_number}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <NotificationPopup/>

                        </main>
                    </div>
                </div>
            </div>
        );
    }

    onClickNavigation(path) {
        Logger.info("navigate to " + path);
        this.setState({notification_message: null});
        this.props.history.push(path);
    }


    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {

            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }


    retrieveUserDashboard() {
        if (this.props.user !== null && this.props.user.authenticated === true && this.state.loading_user_dashboard === false) {
            Logger.info("retrieve user dashboard");
            this.setState({loading_user_dashboard: true});
            this.props.getUserDashBoardSettings(this.props.user.data.auth.token);
        }
    }


    showDashboard() {
        if (this.props.user_dashboard !== null && this.props.user_dashboard.success) {
            return this.props.user_dashboard.data.map((setting, index) => {
                if (setting.status === "completed") {
                    return <DashboardCompletedItem key={setting.id} data={setting}
                                                   onClickNavigation={this.onClickNavigation.bind(this)}/>
                } else if (setting.status === "pending") {
                    return <DashboardPendingItem key={setting.id} data={setting}
                                                 onClickNavigation={this.onClickNavigation.bind(this)}/>
                }
                return <DashboardRestrictedItem key={setting.id} data={setting}/>
            });
        }
    }


    backToAdmin() {
        localStorage.removeItem("user-token");
        this.props.userSignOut();
        this.props.history.push("/admin/login");
    }
}

const actions = {
    userSignOut: userLogoutAction,
    getUserDashBoardSettings: getUserDashBoardSettingsAction
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        user_dashboard: state.user_dashboard,
    }
};

export default withRouter(connect(mapStateToProps, actions)(UserDashboard));
