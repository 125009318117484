import React from 'react';
import './not-found.css';

export default () => {

    return (
        <div className={"container"}>
            <div className="row">
                <div className="not-found text-center">
                    <i className="fas fa-exclamation-triangle"></i>
                    <h1>404 Not Found!</h1>
                </div>
            </div>


        </div>
    );
};