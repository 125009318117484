import React, {Component} from 'react';
import {connect} from "react-redux";
import AuthenticatedComponent from '../../common/AuthenticatedComponent';
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import TopMenuBar from '../../top-menu-bar/TopMenuBar';
import '../domain-setup-main.css';
import BackToHome from "../../user-dahsboard/BackToHome";
import AccountManagerHelp from "../../top-menu-bar/AccountManagerHelp";
import NewDomainSearch from "./NewDomainSearch";
import NewDomainReserve from "./NewDomainReserve";


class NewDomainPurchaseMain extends Component {

    constructor() {
        super();
        this.formik = React.createRef();
        this.state = {
            show_domain_search_view: true,
            show_domain_reserve_view: false,
            show_account_manager_details: false
        }
    }


    render() {
        return (
            <div id="app" onClick={() => this.onClickShowAccountManagerDetails(this, false)}>

                <AuthenticatedComponent/>

                <LoadingSpinner/>

                <TopMenuBar/>

                <div className={'container'}>
                    <div className="card content-box ">
                        <BackToHome/>

                        <AccountManagerHelp showAccountManager={this.onClickShowAccountManagerDetails.bind(this)}
                                            show={this.state.show_account_manager_details}/>
                        <main>
                            <div className="container text-center">
                                <div className="introing-new-domain">
                                    <div className="first-section">
                                        <h3>New Domain</h3>
                                    </div>
                                    <div className="second-section">
                                        <p className="title">Search your new domain</p>
                                        <p>Search an available domain to continue. <span>MacroActive will bear the cost of the domain.</span>
                                        </p>
                                    </div>
                                    <div className="third-section">

                                        <NewDomainSearch display_form={this.state.show_domain_search_view}
                                                         parent_context={this}/>

                                        <NewDomainReserve display_form={this.state.show_domain_reserve_view}
                                                          parent_context={this}/>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }


    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }

    showDomainReservationView() {
        this.setState({
            show_domain_search_view: false,
            show_domain_reserve_view: true
        });
    }
}


const mapStateToProps = (state) => {
    return {
        purchased_domain: state.purchased_domain,
        domain_availability: state.domain_availability,
    }
};


export default connect(mapStateToProps, {})(NewDomainPurchaseMain);
