import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const getAdminGoogleAuthUrlAction = () => async dispatch => {

    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await RestApiService.get("/admin/google/auth-url");
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("admin authentication url received successfully");
        dispatch({    
            type: "ADMIN_AUTH_INITIATED_SUCCESS",
            payload: response.data
        });
    }
    else {
        Logger.info("error in getting admin authentication url for google [" + response.data + "]");
        dispatch({type: "ADMIN_AUTH_INITIATED_FAILED"});
    }

    //loader need to be hidden, if the displayLoader is set yo true
    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch({type: "HIDE_LOADER_SPINNER"});
};