export const getDnsProviderDetailsByIdReducer = (state = null, action) => {
    if (action.type === 'DNS_PROVIDER_DETAILS_RECEIVED_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'DNS_PROVIDER_DETAILS_RECEIVED_FAILED') {
        return {
            success: false,
            data: state
        };
    }
    else if (action.type === 'RESET_DNS_PROVIDER_DETAILS') {
        return null;
    }
    return state;
};

