import React, {Component} from 'react';
import Ticker from "../common/ticker/Ticker";
import {getReleasesAction} from "../../actions/admin/get-releases-action";
import {connect} from "react-redux";

class ReleaseTicker extends Component {

    componentDidMount() {
        this.props.getReleases();
    }

    render() {
        return (
            <Ticker messages={this.props.releases}/>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        releases: state.releases
    };
};


const actions = {
    getReleases: getReleasesAction
};

export default connect(mapStateToProps, actions)(ReleaseTicker);