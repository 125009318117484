import {ReleasesApiService, RestApiService} from "../../core/api/ReleasesApiService";
import Logger from "../../core/util/Logger";


export const getReleasesAction = () => async dispatch => {
    Logger.info("getting details of releases available");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await ReleasesApiService.get();
    Logger.info(response.data);

    if (response.status === 200) {
        Logger.info("data for all releases received successfully");
        dispatch({
            type: "GET_ALL_RELEASES_SUCCESS",
            payload: response.data.updates
        });
    } else {
        Logger.info("error in getting all data of all releases");
        dispatch({type: "GET_ALL_RELEASES_FAILED", payload: response.data});
    }

    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 500));
    dispatch({type: "HIDE_LOADER_SPINNER"});
};