import React, {Component} from 'react';
// import {removeMemberUploadedFileAction} from "../../../actions/user/remove-member-uploaded-file-action";
// import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
// import {getFileUploadMenuDetailsAction} from "../../../actions/user/get-file-upload-menu-details-action";
// import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";


export default class MemberFileGridItem extends Component {

    // componentDidUpdate() {
    //     if (this.props.remove_member_file != null && this.props.remove_member_file.success) {
    //         Logger.info("reloading the member files information (including the categories)");
    //         this.props.resetReduxStore('RESET_REMOVE_MEMBER_UPLOADED_FILE');
    //         this.props.getFileUploadMenuDetailsForUser(this.props.user.data.auth.token);
    //     }
    // }

    render() {
        return (
            <div className={"img-section"}>
                <div className="img-wrap">
                    <img width="300" height="250" src={this.props.file.source_url}/>
                    <a className={"img-remove-btn"} onClick={() => this.removeFileById(this.props.file.file_id)}><i
                        className="far fa-times-circle"></i></a>
                </div>



            </div>
        );
    }

    removeFileById = (fileId) => {
        let confirmation = window.confirm("Are you sure to remove the selected image ?");
        if(confirmation) {
            Logger.info("removing the file " + fileId);
            this.props.removeFileHandler(fileId);
        }
    };
}


// const actions = {
//     removeFile: removeMemberUploadedFileAction,
//     resetReduxStore: resetReduxStoreAction,
//     getFileUploadMenuDetailsForUser: getFileUploadMenuDetailsAction
// };
//
// const mapStateToProps = (state) => {
//     return {
//         user: state.user,
//         remove_member_file: state.remove_member_file
//     }
// };
//
//
// export default connect(mapStateToProps, actions)(MemberFileGridItem);
