import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";
import {updateMemberSubDomainAction} from "../../../actions/admin/update-member-subdomain-action";

let defaultState = {
    submit_update_sub_domain_request: false,
    show_sub_domain_update_form: true,
    sub_domain: '',
    message: ''
};

let showForm = false;
let domainAdded = null;

class SubDomainUpdatePopup extends Component {

    constructor() {
        super();
        this.state = defaultState;
        this.formik = React.createRef();
    }


    componentDidUpdate() {

        //clear the sub domain
        if (this.props.member_profile == null && this.state.sub_domain !== '') {
            this.setState({sub_domain: ''});
        }
        //updating the initial sub domain
        if (this.props.member_profile != null && this.props.member_profile.data.domain_details != null && this.state.sub_domain === '') {
            this.setState({sub_domain: this.props.member_profile.data.domain_details.sub_domain});
        }

        //success message is received
        if (this.props.member_subdomain_update !== null &&
            this.props.member_subdomain_update.success === true &&
            this.state.submit_update_sub_domain_request === true) {
            Logger.info("handling the success message");
            this.setState({
                submit_update_sub_domain_request: false,
                message_type: 'success',
                message: this.props.member_subdomain_update.data.message
            });
        }

        // //error message is received
        if (this.props.member_subdomain_update !== null &&
            this.props.member_subdomain_update.success === false &&
            this.state.submit_update_sub_domain_request === true) {
            Logger.info("handling the error message");
            this.setState({
                submit_update_sub_domain_request: false,
                message_type: 'error',
                message: this.props.member_subdomain_update.data.message
            });
        }
    }

    render() {
        return (
            <div>
                <div tabIndex="-1" role="dialog"
                     style={this.props.popup.data.id === 'update_member_subdomain_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                     className="modal fade show"
                     aria-modal="true">
                    <div className="overlay">
                        <div role="document" className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 id="exampleModalLabel" className="modal-title text-center">
                                        Update Member Domain
                                    </h5>

                                    <button type="button" data-dismiss="modal" aria-label="Close"
                                            onClick={this.onClosePopupHandler.bind(this)}
                                            className="close">
                                        <span aria-hidden="true">×</span></button>
                                </div>

                                <div className="modal-body">

                                    <div
                                        style={this.state.message !== '' ? {display: 'block'} : {display: 'none'}}
                                        className={this.state.message_type !== '' && this.state.message_type === 'success' ? "alert alert-success" : "alert alert-danger"}
                                        role="alert">
                                        {
                                            this.state.message
                                        }
                                    </div>

                                    {
                                        domainAdded = (this.props.member_profile != null
                                            && this.props.member_profile.data.domain_details !== undefined)
                                    }

                                    {
                                        showForm = (this.state.show_sub_domain_update_form === true && this.state.submit_update_sub_domain_request === false && domainAdded)
                                    }


                                    <div id={"sub-domain-change"}>
                                        <div className="form-inline"
                                             style={showForm ? {display: 'block'} : {display: 'none'}}>
                                            <Formik
                                                enableReinitialize
                                                ref={this.formik}
                                                initialValues={{
                                                    sub_domain: this.state.sub_domain,
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    sub_domain: Yup.string()
                                                        .required('Sub domain is required'),
                                                })}
                                                onSubmit={fields => {
                                                    Logger.info("handle submission");
                                                    this.onSubmitHandler(fields);
                                                }}
                                                render={({errors, status, touched}) => (
                                                    <div>
                                                        <Form>
                                                            <div className={"row"}>
                                                                <div className={"form-group col-md-6 pr-0"}>
                                                                    <Field name="sub_domain" type="text"
                                                                           style={{paddingRight: 'none !important',width:'100%'}}
                                                                           className={'form-control mb-2' + (errors.sub_domain && touched.sub_domain ? ' is-invalid' : '')}/>
                                                                    <ErrorMessage name="sub_domain" component="div"
                                                                                  className="invalid-feedback"/>
                                                                </div>
                                                                <div className={"form-group col-md-6"}>
                                                                    <p className="form-control-plaintext">
                                                                        .{this.props.member_profile != null && this.props.member_profile.data.domain_details != null && this.props.member_profile.data.domain_details.domain}
                                                                    </p>
                                                                </div>

                                                            </div>
                                                            <button className="btn btn-primary mb-2" style={{"float":"right"}}>
                                                                Change
                                                            </button>
                                                        </Form>
                                                    </div>
                                                )}
                                            />

                                        </div>


                                        <div className="modal-body jrf-popup"
                                             style={this.state.submit_update_sub_domain_request === true ? {display: 'block'} : {display: 'none'}}>
                                            <p className="text-center">
                                                Please wait... sub domain is being changed
                                            </p>
                                            <img src="../../../images/spinner.gif" className="loder"/>
                                        </div>
                                    </div>


                                    <div style={domainAdded === false ? {display: 'block'} : {display: 'none'}}
                                         className={"alert alert-danger"}>
                                        User has not set the domain details
                                    </div>

                                </div>

                                {/*<div className="modal-footer">*/}
                                {/*    <button className="btn btn-primary mb-2">*/}
                                {/*        Change*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClosePopupHandler() {
        Logger.info("popup is closed");
        // this.props.resetReduxStore("MEMBER_DNS_UPDATE_RESET");
        // this.props.resetReduxStore("MEMBER_PROFILE_RESET");
        this.formik.current.resetForm();
        this.setState(defaultState, () => {
            Logger.info("updated", this.state);
        });
        this.props.resetReduxStore("MEMBER_PROFILE_RESET");
        this.props.resetReduxStore("MEMBER_DNS_UPDATE_RESET");

        let payload = {
            id: "update_member_subdomain_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }


    onSubmitHandler(data) {
        Logger.info("sub domain updated");
        this.setState({submit_update_sub_domain_request: true, sub_domain: data.sub_domain});
        let token = this.props.user.data.auth.token;
        this.props.updateMemberSubDomain(this.props.member_profile.data.user_id, token, data);
    }
}


const mapStateToProps = (state) => {
    return {
        popup: state.popup,
        member_profile: state.member_profile,
        user: state.user,
        member_subdomain_update: state.member_subdomain_update
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    updateMemberSubDomain: updateMemberSubDomainAction,
    popUpHandler: popupDisplayHandlerAction,
    resetReduxStore: resetReduxStoreAction
};


export default withRouter(connect(mapStateToProps, actions)(SubDomainUpdatePopup));
