import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const uploadMemberFilesAction = (category, files, token) => async dispatch => {

    Logger.info("uploading ", files);
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let formData = new FormData();
    formData.append("category", category);
    formData.append("files", JSON.stringify(files));

    let response = await RestApiService.post("/dropbox/upload", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        dispatch({
            type: "UPLOAD_MEMBER_FILES_SUCCESS",
            payload: response.data
        });
    } else {
        dispatch({
            type: "UPLOAD_MEMBER_FILES_FAILED",
            payload: response.data
        });
    }

    //loader need to be hidden, if the displayLoader is set yo true
    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch({type: "HIDE_LOADER_SPINNER"});
};