import React, {Component} from 'react';
import AuthenticatedComponent from "../common/AuthenticatedComponent";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from "../top-menu-bar/TopMenuBar";
import BackToHome from "../user-dahsboard/BackToHome";
import AccountManagerHelp from "../top-menu-bar/AccountManagerHelp";
import {connect} from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExerciseVideoUpload from "./ExerciseVideoUpload";
import WantToUseDefaultWorkouts from "./steps/WantToUseDefaultWorkouts";
import NoDefaultWorkouts from "./steps/NoDefaultWorkouts";
import RecordOwnExerciseVideos from "./steps/RecordOwnExerciseVideos";
import InstructionsVideo from "./steps/InstructionsVideo";
import {userStoreExerciseVideosStepAction} from "../../actions/user/user-store-exercise-videos-step-action";
import {userGetExerciseVideosStepAction} from "../../actions/user/user-get-exercise-videos-step-action";
import YesRecordOwnExerciseVideos from "./steps/YesRecordOwnExerciseVideos";
import UseMacroActiveVideos from "./steps/UseMacroActiveVideos";
import DownloadExerciseList from "./steps/DownloadExerciseList";


class ExerciseVideoDashboard extends Component {

    constructor() {
        super();
        this.state = {
            show_account_manager_details: false,
            step: '1'
        };
    }

    componentDidMount() {
        if (this.props.user.authenticated && this.props.user.data.auth.token !== null) {
            console.log("getting exercise video step information");
            this.props.getMemberExerciseVideosStep(this.props.user.data.auth.token);
        }
    }

    componentDidUpdate() {
        if (this.props.user.authenticated && this.props.user.data.auth.token !== null && this.props.user_exercise_videos_step == null) {
            console.log("getting exercise video step information");
            this.props.getMemberExerciseVideosStep(this.props.user.data.auth.token);
        }
    }


    render() {
        return (
            <div onClick={() => this.onClickShowAccountManagerDetails(this, false)}>
                <AuthenticatedComponent/>
                <LoadingSpinner/>
                <ToastContainer />
                <TopMenuBar/>
                <div className={'container'}>
                    <div className="card content-box ">
                        <BackToHome/>

                        <AccountManagerHelp showAccountManager={this.onClickShowAccountManagerDetails.bind(this)}
                                            show={this.state.show_account_manager_details}/>
                        <hr className="m-0"/>
                        {this.renderDashboard()}
                    </div>
                </div>
            </div>
        );
    }

    renderDashboard() {
        if (this.props.user_exercise_videos_step == null
            || this.props.user_exercise_videos_step.data.data.use_macroactive_workouts == null
            || this.props.user_exercise_videos_step.data.data.custom_workouts == null
        ) {
            return <WantToUseDefaultWorkouts action={this.props.storeMemberExerciseVideosStep}/>;
        } else if ((this.props.user_exercise_videos_step.data.data.use_macroactive_workouts == true
            && this.props.user_exercise_videos_step.data.data.custom_workouts == false)
            && this.props.user_exercise_videos_step.data.data.record_own_videos  == true
            && this.props.user_exercise_videos_step.data.data.record_own_video_step  == null
        ) {
            return <InstructionsVideo action={this.props.storeMemberExerciseVideosStep} />;
        } else if ((this.props.user_exercise_videos_step.data.data.use_macroactive_workouts == true
            && this.props.user_exercise_videos_step.data.data.custom_workouts == false)
            && this.props.user_exercise_videos_step.data.data.record_own_videos  == true
            && this.props.user_exercise_videos_step.data.data.record_own_video_step  == 1
        ) {
            return <DownloadExerciseList action={this.props.storeMemberExerciseVideosStep} />;
        } else if ((this.props.user_exercise_videos_step.data.data.use_macroactive_workouts == true
            && this.props.user_exercise_videos_step.data.data.custom_workouts == false)
            && this.props.user_exercise_videos_step.data.data.record_own_videos  == true
            && this.props.user_exercise_videos_step.data.data.record_own_video_step  == 2
        ) {
            return <ExerciseVideoUpload />;
        } else if ((this.props.user_exercise_videos_step.data.data.use_macroactive_workouts == true
            && this.props.user_exercise_videos_step.data.data.custom_workouts == false)
            && this.props.user_exercise_videos_step.data.data.record_own_videos  == false) {
            return <UseMacroActiveVideos />;
        } else if (this.props.user_exercise_videos_step.data.data.use_macroactive_workouts == false
            && this.props.user_exercise_videos_step.data.data.custom_workouts == true
        ) {
            return <ExerciseVideoUpload />;
        } else if (this.props.user_exercise_videos_step.data.data.use_macroactive_workouts == true
            && this.props.user_exercise_videos_step.data.data.custom_workouts == false
        ) {
            return <RecordOwnExerciseVideos action={this.props.storeMemberExerciseVideosStep}/>;
        } else {
            return <WantToUseDefaultWorkouts action={this.props.storeMemberExerciseVideosStep}/>;
        }
    }


    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }
}


const actions = {
    storeMemberExerciseVideosStep: userStoreExerciseVideosStepAction,
    getMemberExerciseVideosStep: userGetExerciseVideosStepAction
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        user_exercise_videos_step: state.user_exercise_videos_step
    }
};


export default connect(mapStateToProps, actions)(ExerciseVideoDashboard);
