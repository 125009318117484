import React, {Component} from 'react';
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';

class WantToUseDefaultWorkouts extends Component {

    stepName = 'wantToUseDefaultWorkouts';

    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div className="card card border-0 border-top">
                <div className="card-body my-5">
                    <div className="text-center">
                        <h4 className="py-2">
                            Let's build your workout program
                        </h4>
                        <h4 className="py-2">
                            Do you want to use the MacroActive default workouts?
                        </h4>
                    </div>
                    <div className="d-flex flex-row justify-content-center text-center p-3 text-xl-center lead">

                        <button
                            onClick={() => this.yes()}
                            className="btn btn-info col-3 mx-2 text-white">
                            Yes, use MacroActive designed workouts & exercises
                        </button>
                        <button
                            onClick={() => this.no()}
                            className="btn btn-warning-alt col-3 mx-2 text-white">
                            No, I want to create my own workouts
                        </button>

                    </div>
                </div>
            </div>
        );
    }

    yes() {
        this.props.action(
            this.props.user.data.auth.token,
            this.stepName,
            {use_macroactive_workouts: true, custom_workouts: false}
            )
    }

    no() {
        this.props.action(
            this.props.user.data.auth.token,
            this.stepName,
            {use_macroactive_workouts: false, custom_workouts: true}
            )
    }
}


const actions = {
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};


export default connect(mapStateToProps, actions)(WantToUseDefaultWorkouts);
