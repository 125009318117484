import React, {Component} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Logger from "../../../../core/util/Logger";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";


export default class ExistingDomainVerificationEmailView extends Component {

    render() {
        return (
            <div className="form-inline"
                 style={this.props.show ? {display: 'block'} : {display: 'none'}}>
                <Formik
                    ref={this.props.formRef}
                    initialValues={{
                        mailbox_id: '',
                    }}
                    validationSchema={Yup.object().shape({
                        mailbox_id: Yup.string()
                            .required('Email id is required'),
                    })}
                    onSubmit={fields => {
                        Logger.info("handle submission");
                        this.props.sendExistingDomainVerification(fields);
                    }}
                    render={({errors, status, touched}) => (
                        <div>
                            <Form>
                                {/*<div className={"row"}>*/}
                                {/*    <div className={"form-group col-md-6"}>*/}
                                {/*        <Field name="mailbox_id" type="text"*/}
                                {/*               style={{paddingRight: 'none !important'}}*/}
                                {/*               className={'form-control mb-2' + (errors.mailbox_id && touched.mailbox_id ? ' is-invalid' : '')}/>*/}
                                {/*        <ErrorMessage name="mailbox_id" component="div"*/}
                                {/*                      className="invalid-feedback"/>*/}
                                {/*    </div>*/}
                                {/*    <div className={"form-group col-md-6"}>*/}
                                {/*        <p className="form-control-plaintext">*/}
                                {/*            @{this.props.member_profile != null && this.props.member_profile.data.domain_details != null && this.props.member_profile.data.domain_details.domain}*/}
                                {/*        </p>*/}
                                {/*    </div>*/}

                                {/*</div>*/}

                                <Container>

                                    <Row className={"mb-2"}>
                                        <Col md={6}>
                                            <div className="form-group">
                                                <Field name="mailbox_id" type="text"
                                                       style={{paddingRight: 'none !important'}}
                                                       className={'form-control mb-2' + (errors.mailbox_id && touched.mailbox_id ? ' is-invalid' : '')}/>
                                                <ErrorMessage name="mailbox_id" component="div"
                                                              className="invalid-feedback"/>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <p className="form-control-plaintext">
                                                @{this.props.member_profile != null && this.props.member_profile.data.domain_details != null && this.props.member_profile.data.domain_details.domain}
                                            </p>
                                        </Col>
                                    </Row>

                                    {/*<Button variant="primary mb-2">*/}
                                    {/*    Send*/}
                                    {/*</Button>*/}

                                    {/*<button className="btn btn-primary mb-2">*/}
                                    {/*    Send*/}
                                    {/*</button>*/}

                                    <Button
                                        type={"submit"}
                                        variant="btn btn-primary mb-2">
                                        Send
                                    </Button>

                                </Container>


                                {/*<Button*/}
                                {/*    type={"submit"}*/}
                                {/*    variant="btn btn-primary mb-2">*/}
                                {/*    Send*/}
                                {/*</Button>*/}

                                {/*<button className="btn btn-primary mb-2">*/}
                                {/*    Send*/}
                                {/*</button>*/}
                            </Form>
                        </div>
                    )}
                />
            </div>
        );
    }

}
