import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const askHelpFromTechPersonAction = (data, token) => async dispatch => {

    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("note", data.note);

    let response = await RestApiService.post("/members/ask_help", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        dispatch({
            type: "ASK_HELP_FROM_TECH_PERSON_SUCCESS",
            payload: {
                message: "Instructions have been sent"
            }
        });
    }
    else {
        dispatch({
            type: "ASK_HELP_FROM_TECH_PERSON_FAILED",
            payload: {
                message: response.data.message
            }
        });
    }
};
