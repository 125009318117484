import React, {Component} from 'react';
import '../member-file-uploading.css';

export default class MemberFileCategory extends Component {

    render() {
        return (
            <div className={'left-nav-bar-item'}>
                <a onClick={() => this.props.selectFileCategory(this.props.menu.key)}
                   className={(this.props.menu.files_count !== 0 && (this.props.menu.files_count >= this.props.menu.min_files_limit) ? 'cat-completed' : 'cat-pending') + " w3-bar-item w3-button " + (this.props.selected ? 'file-menu-selected' : '') + " "}>
                    {this.props.menu.name}
                </a>
            </div>
        );
    }
}
