import Logger from "../core/util/Logger";

export const memberTotalExerciseVideoSizeReducer = (state = null, action) => {

    if (action.type === 'MEMBER_TOTAL_VIDEO_SIZE_SUCCESS') {
        Logger.info("handling the success of retrieving member total video size in reducer");
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'MEMBER_TOTAL_VIDEO_SIZE_FAILED') {
        Logger.info("handling the failure of retrieving member total video size in reducer");
        return {
            success: false,
            data: action.payload
        };
    }
    else if (action.type === 'MEMBER_TOTAL_VIDEO_SIZE_RESET') {
        Logger.info("member total video size was removed from redux store");
        return null;
    }
    return state;
};
