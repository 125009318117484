import React, {Component} from 'react';
import Logger from "../../../core/util/Logger";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getAllRegisteredMembersAction} from "../../../actions/admin/get-registered-members-action";
import Member from './Member';
import MemberDetailsPopup from './MemberDetailsPopup';
import MemberDnsPopup from './MemberDnsPopup';
import MemberPasswordResetPopup from "./MemberPasswordResetPopup";
import MemberVideoProcessingPopup from "./MemberVideoProcessingPopup";
import SubDomainUpdatePopup from "./SubDomainUpdatePopup";
import EmailForwardingPopup from "./ManageEmailForwardersPopup";
import MemberVideosPopup from "../../exercise-videos/MemberVideosPopup";

// import ArchiveMemberConfirmationPopup from "./ArchiveMemberConfirmationPopup";


class MembersList extends Component {

    constructor() {
        super();
        this.state = {message: ''}
    }

    componentDidMount() {
        // Logger.info("=====all members", this.props.all_members);
        //to handle page initial loading events (through router navigation)
        if (this.props.user.authenticated) {
            Logger.info("getting all the registered members", this.props);
            this.props.getAllMembers(this.props.user.data.auth.token, this.props.filter);
        }
    }

    async componentDidUpdate() {
        if (this.props.member_profile == null && this.state.message !== '') {
            Logger.info("clear the previous updated error messages");
            this.setState({message: ''});
        }
        if (this.props.member_profile != null && this.props.member_profile.success === false && this.state.message === '') {
            this.setState({message: this.props.member_profile.data.message});
        }

        //to handle page refresh events by user (manual refresh)
        if (this.props.user.authenticated && this.props.all_members == null) {
            Logger.info("getting all the registered members", this.props);
            this.props.getAllMembers(this.props.user.data.auth.token, this.props.filter);
        }
    }


    render() {
        return (
            <div className="container"
                 style={this.props.all_members !== null && this.props.all_members.success ? {display: 'block'} : {display: 'none'}}>
                <div style={this.state.message !== '' ? {display: 'block'} : {display: 'none'}}
                     className="alert alert-danger" role="alert">
                    {this.state.message}
                </div>
                <div className="row justify-content-center">

                    <div className="list-group">
                        <table className="table table-striped text-center">
                            <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Domain</th>
                                <th scope="col">Domain Type</th>
                                <th scope="col">Mandrill Verification</th>
                                <th scope="col">DNS Records</th>
                                <th scope="col">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.showMembersList()
                            }
                            </tbody>
                        </table>
                    </div>

                </div>
                <MemberDetailsPopup/>
                <MemberDnsPopup/>
                <MemberPasswordResetPopup/>
                <SubDomainUpdatePopup/>
                <EmailForwardingPopup/>
                <MemberVideoProcessingPopup />
                <MemberVideosPopup />

                {/*<ArchiveMemberConfirmationPopup/>*/}
            </div>
        );
    }


    showMembersList() {
        if (this.props.all_members !== null && this.props.all_members.success) {
            return this.props.all_members.data.map((member, index) => {
                return (
                    <Member key={member.id} member={member}/>
                );
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        all_members: state.all_members,
        member_profile: state.member_profile
    };
};

const actions = {
    getAllMembers: getAllRegisteredMembersAction,
};


export default withRouter(connect(mapStateToProps, actions)(MembersList));
