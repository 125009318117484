import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const getMemberTotalVideoSizeByUserIdAction = (userId, token, popupDetails = null) => async dispatch => {
    Logger.info("getting user details by user id [" + userId + "] and token[" + token + "] ");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await RestApiService.get("/members/" + userId + "/exercise_videos/total_size", {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("total video size received successfully");
        dispatch({
            type: "MEMBER_TOTAL_VIDEO_SIZE_SUCCESS",
            payload: response.data
        });

        //display the related popup once the data is received
        if (popupDetails != null) {
            dispatch({
                type: "POPUP_HANDLER",
                payload: popupDetails
            });
        }
    }
    else {
        Logger.info("error in getting member total exercise video size [" + response.data + "]");
        dispatch({type: "MEMBER_TOTAL_VIDEO_SIZE_FAILED", payload: response.data});
    }
    dispatch({type: "HIDE_LOADER_SPINNER"});
};
