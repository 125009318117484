export const selfMemberInvitationReducer = (state = null, action) => {

    if (action.type === 'ADMIN_SELF_MEMBER_INVITATION_SUCCESS') {
        return {
            success: true,
            // message: "Self member invitation successfully completed",
            data: action.payload
        };
    } else if (action.type === 'ADMIN_SELF_MEMBER_INVITATION_FAILED') {
        return {
            success: false,
            data:action.payload
            // message: "Error occurred while making a member invitation. Please try again shortly"
        };
    }
    else if(action.type === 'ADMIN_SELF_MEMBER_INVITATION_RESET'){
        return null;
    }
    return state;
};
