import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../actions/popup-display-action";
import VideoList from "./VideoList";
import {resetReduxStoreAction} from "../../actions/reset-redux-store-action";
import {getMemberExerciseVideosAction} from "../../actions/admin/get-member-exercise-videos-action";
import {deleteMemberAllExerciseVideosAction} from "../../actions/admin/delete-member-all-exercise-videos-action";
import {getMemberTotalVideoSizeByUserIdAction} from "../../actions/admin/get-member-total-exercise-video-size-action";


class MemberVideosPopup extends Component {

    render() {
        return (
            <div tabIndex="-1" role="dialog"
                 style={this.props.popup.data.id === 'admin_view_member_videos_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                 className="modal fade show"
                 aria-modal="true">
                <div className="overlay">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 id="exampleModalLabel" className="modal-title text-center">
                                    {this.makeName()} Uploaded Videos
                                </h5>
                                <button type="button" data-dismiss="modal" aria-label="Close"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="close">
                                    <span aria-hidden="true">×</span></button>
                            </div>

                            <div className="modal-body">
                                <hr className="mt-0"/>
                                <div style={{fontSize: "18px"}}>
                                    <div className="form-group row">
                                        <label className="col-2 col-form-label">API Hash</label>
                                        <div className="col-5">
                                            <input disabled="disabled" className="form-control" type="text"
                                                   value={this.props.member_profile ? this.props.member_profile.data.hid : ''}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-2">
                                            <span className="col-form-label">No. of Files</span>
                                        </div>
                                        <div className="col-4">
                                            {this.props.member_exercise_videos && this.props.member_exercise_videos.success
                                                ? <strong>{this.props.member_exercise_videos.data.length}</strong>
                                                : <strong>0</strong>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-2">
                                            <span className="col-form-label">Size</span>
                                        </div>
                                        <div className="col-4">
                                            <strong>{this.props.member_total_video_size && this.props.member_total_video_size.success
                                                ?
                                                <strong>{this.props.member_total_video_size.data.size + ' ' + this.props.member_total_video_size.data.uom}</strong>
                                                : <strong>-</strong>
                                            }</strong>
                                        </div>
                                        <div className="col-6 text-right">
                                            <button type="button" className="btn btn-danger"
                                                    onClick={() => this.showConfirmDeleteAllVideos()}>Delete All
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {this.props.member_profile && this.props.member_profile.success ?
                                    <VideoList memberId={this.props.member_profile.data.user_id}/>
                                    :
                                    ''
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="btn btn-secondary">Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClosePopupHandler() {
        Logger.info("member videos popup is closed");
        let payload = {
            id: "admin_view_member_videos_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
        this.props.resetReduxStore("GET_MEMBER_EXERCISE_VIDEOS_RESET");
    }

    makeName() {
        let name = '';
        if (this.props.member_profile && this.props.member_profile.data) {
            name += this.props.member_profile.data.full_name || '';
            if (name !== '' && this.props.member_profile.data.company_name) {
                name += ' - ';
            }
            name += this.props.member_profile.data.company_name || '';
        }
        return name;
    }

    showConfirmDeleteAllVideos() {
        let response = prompt("Are you sure about deleting these videos? Type \"confirm\" to delete");
        if (response === 'confirm') {
            this.deleteAllVideos();
        } else if (response) {
            alert('Invalid confirmation text');
        }
    }

    deleteAllVideos() {
        let memberId = this.props.member_profile.data.user_id;
        let authToken = this.props.user.data.auth.token;
        this.props.deleteMemberAllExerciseVideos(
            memberId,
            authToken
        ).then(() => {
            this.props.getMemberTotalVideoSize(memberId, authToken);
            this.props.getMemberExerciseVideos(memberId, authToken);
        });
    }

}


const mapStateToProps = (state) => {
    return {
        member_profile: state.member_profile,
        user: state.user,
        popup: state.popup,
        member_exercise_videos: state.member_exercise_videos,
        member_total_video_size: state.member_total_video_size
    };
};

const actions = {
    getMemberExerciseVideos: getMemberExerciseVideosAction,
    deleteMemberAllExerciseVideos: deleteMemberAllExerciseVideosAction,
    getMemberTotalVideoSize: getMemberTotalVideoSizeByUserIdAction,
    handlePopupVisibility: popupDisplayHandlerAction,
    resetReduxStore: resetReduxStoreAction
};


export default withRouter(connect(mapStateToProps, actions)(MemberVideosPopup));
