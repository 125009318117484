export default class Logger {

    static info(message, object = undefined) {
        if (object !== undefined) {
            console.log(message, object);
        }
        else {
            console.log(message);
        }
    }
}