import Logger from "../core/util/Logger";

export const newMemberRegistrationReducer = (state = {success: false, user: undefined, message: {}}, action) => {

    if (action.type === 'REGISTER_MEMBER_SUCCESS') {
        Logger.info("handling register member success status in reducer");
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'REGISTER_MEMBER_FAILED') {
        Logger.info("handling the member registration failed status in reducer");
        return {
            success: false,
            message: {
                type: 'error',
                text: action.payload.message
            }
        };
    }
    return state;
};
