import Logger from "../core/util/Logger";

export const adminMemberPasswordResetReducer = (state = null, action) => {

    if (action.type === 'ADMIN_RESET_MEMBER_PASSWORD_SUCCESS') {
        Logger.info("handling register member success status in reducer");
        return {
            success: true,
            message: action.payload.message
        };
    } else if (action.type === 'ADMIN_RESET_MEMBER_PASSWORD_FAILED') {
        Logger.info("handling the member registration failed status in reducer");
        return {
            success: false,
            message: action.payload.message
        };
    }
    else if (action.type === 'RESET_ADMIN_PASSWORD_CHANGE') {
        Logger.info("resetting the previous password changing attempt");
        return null;
    }
    return state;
};
