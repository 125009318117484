import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
// import {getAllRegisteredMembersAction} from "../../../actions/admin/get-registered-members-action";
import {getMemberProfileDetailsByUserIdAction} from "../../../actions/admin/get-member-details-by-id-action";
import {getMemberTotalVideoSizeByUserIdAction} from "../../../actions/admin/get-member-total-exercise-video-size-action"
import Logger from "../../../core/util/Logger";
import {getMemberDnsConfigurationDetailsAction} from "../../../actions/admin/get-dns-configuration-details-by-admin-action";
import {userLogoutAction} from "../../../actions/logout-action";
import {getMemberExerciseVideosAction} from "../../../actions/admin/get-member-exercise-videos-action";

// import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";

class Member extends Component {

    render() {
        return (
            <tr>
                <th scope="row">{this.props.member.name}</th>
                <td>{this.props.member.email}</td>
                <td>{this.props.member.domain}</td>
                <td>{this.props.member.domain_type}</td>
                <td>
                    <span style={this.props.member.mandrill_verified ? {display: 'inline'} : {display: 'none'}}
                          className="badge badge-success">
                        Completed
                    </span>
                    <span style={!this.props.member.mandrill_verified ? {display: 'inline'} : {display: 'none'}}
                          className="badge badge-warning">
                        Pending
                    </span>
                </td>

                <td>
                    <span style={this.props.member.dns_verified ? {display: 'inline'} : {display: 'none'}}
                          className="badge badge-success">Completed</span>
                    <span style={!this.props.member.dns_verified ? {display: 'inline'} : {display: 'none'}}
                          className="badge badge-warning">
                        Pending
                    </span>
                </td>

                <td>
                    <div className="btn-group">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Actions
                        </button>
                        {this.getMemberActions()}
                    </div>
                </td>
            </tr>
        );
    }

    getMemberActions() {
        if (this.props.member.status == 1) {
            return (
                <div className="dropdown-menu">
                    <a className="dropdown-item"
                       onClick={() => this.onClickViewMemberSubmissionAction(this.props.member.id)}>
                        View Submission
                    </a>
                    <a className="dropdown-item"
                       onClick={() => this.onClickViewMemberDnsConfigurationAction(this.props.member.id)}>
                        Setup DNS
                    </a>
                    <a onClick={() => this.onClickArchiveMemberAction(this.props.member.id)}
                       className="dropdown-item">Archive</a>
                    <a onClick={() => this.onClickResetPasswordAction(this.props.member.id)}
                       className="dropdown-item">Reset Password</a>

                    <a onClick={() => this.onClickUpdateMemberSubDomainAction(this.props.member.id)}
                       className="dropdown-item">Change Sub Domain</a>

                    <a onClick={() => this.onClickEmailForwardersAction(this.props.member.id)}
                       className="dropdown-item">Email Forwarding</a>

                    <div className="dropdown-divider"></div>

                    <a onClick={() => this.onClickViewUploadedVideosAction(this.props.member)}
                       target="_blank"
                       className="dropdown-item">Uploaded Videos</a>

                    <div className="dropdown-divider"></div>

                    <a onClick={() => this.onClickAuthenticateSelfOnboarding(this.props.member.member_token)}
                       target="_blank"
                       className="dropdown-item">Onboarding</a>

                    <div className="dropdown-divider"></div>

                    <a onClick={() => this.onClickStartVideoProcessingAction(this.props.member)}
                       target="_blank"
                       className="dropdown-item">Start Video Processing</a>
                </div>
            );
        } else if (this.props.member.status == 0) {
            return (
                <div className="dropdown-menu">
                    <a className="dropdown-item"
                       onClick={() => this.onClickViewMemberSubmissionAction(this.props.member.id)}>
                        View Submission
                    </a>
                    <a onClick={() => this.onClickActivateMemberAction(this.props.member.id)}
                       className="dropdown-item">Restore</a>
                </div>
            );
        }
    }

    onClickViewMemberSubmissionAction = (memberId) => {
        Logger.info("click on member with id [" + memberId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "member_details_popup",
            shouldDisplay: true
        };
        this.props.getMemberDetailsById(token, memberId, popupDetails);
    };

    onClickViewMemberDnsConfigurationAction = (memberId) => {
        Logger.info("click on member with id [" + memberId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "member_dns_configuration_popup",
            shouldDisplay: true
        };
        this.props.getMemberDnsDetails(token, memberId, popupDetails);
    };

    onClickActivateMemberAction = (memberId) => {
        Logger.info("click on activate member with id [" + memberId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "activate_member_confirmation_popup",
            shouldDisplay: true,
        };
        this.props.getMemberDetailsById(token, memberId, popupDetails);
    };


    onClickArchiveMemberAction = (memberId, name) => {
        Logger.info("click on archive member with id [" + memberId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "archive_member_confirmation_popup",
            shouldDisplay: true,
        };
        // this.props.handlePopupVisibility(popupDetails);
        this.props.getMemberDetailsById(token, memberId, popupDetails);
    };


    onClickResetPasswordAction = (memberId) => {
        Logger.info("click change password for member with id [" + memberId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "member_password_reset_popup",
            shouldDisplay: true,
        };
        this.props.getMemberDetailsById(token, memberId, popupDetails);
    };


    onClickUpdateMemberSubDomainAction = (memberId) => {
        Logger.info("click on update sub domain for member with id [" + memberId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "update_member_subdomain_popup",
            shouldDisplay: true,
        };
        this.props.getMemberDetailsById(token, memberId, popupDetails);
    };

    onClickEmailForwardersAction = (memberId) => {
        Logger.info("handle email forwarding for domain purchased by user [" + memberId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "email_forwarders_popup",
            shouldDisplay: true,
        };
        this.props.getMemberDetailsById(token, memberId, popupDetails);
    };


    onClickAuthenticateSelfOnboarding(token) {
        localStorage.setItem("user-token", token);
        localStorage.setItem("is-admin-attempt", true);
        this.props.userSignOut();
        Logger.info("redirecting to admin to self onboarded user's dashboard with token [" + token + "] ");
        this.props.history.push("/user/home");
    }

    onClickStartVideoProcessingAction = (member) => {
        let memberId = member.id;
        if (member.video_processing_project_id) {
            this.props.history.push("/member/video-processing/" + member.video_processing_project_id);
        } else {

            Logger.info("click start video processing for member with id [" + memberId + "]");
            let token = this.props.user.data.auth.token;
            let popupDetails = {
                id: "member_video_processing_popup",
                shouldDisplay: true,
            };
            this.props.getMemberDetailsById(token, memberId, popupDetails);
        }
    };

    onClickViewUploadedVideosAction = (member) => {
        let memberId = member.id;
        let token = this.props.user.data.auth.token;
        Logger.info("fetch videos for member [" + member.id + "]");
        let popupDetails = {
            id: "admin_view_member_videos_popup",
            shouldDisplay: true,
        };
        this.props.getMemberDetailsById(token, memberId, popupDetails);
        this.props.getMemberExerciseVideos(memberId, token);
        this.props.getMemberTotalVideoSize(memberId, token);
    };
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        all_members: state.all_members
    };
};

const actions = {
    getMemberDetailsById: getMemberProfileDetailsByUserIdAction,
    getMemberTotalVideoSize: getMemberTotalVideoSizeByUserIdAction,
    getMemberDnsDetails: getMemberDnsConfigurationDetailsAction,
    getMemberExerciseVideos: getMemberExerciseVideosAction,
    userSignOut: userLogoutAction
};


export default withRouter(connect(mapStateToProps, actions)(Member));
