import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";
import {getUserDetailsByTokenAction} from "./get-userdetails-by-token-action";


export const updateUserProfileAction = (token, requestObject) => async dispatch => {
    Logger.info("trying to update user profile", requestObject);

    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    //converting request object  to form data
    const formData = new FormData();
    Object.keys(requestObject).forEach(key => {
        console.log("key [" + key + "]  value [" + requestObject[key] + "]");
        formData.append(key, requestObject[key]);
        console.log(formData);
    });

    let headers = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
    };
    let response = await RestApiService.post("/members/update", formData, headers);
    Logger.info("member update response received", response);

    if (response.status === 200) {
        await handleMemberProfileUpdateSuccess(requestObject, dispatch, token, response);
    }
    else {
        await  handleMemberProfileUpdateFailure(dispatch, response.data.message);
    }
};


const handleMemberProfileUpdateSuccess = async (requestData, dispatch, token, memberProfileUpdateResponse) => {
    Logger.info("member profile was updated successfully....", memberProfileUpdateResponse);
    await new Promise(resolve => setTimeout(resolve, 2000));
    dispatch({type: "HIDE_LOADER_SPINNER"});

    dispatch({
        type: "MEMBER_PROFILE_UPDATE_SUCCESS",
        payload: {
            type: 'success',
            dns_verification_required: memberProfileUpdateResponse.data.dns_verification_required,
            message: memberProfileUpdateResponse.data.message
        }
    });

    //apply the latest changes (name changes)
    dispatch(getUserDetailsByTokenAction(token));

};


const handleMemberProfileUpdateFailure = async (dispatch, error) => {
    Logger.info("updating member profile failed [" + error + "]");
    //wait 2 seconds
    await new Promise(resolve => setTimeout(resolve, 2000));
    dispatch({type: "HIDE_LOADER_SPINNER"});

    dispatch({
        type: "MEMBER_PROFILE_UPDATE_FAILED",
        payload: {
            type: 'error',
            message: error
        }
    });
};

