export const getUserDashboardSettingsReducer = (state = null, action) => {

    if (action.type === 'GET_USER_DASHBOARD_SETTINGS_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'GET_USER_DASHBOARD_SETTINGS_FAILED') {
        return {
            success:false,
            data: {
                message: "Error while loading the dashboard. Please try again later"
            }
        };
    }
    return state;
};
