export const userExerciseVideosStepReducer = (state = null, action) => {

    if (action.type === 'STORE_EXERCISE_VIDEO_STEP_SUCCESS' || action.type === 'GET_EXERCISE_VIDEO_STEP_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'STORE_EXERCISE_VIDEO_STEP_FAILED' || action.type === 'GET_EXERCISE_VIDEO_STEP_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    } else if (action.type === 'STORE_EXERCISE_VIDEO_STEP_RESET' || action.type === 'GET_EXERCISE_VIDEO_STEP_RESET') {
        return null;
    }
    return state;
};
