import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";
import Logger from "../../../core/util/Logger";
import {sendNewMemberInvitationAction} from "../../../actions/admin/send-new-member-invitation";
import "./inviteNewMember.css"
import Modal from "react-bootstrap/Modal";
import ProgressLoader from "../../common/progress-loader/ProgressLoader";
import MemberInvitationForm from "./MemberInvitationForm";
import MessageBoxAlert from "../../common/MessageBoxAlert";

const initialState = {
    start_sending_member_invitation: false,
    show_invitation_form: true,
    type: undefined,
    message: undefined
};


class InviteNewMember extends Component {

    constructor() {
        super();
        this.formik = React.createRef();
        this.state = initialState;
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.member_invitation != null && this.state.start_sending_member_invitation === true) {
            Logger.info("member invitation response received");
            let newState = {
                start_sending_member_invitation: false,
                show_invitation_form: !this.props.member_invitation.success,
                type: this.props.member_invitation.success ? 'success' : 'danger',
                message: this.props.member_invitation.data.message
            };
            this.setState(newState);
        }
    }

    render() {
        return (

            <div>
                <Modal
                    show={(this.props.popup.data.id === 'invite_new_member_popup' && this.props.popup.data.shouldDisplay)}
                    backdrop="static"
                    onHide={this.onClosePopupHandler.bind(this)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Invite new member
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <MessageBoxAlert show={this.state.message !== undefined}
                                         type={this.state.type}
                                         message={this.state.message}/>

                        <div
                            style={this.state.show_invitation_form === true ? {display: 'block'} : {display: 'none'}}
                            className="form-inline">

                            <MemberInvitationForm
                                formRef={this.formik}
                                handleInviteMember={this.handleInviteMember.bind(this)}/>

                        </div>

                        <ProgressLoader
                            text={"Please wait... invitation is being sent"}
                            display={this.state.start_sending_member_invitation === true}/>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }


    onClosePopupHandler() {
        Logger.info("popup is closed");
        this.props.resetReduxStore("RESET_NEW_MEMBER_INVITATION");
        this.setState(initialState);
        this.formik.current.resetForm();

        let payload = {
            id: "invite_new_member_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }


    handleInviteMember(data) {
        Logger.info("send new member invitation", data);
        this.props.resetReduxStore("RESET_NEW_MEMBER_INVITATION");
        this.setState({start_sending_member_invitation: true, show_invitation_form: false});
        let token = this.props.user.data.auth.token;
        this.props.sendMemberInvitation(token, data);
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        popup: state.popup,
        member_invitation: state.member_invitation
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    resetReduxStore: resetReduxStoreAction,
    sendMemberInvitation: sendNewMemberInvitationAction
};


export default withRouter(connect(mapStateToProps, actions)(InviteNewMember));
