import {VideoProcessingApiService} from "../../core/api/VideoProcessingApiService";
import Logger from "../../core/util/Logger";


export const getVideoProcessingProjectAction = (token, projectId) => async dispatch => {
    Logger.info("getting details of project", projectId);
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await VideoProcessingApiService.get("/projects/"+projectId,{Authorization: "Bearer " + token});
    Logger.info(response.data);

    if (response.status === 200) {
        Logger.info("data for project received successfully");
        dispatch({
            type: "GET_VIDEO_PROCESSING_PROJECT_SUCCESS",
            payload: response.data
        });
    } else {
        Logger.info("error in getting data for project");
        dispatch({type: "GET_VIDEO_PROCESSING_PROJECT_FAILED", payload: response.data.errors});
    }

    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch({type: "HIDE_LOADER_SPINNER"});
};