import React, {Component} from 'react';

export default class DashboardCompletedItem extends Component {

    render() {
        return (
            <a className="setup-selection-btn complete"
               onClick={() => this.props.onClickNavigation(this.props.data.path)}>
                <div className="setup-selection-text">
                    <span className="check"><i className="fas fa-check-circle"></i></span>
                    {
                        this.props.data.name
                    }
                </div>
            </a>
        );
    }
}
