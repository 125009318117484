import React from 'react';
import {Field} from 'redux-form';
import RadionButtonGroup from "../RadionButtonGroup";
import {FormField} from "../../../core/util/FormField";

const ProgramDetails = (props) => {
    return (
        <div className="card">
            <div id="headingThree" className="card-header">
                <h2 className="mb-0">
                    <button
                        onClick={() => props.onAccordionClick('program_details')}
                        type="button"
                        aria-expanded="false"
                        className="btn btn-link  collapsed   ">
                        Program Customization
                    </button>
                </h2>
            </div>
            <div id="collapseThree" aria-labelledby="headingThree"
                 data-parent="#accordionExample"
                 className={props.expandOnSubmit === true ? "accordion-expand" : 'accordion-collapse'}>
                <div className="card-body">

                    <div className="form-group mb-5 app-logo-wrap"><label>What do you want your
                        iPhone/Android App to be called?</label> <br/>
                        <div id="app-logo"><p className="logo-text">{props.appName}</p>
                            <img
                                src="../images/brand-logo_form.png" className="img-fluid"
                                style={{position: 'relative'}}/>

                            <Field name="app_name" component={FormField.renderInputField} type="text"
                                   className="form-control"
                                   onChange={props.persistFormData}
                                   label="App name"/>

                        </div>
                        <small id="" className="form-text text-muted">E.g: Arnold
                            Fitness
                        </small>
                    </div>


                    <Field label="Complete"
                           value="Complete"
                           onClick={props.onClickCompleteBtn}
                           component={FormField.renderSubmitButton}
                           className="float-right btn btn-success  mb-5"/>

                </div>
            </div>
        </div>
    );
};

export default ProgramDetails;
