export const reserveNewDomainReducer = (state = null, action) => {

    if (action.type === 'RESERVE_NEW_DOMAIN') {
        return {
            data: action.payload
        };
    }
    else if (action.type === 'RESET_RESERVED_DOMAIN') {
        return null;
    }
    return state;
};
