import axios from 'axios';
import Logger from "../util/Logger";
import {Response} from "./Response";

export class ReleasesApiService {

    //hardcoding since it's going to be the same endpoint regardless of the env
    static BASE_URL = 'https://releases.macroactive.com/index.php?get-releases';

    static async get(apiEndpoint = "", headersOb = {}, paramOb = {}) {
        Logger.info("sending data Request to [" + apiEndpoint + "] ", process.env);
        let api = axios.create({baseURL: this.BASE_URL});

        let config = {
            headers: headersOb,
            params: paramOb,
        };

        try {
            const response = await api.get(apiEndpoint, config);
            Logger.info("response was successfully received", response);
            return new Response(response.status, response.data);
        } catch (exception) {
            return await this.handleError(apiEndpoint, exception);
        }
    }

    static async handleError(apiEndpoint, exception) {
        Logger.info("error while making the request for the endpoint [" + apiEndpoint + "]. error :", exception.response);

        let status = 500;
        let data = {
            message: "Something went wrong! Please try again shortly"
        };
        if (exception.response !== null && exception.response !== undefined) {
            status = exception.response.status;
            data = exception.response.data;
        }
        return new Response(status, data);

    }
}