import React, {Component} from 'react';
import './ticker.css'

export default  class Ticker extends Component {
    intervalID = 0;

    constructor() {
        super();
        this.state = {show: false, transitionClass: 'message-b in', messageToShow: 0, message: null};
        this.animate = this.animate.bind(this);
    }
    
    animate() {
        if (this.props.messages && this.props.messages.data && this.props.messages.data.length) {
            this.setState(state => ({
                show: !state.show,
                transitionClass: state.show ? 'message-b in' : 'message-b out',
            }));
            if (!this.state.show) {
                this.setState(state => ({
                    messageToShow: state.messageToShow >= this.props.messages.data.length - 1 ? 0 : ++state.messageToShow,
                }));
                this.setState(state => ({
                    message: this.getMessage()
                }));
            }
        }
    }

    getMessage() {
        let messageHtml = '';
        let messageClasses ='';
        let messagePrefix = '';
        let message = this.props.messages.data[this.state.messageToShow];
        if (typeof message === 'object' && message.url !== null) {
            if (message.slugs !== null) {
                messageClasses = Array.isArray(message.slugs) ? message.slugs.join() : message.slugs;
            }
            if (message.categories !== null) {
                messagePrefix =  Array.isArray(message.categories) ? message.categories.join(', ') : message.categories;
            }
            messageHtml = <a href={message.url} target='_blank'>{message.title}</a>;
        } else {
            messageHtml = <span>{message}</span>;
        }
        this.setState(
            {
                messageClasses: messageClasses,
                messagePrefix: messagePrefix
            }
        );
        return messageHtml;
    }
    componentDidMount() {
        this.intervalID = setInterval(this.animate, 1500);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    render() {
        return (
            <div className={"alert alert-info text-center fade-in "+ this.state.messageClasses} role="alert">
                {this.state.message ?
                    <div className={this.state.transitionClass}>
                        <strong>{this.state.messagePrefix} </strong>
                        {this.state.message}
                    </div> : <p style={{paddingTop: "7px"}}>

                    </p>}
            </div>
        );
    }
}
