import React, {Component} from 'react';
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {getUserDashBoardSettingsAction} from "../../../actions/user/user-user-dashboard-settings-action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Logger from "../../../core/util/Logger";

export class DashboardRestrictedItem extends Component {

    render() {
        return (
            <a className="setup-selection-btn "
               onClick={this.displayPopup.bind(this)}>
                <div className="setup-selection-text">
                    <span className="pending"><i className="far fa-hourglass"></i></span>
                    {
                        this.props.data.name
                    }
                </div>
            </a>
        );
    }


    displayPopup() {
        Logger.info("display notification popup");
        let payload = {
            id: "dashboard_notification_popup",
            shouldDisplay: true,
            data: this.props.data
        };
        this.props.handlePopupVisibility(payload);
    }
}


const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    getUserDashBoardSettings: getUserDashBoardSettingsAction
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        user_dashboard: state.user_dashboard
    }
};

export default withRouter(connect(mapStateToProps, actions)(DashboardRestrictedItem));

// let payload = {
//     id: "admin_mandrill_verification_popup",
//     shouldDisplay: false
// };
// this.props.handlePopupVisibility(payload);
