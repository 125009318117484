export class Response {
    status;
    data;

    constructor(status, data) {
        this.status = status;
        this.data = data;
    }

    getStatus() {
        return this.status;
    }

    setStatus(value) {
        this.status = value;
    }

    getData() {
        return this.data;
    }

    setData(value) {
        this.data = value;
    }
}