import React, {Component} from 'react';
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';

class RecordOwnExerciseVideos extends Component {

    stepName = 'recordOwnExerciseVideos';

    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div className="card card border-0 border-top">
                <div className="card-body my-5">
                    <div className="text-center">
                        <h4 className="py-2">
                            Do you want to record your own exercise videos
                            for the default MacroActive workouts?
                        </h4>
                    </div>
                    <div className="d-flex flex-md-row flex-sm-column justify-content-center text-center p-3 ">

                        <button
                            onClick={() => this.no()}
                            className="btn btn-info col-md-3 col-sm-12 mx-2 text-white">
                            No use MacroActive's videos
                        </button>
                        <button
                            onClick={() => this.yes()}
                            className="btn btn-warning-alt col-md-3 col-sm-12 mx-2 text-white">
                            Yes, I want to record my own exercise videos
                        </button>

                    </div>
                </div>
            </div>
        );
    }



    yes() {
        this.props.action(
            this.props.user.data.auth.token,
            this.stepName,
            {record_own_videos: true}
        )
    }

    no() {
        this.props.action(
            this.props.user.data.auth.token,
            this.stepName,
            {record_own_videos : false}
        )
    }
}


const actions = {
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};


export default connect(mapStateToProps, actions)(RecordOwnExerciseVideos);
