import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import ShareDnsSetupInstructionsPopup from "./ShareDnsSetupInstructionsPopup";

class MemberDnsPopup extends Component {


    render() {
        return (
            <div>
                <div tabIndex="-1" role="dialog"
                     style={this.props.popup.data.id === 'member_dns_configuration_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                     className="modal fade show"
                     aria-modal="true">
                    <div className="overlay">
                        <div role="document" className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 id="exampleModalLabel" className="modal-title text-center">
                                        Dns Configurations
                                    </h5>

                                    <button type="button" data-dismiss="modal" aria-label="Close"
                                            onClick={this.onClosePopupHandler.bind(this)}
                                            className="close">
                                        <span aria-hidden="true">×</span></button>
                                </div>

                                <div className="modal-body">

                                    <div className="alert alert-danger" role="alert"
                                         style={(this.props.dns.received === false && this.props.dns.message !== undefined) ? {display: 'block'} : {display: 'none'}}>
                                        {
                                            this.props.dns.message
                                        }
                                    </div>


                                    <div
                                        style={this.props.dns.received === true ? {display: 'block'} : {display: 'none'}}
                                        className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                            <tr>
                                                <th>CNAME</th>
                                                <th>{this.props.dns.data.urls.member_url}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    {/*{this.props.dns.data !== undefined ? this.props.dns.data.CNAME.value : 'N/A'}*/}
                                                    <div className="input-group">
                                                        <div id="spf" className="input-group-prepend">
                                                            <div
                                                                className="input-group-text">
                                                                <i style={!(this.props.dns.data.settings.find(setting => setting.type === 'CNAME')).completed ? {display: 'block'} : {display: 'none'}}
                                                                   className={'fas fa-times-circle text-danger'}></i>
                                                                <i style={(this.props.dns.data.settings.find(setting => setting.type === 'CNAME')).completed ? {display: 'block'} : {display: 'none'}}
                                                                   className={'fas fa-check-circle text-success'}></i>
                                                            </div>
                                                        </div>
                                                        <input type="text"
                                                               value={(this.props.dns.data.settings.find(setting => setting.type === 'CNAME')).value}
                                                               readOnly className="form-control"/>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>CNAME</th>
                                                <th>{this.props.dns.data.urls.landing_page_url}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    {/*{this.props.dns.data !== undefined ? this.props.dns.data.CNAME.value : 'N/A'}*/}
                                                    <div className="input-group">
                                                        <div id="spf" className="input-group-prepend">
                                                            <div
                                                                className="input-group-text">
                                                                <i style={!(this.props.dns.data.settings.find(setting => setting.type === 'CNAME_LANDING')).completed ? {display: 'block'} : {display: 'none'}}
                                                                   className={'fas fa-times-circle text-danger'}></i>
                                                                <i style={(this.props.dns.data.settings.find(setting => setting.type === 'CNAME_LANDING')).completed ? {display: 'block'} : {display: 'none'}}
                                                                   className={'fas fa-check-circle text-success'}></i>
                                                            </div>
                                                        </div>
                                                        <input type="text"
                                                               value={(this.props.dns.data.settings.find(setting => setting.type === 'CNAME_LANDING')).value}
                                                               readOnly className="form-control"/>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>TXT</th>
                                                <th>{this.props.dns.data.urls.domain}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <div className="input-group">
                                                        <div id="spf" className="input-group-prepend">
                                                            <div
                                                                className="input-group-text">
                                                                <i style={!(this.props.dns.data.settings.find(setting => setting.type === 'TXT')).completed ? {display: 'block'} : {display: 'none'}}
                                                                   className={'fas fa-times-circle text-danger'}></i>
                                                                <i style={(this.props.dns.data.settings.find(setting => setting.type === 'TXT')).completed ? {display: 'block'} : {display: 'none'}}
                                                                   className={'fas fa-check-circle text-success'}></i>
                                                            </div>
                                                        </div>
                                                        <textarea type="text" rows="2"
                                                                  value={(this.props.dns.data.settings.find(setting => setting.type === 'TXT')).value}
                                                                  placeholder=""
                                                                  readOnly
                                                                  className="form-control"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>TXT</th>
                                                <th>{this.props.dns.data.urls.mandrill_url}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <div className="input-group">
                                                        <div id="spf" className="input-group-prepend">
                                                            <div
                                                                className="input-group-text">
                                                                <i style={!(this.props.dns.data.settings.find(setting => setting.type === 'MANDRILL')).completed ? {display: 'block'} : {display: 'none'}}
                                                                   className={'fas fa-times-circle text-danger'}></i>
                                                                <i style={(this.props.dns.data.settings.find(setting => setting.type === 'MANDRILL')).completed ? {display: 'block'} : {display: 'none'}}
                                                                   className={'fas fa-check-circle text-success'}></i>
                                                            </div>
                                                        </div>
                                                        <textarea type="text" rows="8"
                                                                  value={(this.props.dns.data.settings.find(setting => setting.type === 'MANDRILL')).value}
                                                                  placeholder=""
                                                                  readOnly
                                                                  className="form-control"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                <div className="modal-footer">

                                    <a onClick={this.onClickGetHelpFromTechPersonBtn.bind(this)}
                                       style={this.props.dns.received === true ? {display: 'block'} : {display: 'none'}}
                                       className={'btn btn-primary'}>
                                        Share
                                    </a>

                                    <button type="button" data-dismiss="modal"
                                            onClick={this.onClosePopupHandler.bind(this)}
                                            className="btn btn-secondary">Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShareDnsSetupInstructionsPopup user_id={this.props.dns.data.user_id}/>
            </div>
        );
    }

    onClosePopupHandler() {
        Logger.info("member dns configuration popup is closed");
        let payload = {
            id: "member_dns_configuration_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }


    onClickGetHelpFromTechPersonBtn() {
        Logger.info("displaying the hosting details submission popup");
        let popupDetails = {
            id: "share_dns_setup_instructions",
            shouldDisplay: true
        };
        this.props.popUpHandler(popupDetails);
    }
}


const mapStateToProps = (state) => {
    return {
        // member_profile: state.member_profile,
        popup: state.popup,
        dns: state.dns
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    popUpHandler: popupDisplayHandlerAction
};


export default withRouter(connect(mapStateToProps, actions)(MemberDnsPopup));
