import React, {Component} from 'react';
import {connect} from "react-redux";
import AuthenticatedComponent from '../common/AuthenticatedComponent';
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from '../top-menu-bar/TopMenuBar';
import Group from './Group';
import Action from './Action';
import Queue from './Queue';
import Logger from "../../core/util/Logger";
import {getVideoProcessingProjectAction} from "../../actions/admin/get-video-processing-project-action";
import {getVideoProcessingPrepareMatchReportAction} from "../../actions/admin/get-video-processing-prepare-match-report-action";
import {getVideoProcessingProcessMatchReportAction} from "../../actions/admin/get-video-processing-process-match-report-action";

class VideoProcessing extends Component {
    reportPolling = 0;

    constructor() {
        super();
        this.state = {updateReportUpdatedAt: null, processReportUpdatedAt: null, sheetId: null}
    }


    componentWillUnmount() {
        clearInterval(this.reportPolling);
    }

    componentDidUpdate(nextProps, nextState, nextContext) {
        //to handle page refresh
        if (this.props.user.authenticated && this.reportPolling === 0) {
            Logger.info("getting video processing project", this.props);
            this.props.getVideoProcessingProject(this.props.user.data.auth.token, this.props.match.params.id);
            this.getProcessMatchReport();
            this.getPrepareMatchReport();
            this.reportPolling = setInterval(() => {
                this.getProcessMatchReport();
                this.getPrepareMatchReport();
            }, 10000);
        }
    }


    componentDidMount() {
        // get data on page navigation within router
        if (this.props.user.authenticated) {
            Logger.info("getting video processing project", this.props);
            this.props.getVideoProcessingProject(this.props.user.data.auth.token, this.props.match.params.id);
            this.getProcessMatchReport();
            this.getPrepareMatchReport();
            this.reportPolling = setInterval(() => {
                this.getProcessMatchReport();
                this.getPrepareMatchReport();
            }, 10000);
        }
    }

    render() {
        if (this.props.video_processing_project && this.props.video_processing_project.data && this.props.video_processing_project.data.name) {
            return (
                <div id="app">
                    <AuthenticatedComponent/>
                    <LoadingSpinner/>
                    <TopMenuBar/>
                    <div className={'container'}>
                        <div className="card content-box border-0">
                            <div className="card-body px-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="float-left">
                                            <h2 className="card-title">Video Processing</h2>
                                            <h5 className="card-text">{this.props.video_processing_project.data.name}</h5>
                                        </div>
                                        <div className="float-right">
                                            <button onClick={() => {this.props.history.goBack()}} className="btn btn-outline-success">Go Back</button>
                                        </div>
                                    </div>
                                </div>
                                <hr/>

                                {/*Processing Actions*/}
                                <Group label="Process">
                                    <Action label="Generate Match Report" action="PrepareMatchReport"
                                            confirmMessage="Are you sure you want to generate the match report? Any previously generated reports will be lost."
                                            description="Re-generate the match report. Any changes you made to the previous report will be lost"/>
                                    <Action label="Update Report" action="UpdateMatchReport"
                                            generatedAt={this.state.updateReportUpdatedAt}
                                            handleClick={this.handleUpdateMatchReportClick.bind(this)}
                                            btnColor="secondary"
                                            disabled={!this.state.updateReportUpdatedAt}
                                            description="View/edit the exercises in bulk before loading to trainer"/>
                                    <Action label="Process the Report" action="ProcessMatchReport"
                                            generatedAt={this.state.processReportUpdatedAt}
                                            btnColor="warning"
                                            disabled={!this.state.updateReportUpdatedAt}
                                            confirmMessage="Are you sure you want to process the match report? All the exercises will be unpublished before processing."
                                            description="Update exercises in trainer console using the match report"/>
                                </Group>

                                {/*Other Actions*/}
                                <Group label="Other">
                                    <Action label="CountReport"
                                            action="CountReport"
                                            description="See number of exercises broken down by muscle group, gender, location and priority"
                                    />
                                    <Action label="Sync Videos to Vimeo"
                                            action="UploadVideosToVimeo"
                                            description="Copy trainer uploaded video files to our Vimeo account"
                                    />
                                    <Action label="Exercise Import CSV"
                                            handleClick={this.downloadExerciseImportCSV.bind(this)}
                                            description="Get bulk exercise import csv from match report"
                                    />
                                </Group>
                                <hr/>
                                <Queue projectId={this.props.video_processing_project.data.id}/>
                            </div>
                        </div>
                    </div>

                </div>
            );
        } else if (this.props.video_processing_project && this.props.video_processing_project.success !== true) {
            return (
                <div id="app">
                    <AuthenticatedComponent/>
                    <LoadingSpinner/>
                    <TopMenuBar/>
                    <div className={'container'}>
                        <div className="card content-box border-0">
                            <div className="card-body px-5">
                                <div className="row">
                                    <div className="col-12 py-2">
                                        <div className="float-right">
                                            <button onClick={() => {this.props.history.goBack()}} className="btn btn-outline-success">Go Back</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={"alert alert-danger"}
                                     role="alert">
                                    {this.props.video_processing_project.data}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div id="app">
                    <AuthenticatedComponent/>
                    <LoadingSpinner/>
                    <TopMenuBar/>
                    <div className={'container'}>
                        <div className="card content-box border-0">
                            <div className="card-body px-5">
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    downloadExerciseImportCSV () {
        window.location.href = `${process.env.REACT_APP_VIDEO_PROCESSING_API_URL}exercise-import-csv-generate?sheet_id=${this.state.sheetId}`;
    }

    getProcessMatchReport() {
        this.props.getVideoProcessingProcessMatchReport(this.props.user.data.auth.token, this.props.match.params.id)
            .then(() => {
                if (this.props.video_processing_process_match_report
                    && this.props.video_processing_process_match_report.success
                    && this.props.video_processing_process_match_report.data
                    && this.props.video_processing_process_match_report.data.data
                    && this.props.video_processing_process_match_report.data.data.length) {
                    let updatedAt = null;
                    if (this.props.video_processing_process_match_report.data.data[0].status == 'completed') {
                        updatedAt = new Date(this.props.video_processing_process_match_report.data.data[0].updated_at);
                        updatedAt = 'Last Processed: '+updatedAt.toLocaleDateString();
                    }
                    this.setState(
                        {
                            processReportUpdatedAt: updatedAt
                        }
                    )
                }
            })
    }

    getPrepareMatchReport() {
        this.props.getVideoProcessingPrepareMatchReport(this.props.user.data.auth.token, this.props.match.params.id)
            .then(() => {
                if (this.props.video_processing_prepare_match_report
                    && this.props.video_processing_prepare_match_report.success
                    && this.props.video_processing_prepare_match_report.data
                    && this.props.video_processing_prepare_match_report.data.data
                    && this.props.video_processing_prepare_match_report.data.data.length) {
                    let updatedAt = null;
                    if (this.props.video_processing_prepare_match_report.data.data[0].status == 'completed') {
                        updatedAt = new Date(this.props.video_processing_prepare_match_report.data.data[0].updated_at);
                        updatedAt = 'Last Generated: '+updatedAt.toLocaleDateString();
                    }
                    let sheetId = this.props.video_processing_prepare_match_report.data.data[0].output.created_sheet;
                    this.setState(
                        {
                            sheetId: sheetId,
                            updateReportUpdatedAt: updatedAt
                        }
                    )
                }
            })
    }

    handleUpdateMatchReportClick() {
        if (this.state.sheetId) {
            let url = 'https://docs.google.com/spreadsheets/d/' + this.state.sheetId + '/edit';
            window.open(url, '_blank');
        }
        void(0)
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        video_processing_project: state.video_processing_project,
        video_processing_prepare_match_report: state.video_processing_prepare_match_report,
        video_processing_process_match_report: state.video_processing_process_match_report
    };
};

const actions = {
    getVideoProcessingProject: getVideoProcessingProjectAction,
    getVideoProcessingPrepareMatchReport: getVideoProcessingPrepareMatchReportAction,
    getVideoProcessingProcessMatchReport: getVideoProcessingProcessMatchReportAction
};


export default connect(mapStateToProps, actions)(VideoProcessing);
