import Logger from "../core/util/Logger";

export const updateMemberExerciseVideoReducer = (state = null, action) => {

    if (action.type === 'UPDATE_MEMBER_EXERCISE_VIDEOS_SUCCESS') {
        Logger.info("handling the success of update for member exercise videos in reducer");
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'UPDATE_MEMBER_EXERCISE_VIDEOS_FAILED') {
        Logger.info("handling the failure of update for member exercise videos in reducer");
        return {
            success: false,
            data: action.payload
        };
    }
    return state;
};