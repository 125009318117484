import React, {Component} from 'react';
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import MessageBoxAlert from "../../common/MessageBoxAlert";

class UseMacroActiveVideos extends Component {


    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div className="card card border-0 border-top">
                <div className="card-body my-5">
                    <div className="text-center">
                        <li className="text-success fa-8x fa fa-check-circle"></li>
                        <h4 className="py-2">
                            You are all set.
                        </h4>
                        <p>
                            We will prepare your application with <br />
                            our workout programs and exercise videos.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}


const actions = {
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};


export default connect(mapStateToProps, actions)(UseMacroActiveVideos);
