import React, {Component} from 'react';

export default  class ProgressLoader extends Component {

    render() {
        return (
            <div className="modal-body jrf-popup"
                 style={this.props.display ? {display: 'block'} : {display: 'none'}}>
                <p className="text-center">
                    {this.props.text}
                </p>
                <img src="../../../images/spinner.gif" className="loder"/>
            </div>
        );
    }
}
