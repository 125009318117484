import React, {Component} from 'react';

import {connect} from "react-redux";
import GuestUserMenuBar from './GuestUserMenuBar'
import LoggedUserMenuBar from './LoggedUserMenuBar'

class TopMenuBar extends Component {

    render() {
        return (
            <nav className="navbar navbar-expand-md navbar-light navbar-laravel">

                <GuestUserMenuBar/>
                {this.props.user.authenticated ? <LoggedUserMenuBar/> : ''}

            </nav>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};

export default connect(mapStateToProps, {})(TopMenuBar);