export const validateMemberRegistrationLinkHashReducer = (state = null, action) => {
    if (action.type === 'member_invitation_link_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'member_invitation_link_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    }
    return state;
};

