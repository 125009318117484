import Logger from "../core/util/Logger";

export const getMemberDomainDetailsReducer = (state = null, action) => {

    if (action.type === 'MEMBER_DOMAIN_DETAILS_RECEIVED_SUCCESS') {
        Logger.info("handling the success of retrieving member domain details in reducer");
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'MEMBER_DOMAIN_DETAILS_RECEIVED_FAILED') {
        Logger.info("handling the failure of retrieving member domain details  in reducer");
        return {
            success: false,
            data: action.payload
        };
    }
    return state;
};
