import Logger from "../core/util/Logger";

export const userArchiveReducer = (state = null, action) => {

    if (action.type === 'MEMBER_ARCHIVE_SUCCESS') {
        Logger.info("handling member archive success in reducer");
        return {
            success: true,
            message: {
                type: 'success',
                text: action.payload.message
            }
        };
    } else if (action.type === 'MEMBER_ARCHIVE_FAILED') {
        Logger.info("handling the member archive failed  in reducer");
        return {
            success: false,
            message: {
                type: 'danger',
                text: action.payload.message
            }
        };
    } else if (action.type === 'MEMBER_ARCHIVE_RESET') {
        return null;
    }
    return state;
};
