import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const removeMemberUploadedFileAction = (fileId, token) => async dispatch => {

    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await RestApiService.delete("/remove-member-files/" + fileId, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        dispatch({
            type: "REMOVE_MEMBER_UPLOADED_FILE_SUCCESS",
            payload: {
                message: "File was successfully removed!"
            }
        });
    } else {
        dispatch({
            type: "REMOVE_MEMBER_UPLOADED_FILE_FAILED",
            payload: {
                message: "Error occurred while removing the file! Please try again shortly"
            }
        });
    }

    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch({type: "HIDE_LOADER_SPINNER"});
};
