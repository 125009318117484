import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const updateMemberSubDomainAction = (userId,token, data) => async dispatch => {

    Logger.info("updating the member sub domain [" + data.sub_domain + "] with  token [" + token + "]");

    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("sub_domain", data.sub_domain);

    let response = await RestApiService.post("admin/members/sub_domain_update", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    //wait for 2 seconds
    await new Promise(resolve => setTimeout(resolve, 2000));

    if (response.status === 200) {
        Logger.info("member dns updated");
        dispatch({
            type: "MEMBER_DNS_UPDATE_SUCCESS",
            payload: {
                message: "Member domain was successfully updated!"
            }
        });
    } else {
        dispatch({
            type: "MEMBER_DNS_UPDATE_FAILED",
            payload: {
                message: "Error occurred while updating the member domain. Please try again shortly"
            }
        });
    }
};