
export const exerciseVideoUploadReducer = (state = null, action) => {

    if (action.type === 'EXERCISE_VIDEO_URL_RECEIVED_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'EXERCISE_VIDEO_URL_RECEIVED_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    }
    return state;
};
