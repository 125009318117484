import React, {Component} from 'react';
import AuthenticatedComponent from "../common/AuthenticatedComponent";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from "../top-menu-bar/TopMenuBar";
import BackToHome from "../user-dahsboard/BackToHome";
import AccountManagerHelp from "../top-menu-bar/AccountManagerHelp";
import Logger from "../../core/util/Logger";
import {connect} from "react-redux";
import {getFileUploadMenuDetailsAction} from "../../actions/user/get-file-upload-menu-details-action";
import './member-file-uploading.css';
import FileCategoryDetails from "./FileCategoryDetails";
import MemberFileCategoryList from "./file-categories/MemberFileCategoryList";


class MemberFileCategoryDashboard extends Component {

    constructor() {
        super();
        this.state = {
            selected_category_data: null,
            show_account_manager_details: false
        };
    }

    componentDidUpdate() {
        if (this.props.user.authenticated && this.props.file_upload_categories === null) {
            this.props.getFileUploadMenuDetailsForUser(this.props.user.data.auth.token);
        }
    }

    componentDidMount() {
        if (this.props.user.authenticated && this.props.file_upload_categories === null) {
            this.props.getFileUploadMenuDetailsForUser(this.props.user.data.auth.token);
        }
    }

    render() {
        return (
            <div onClick={() => this.onClickShowAccountManagerDetails(this, false)}>
                <AuthenticatedComponent/>
                <LoadingSpinner/>

                <TopMenuBar/>
                <div className={'container'}>
                    {/*<div className="card content-box  ">old-one*/}
                        <div className="head-content">
                            <div className="header-bar">
                                <BackToHome/>

                                <AccountManagerHelp showAccountManager={this.onClickShowAccountManagerDetails.bind(this)}
                                                    show={this.state.show_account_manager_details}/>
                            </div>



                        <main className={"mt-4"}>
                            <div className="container">
                                <div className={"row"}>
                                    <div className="left-nav-bar">
                                        <MemberFileCategoryList
                                            selectedCategoryCallback={this.selectedCategoryCallback.bind(this)}
                                            file_upload_categories={this.props.file_upload_categories}/>
                                    </div>

                                    <div className={"right-content"}>
                                        <FileCategoryDetails category={this.state.selected_category_data}/>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }

    selectedCategoryCallback = (category) => {
        Logger.info("selected category ");
        this.setState({selected_category_data: category});
    };

    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }
}


const actions = {
    getFileUploadMenuDetailsForUser: getFileUploadMenuDetailsAction
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        file_upload_categories: state.file_upload_categories
    }
};


export default connect(mapStateToProps, actions)(MemberFileCategoryDashboard);
