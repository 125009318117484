import Logger from "../core/util/Logger";

export const memberProfileDetailsByIdReducer = (state = null, action) => {

    if (action.type === 'MEMBER_PROFILE_DETAILS_SUCCESS') {
        Logger.info("handling the success of retrieving member profile details in reducer");
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'MEMBER_PROFILE_DETAILS_FAILED') {
        Logger.info("handling the failure of retrieving member profile details  in reducer");
        return {
            success: false,
            data: action.payload
        };
    }
    else if (action.type === 'MEMBER_PROFILE_RESET') {
        Logger.info("member profile was removed from redux store");
        return null;
    }
    return state;
};
