import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {popupDisplayHandlerAction} from "../../actions/popup-display-action";
import {getVideoProcessingJobLogsAction} from "../../actions/admin/get-video-processing-job-logs-action";
import Logger from "../../core/util/Logger";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";


class QueueJobLogPopup extends Component {

    logsPolling = 0;

    componentWillUnmount() {
        clearInterval(this.logsPolling);
    }
    componentDidMount() {
        this.setState({
            liveLogsEnabled : false
        });
    }

    render() {
        if (this.props.popup && this.props.popup.data.id === 'member_view_video_processing_job_logs') {
            return (
                <div>
                    <LoadingSpinner/>
                    <div tabIndex="-1" role="dialog"
                         style={this.props.popup.data.id === 'member_view_video_processing_job_logs' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                         className="modal fade show"
                         aria-modal="true">
                        <div className="overlay">
                            <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h5 id="exampleModalLabel" className="modal-title text-center">
                                            Video Processing Queue Job Logs (Job: {this.getJobId()})
                                        </h5>
                                        <button type="button" data-dismiss="modal" aria-label="Close"
                                                onClick={this.onClosePopupHandler.bind(this)}
                                                className="close">
                                            <span aria-hidden="true">×</span></button>
                                    </div>

                                    <div className="modal-body">
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Log</th>
                                                <th>Time</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.displayLogs()}
                                            </tbody>
                                        </table>
                                    </div>
                                        {this.props.video_processing_job_logs ?
                                            <div className="modal-footer justify-content-between">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" checked={this.state.liveLogsEnabled} className="custom-control-input"
                                                           id="liveLogs"
                                                           onChange={this.switchLiveLogs.bind(this)} />
                                                    <label className="custom-control-label" htmlFor="liveLogs">Live Logs</label>
                                                </div>
                                                <div>
                                                    <div className="btn-group mr-1" role="group" aria-label="Navigation Buttons">
                                                        <a
                                                            onClick={() => this.onClickViewLogsPaginateAction('prev')}
                                                            type="button"
                                                            className="btn btn btn-outline-dark">
                                                            Prev
                                                        </a>
                                                        <a disabled className="btn btn-secondary">Page : {this.props.video_processing_job_logs.data.current_page}</a>
                                                        <a
                                                            onClick={() => this.onClickViewLogsPaginateAction('next')}
                                                            type="button"
                                                            className="btn btn btn-outline-dark">
                                                            Next
                                                        </a>
                                                    </div>
                                                    <button type="button" data-dismiss="modal"
                                                            onClick={this.onClosePopupHandler.bind(this)}
                                                            className="btn btn-secondary">Close
                                                    </button>
                                                </div>
                                            </div>
                                            :
                                            <div className="modal-footer">
                                                <button type="button" data-dismiss="modal"
                                                        onClick={this.onClosePopupHandler.bind(this)}
                                                        className="btn btn-secondary">Close
                                                </button>
                                            </div>
                                            }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return ("");
        }
    }

    onClosePopupHandler() {
        let payload = {
            id: "member_view_video_processing_job_logs",
            shouldDisplay: false
        };
        this.setState({
            liveLogsEnabled : false
        });
        clearInterval(this.logsPolling);

        this.props.handlePopupVisibility(payload);
    }

    displayLogs() {
        if (this.props.video_processing_job_logs
            && this.props.video_processing_job_logs.data
            && this.props.video_processing_job_logs.data.data) {
            return this.props.video_processing_job_logs.data.data.map((item, index) => {
                return <tr key={index}>
                    <td>{item.line_string}</td>
                    <td>{item.created_at}</td>
                </tr>
            })
        }
    }

    getJobId() {
        if (this.props.video_processing_job_logs
            && this.props.video_processing_job_logs.data
            && this.props.video_processing_job_logs.data.data
            && this.props.video_processing_job_logs.data.data[0]) {
            return this.props.video_processing_job_logs.data.data[0].job_id
        } else {
            return '-'
        }
    }

    onClickViewLogsPaginateAction = (page) => {
        let logs = this.props.video_processing_job_logs.data;
        if (page === 'prev') {
            page = logs.current_page > 1 ? logs.current_page - 1 : false
        } else if (page === 'next') {
            page = logs.current_page < logs.last_page ? logs.current_page + 1 : false
        }
        if (page) {
            this.setState({
                liveLogsEnabled : false
            });
            clearInterval(this.logsPolling);
            let jobId = this.props.popup.data.queue_job_item.id;
            Logger.info("click view logs for job id [" + jobId + "]");
            let token = this.props.user.data.auth.token;
            this.props.getVideoProcessingJobLogsAction(token, jobId, null, page);
        }
    };

    switchLiveLogs(event) {
        if (event.target.checked) {

            this.setState({
                liveLogsEnabled : true
            });
            let jobId = this.props.popup.data.queue_job_item.id;
            Logger.info("click view logs for job id [" + jobId + "]");
            let token = this.props.user.data.auth.token;
            this.logsPolling = setInterval(() => {
                this.props.getVideoProcessingJobLogsAction(token, jobId, null, 1, false);
            }, 2000);

        } else {
            this.setState({
                liveLogsEnabled : false
            });
            clearInterval(this.logsPolling);
        }
    }

}


const mapStateToProps = (state) => {
    return {
        video_processing_job_logs: state.video_processing_job_logs,
        popup: state.popup,
        user: state.user,
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    getVideoProcessingJobLogsAction: getVideoProcessingJobLogsAction
};


export default withRouter(connect(mapStateToProps, actions)(QueueJobLogPopup));
