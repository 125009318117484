
import {RestApiService} from "../core/api/RestApiService";

export const uploadProfilePicture = (profileImage) => async dispatch => {
    console.log("trying to upload profile image");
    console.log(profileImage);

    const formData = new FormData();
    formData.append('user_image', profileImage);

    let response = await RestApiService.postMultipart("/upload", formData);

    return {
        type: "UPLOAD_PROFILE_PIC",
        payload: profileImage
    };
};
