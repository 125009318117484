import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {adminResetMemberPasswordAction} from "../../../actions/admin/admin-reset-member-password-action";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";

let defaultState = {submit_change_password: false, show_password_reset_form: true, message: null};

class MemberPasswordResetPopup extends Component {

    constructor() {
        super();
        this.state = defaultState;
        this.formik = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.admin_reset_member_password != null
            && this.state.submit_change_password === true
            && this.state.message === null) {
            Logger.info("updated the message", this.props.admin_reset_member_password);
            if (this.props.admin_reset_member_password.success === true) {
                this.setState({
                    message: this.props.admin_reset_member_password.message,
                    submit_change_password: false,
                    show_password_reset_form: false
                })
            } else {
                this.setState({
                    message: this.props.admin_reset_member_password.message,
                    submit_change_password: false,
                    show_password_reset_form: true
                })
            }
        }
    }

    render() {
        return (
            <div>
                <div tabIndex="-1" role="dialog"
                     style={this.props.popup.data.id === 'member_password_reset_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                     className="modal fade show"
                     aria-modal="true">
                    <div className="overlay">
                        <div role="document" className="modal-dialog">
                            <div className="modal-content">


                                <div className="modal-header">
                                    <h5 id="exampleModalLabel" className="modal-title text-center">
                                        Reset Password
                                    </h5>

                                    <button type="button" data-dismiss="modal" aria-label="Close"
                                            onClick={this.onClosePopupHandler.bind(this)}
                                            className="close">
                                        <span aria-hidden="true">×</span></button>
                                </div>


                                <div style={this.state.message !== null ? {display: 'block'} : {display: 'none'}}
                                     className={(this.props.admin_reset_member_password != null && this.props.admin_reset_member_password.success === true) ? "alert alert-success" : "alert alert-danger"}
                                     role="alert">
                                    {this.state.message}
                                </div>

                                <div className="modal-body jrf-popup"
                                     style={this.state.submit_change_password === true ? {display: 'block'} : {display: 'none'}}>
                                    <p className="text-center">
                                        Please wait... password is being changed
                                    </p>
                                    <img src="../../../images/spinner.gif" className="loder"/>
                                </div>

                                <div
                                    style={this.state.show_password_reset_form === true ? {display: 'block'} : {display: 'none'}}>

                                    <Formik
                                        ref={this.formik}
                                        initialValues={{
                                            password: '',
                                            confirm_password: '',
                                        }}
                                        validationSchema={Yup.object().shape({
                                            password: Yup.string()
                                                .required('Password is required'),
                                            confirm_password: Yup.string()
                                                .required('Password confirmation is required').test('passwords-match', 'Password confirmation must match the password', function (value) {
                                                    return this.parent.password === value;
                                                })
                                        })}
                                        onSubmit={fields => {
                                            Logger.info("handle submission");
                                            this.onSubmitHandler(fields);
                                        }}
                                        render={({errors, status, touched}) => (
                                            <div>
                                                <Form>
                                                    <div className="modal-body">
                                                        <div className="container">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <div className="form-group">
                                                                            <label>New Password</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-8">
                                                                        <div className="form-group">
                                                                            <Field name="password" type="password"
                                                                                   style={{paddingRight: 'none !important'}}
                                                                                   className={'form-control mb-2' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                                                                            <ErrorMessage name="password" component="div"
                                                                                          className="invalid-feedback"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <div className="form-group">
                                                                            <label>Confirm Password</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-8">
                                                                        <div className="form-group">
                                                                            <Field name="confirm_password" type="password"
                                                                                   style={{paddingRight: 'none !important'}}
                                                                                   className={'form-control' + (errors.confirm_password && touched.confirm_password ? ' is-invalid' : '')}/>
                                                                            <ErrorMessage name="confirm_password"
                                                                                          component="div"
                                                                                          className="invalid-feedback"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="modal-footer pt-0">

                                                        <button type={"submit"}
                                                                className={'btn btn-primary'}>
                                                            Submit
                                                        </button>

                                                        <button type="button" data-dismiss="modal"
                                                                onClick={this.onClosePopupHandler.bind(this)}
                                                                className="btn btn-secondary">Close
                                                        </button>
                                                    </div>

                                                </Form>
                                            </div>
                                        )}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClosePopupHandler() {
        Logger.info("popup is closed");
        this.formik.current.resetForm();
        this.props.resetReduxStore('RESET_ADMIN_PASSWORD_CHANGE');
        this.setState(defaultState);

        let payload = {
            id: "member_password_reset_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }


    onSubmitHandler(data) {
        this.setState({submit_change_password: true, show_password_reset_form: false});
        this.formik.current.resetForm();
        let userId = this.props.member_profile.data.user_id;
        let token = this.props.user.data.auth.token;
        this.props.resetMemberPassword(token, userId, data);
    }
}


const mapStateToProps = (state) => {
    return {
        popup: state.popup,
        member_profile: state.member_profile,
        user: state.user,
        admin_reset_member_password: state.admin_reset_member_password
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    resetMemberPassword: adminResetMemberPasswordAction,
    popUpHandler: popupDisplayHandlerAction,
    resetReduxStore: resetReduxStoreAction
};


export default withRouter(connect(mapStateToProps, actions)(MemberPasswordResetPopup));
