import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";
import Logger from "../../../core/util/Logger";
import Modal from "react-bootstrap/Modal";
import ProgressLoader from "../../common/progress-loader/ProgressLoader";
import MessageBoxAlert from "../../common/MessageBoxAlert";
import SelfMemberOnboardForm from "./SelfMemberOnboardForm";
import {adminMemberSelfInvitationAction} from "../../../actions/admin/self-member-invitation-action";
import {userLogoutAction} from "../../../actions/logout-action";

const initialState = {
    start_sending_member_invitation: false,
    show_invitation_form: true,
    type: undefined,
    message: undefined
};


class SelfMemberOnboard extends Component {

    constructor() {
        super();
        this.formik = React.createRef();
        this.state = initialState;
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.self_member_invitation != null &&
            this.state.start_sending_member_invitation === true) {

            if (this.props.self_member_invitation.success === true) {
                Logger.info("member invitation response received",this.props.self_member_invitation);
                this.redirectToMemberRegistrationUrl(this.props.self_member_invitation.data.url);
            } else {
                Logger.info("displaying the error message");
                let newState = {
                    start_sending_member_invitation: false,
                    show_invitation_form: !this.props.self_member_invitation.success,
                    type: this.props.self_member_invitation.success ? 'success' : 'danger',
                    message: this.props.self_member_invitation.data.message
                };
                this.setState(newState);
            }
        }
    }

    render() {
        return (

            <div>
                <Modal
                    show={(this.props.popup.data.id === 'self_member_onboarding_popup' && this.props.popup.data.shouldDisplay)}
                    backdrop="static"
                    onHide={this.onClosePopupHandler.bind(this)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Self member sign up
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <MessageBoxAlert show={this.state.message !== undefined}
                                         type={this.state.type}
                                         message={this.state.message}/>

                        <div
                            style={this.state.show_invitation_form === true ? {display: 'block'} : {display: 'none'}}
                            className="form-inline">

                            <SelfMemberOnboardForm
                                show={this.state.show_invitation_form}
                                formRef={this.formik}
                                handleSelfMemberOnBoard={this.handleSelfMemberOnBoard.bind(this)}/>

                        </div>

                        <ProgressLoader
                            text={"Please wait..."}
                            display={this.state.start_sending_member_invitation === true}/>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }


    onClosePopupHandler() {
        Logger.info("popup is closed");
        this.props.resetReduxStore("RESET_NEW_MEMBER_INVITATION");
        this.setState(initialState);
        this.formik.current.resetForm();

        let payload = {
            id: "invite_new_member_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }


    handleSelfMemberOnBoard(data) {
        Logger.info("send new member invitation", data);
        this.props.resetReduxStore("ADMIN_SELF_MEMBER_INVITATION_RESET");
        this.setState({start_sending_member_invitation: true, show_invitation_form: false, message: undefined});
        let token = this.props.user.data.auth.token;
        this.props.doSelfMemberInvitation(token, data);
    }


    redirectToMemberRegistrationUrl(url) {
        if (localStorage.getItem('user-token') != null) {
            Logger.info("clear the user token");
            localStorage.removeItem('user-token');
        }
        this.props.userSignOut();
        window.open(url);
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        popup: state.popup,
        self_member_invitation: state.self_member_invitation
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    resetReduxStore: resetReduxStoreAction,
    doSelfMemberInvitation: adminMemberSelfInvitationAction,
    userSignOut: userLogoutAction
};


export default withRouter(connect(mapStateToProps, actions)(SelfMemberOnboard));
