import Logger from "../../core/util/Logger";

export const reserveNewDomainAction = (action, data = null) => async dispatch => {
    if (action === "reserve" && data != null) {
        Logger.info("action to reserve domain [" + data.domainName + "]");
        dispatch({type: "RESERVE_NEW_DOMAIN", payload: {domain: data.domainName}});
    }
    else if (action === 'reset') {
        Logger.info("action to clear/release the reserved domain");
        dispatch({type: "RESET_RESERVED_DOMAIN"});
    }
};