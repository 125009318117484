import Logger from "../core/util/Logger";

export const purchasingNewDomainReducer = (state = null, action) => {

    if (action.type === 'BUYING_NEW_DOMAIN_SUCCESS') {
        Logger.info("purchasing new domain success - reducer");
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'BUYING_NEW_DOMAIN_FAILED') {
        Logger.info("purchasing new domain failed - reducer");
        return {
            success: false,
            data: action.payload
        };
    }
    else if (action.type === 'RESET_BUYING_NEW_DOMAIN') {
        Logger.info("resetting the previous purchasing attempt");
        return null;
    }
    return state;
};
