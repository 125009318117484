import React, {Component} from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import SignUpForm from './components/sign-up/SignUpForm';
import NotFound from "./components/not-found/NotFound";
import Login from "./components/login/Login";
import ProfileEditForm from "./components/profile-edit/ProfileEditForm";
import AdminLogin from "./components/login/AdminLogin";
import AdminDashboard from "./components/admin-dashboard/AdminDashboard";
import DomainSetupMain from "./components/domain-setup/DomainSetupMain";
import SetupCompleted from "./components/domain-setup/new-domain/SetupCompleted";
import DomainConfigurations from "./components/domain-setup/existing-domain/DomainConfigurations";
import UserDashboard from "./components/user-dahsboard/UserDashboard";
import StripePayment from "./components/stripe-payment/StripePayment";
import LandingView from "./components/landing-view/LandingView";
import NewDomainPurchaseMain from "./components/domain-setup/new-domain/NewDomainPurchaseMain";
import ExistingDomainDetails from "./components/domain-setup/existing-domain/ExistingDomainDetails";
import StripeConnectInstruction from "./components/stripe-payment/StripeConnectInstruction";
import MemberFileCategoryDashboard from "./components/member-files/MemberFileCategoryDashboard";
import VideoProcessing from "./components/video-processing/VideoProcessing";
import ExerciseVideoDashboard from "./components/exercise-videos/ExerciseVideoDashboard";

class App extends Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={LandingView}/>
                    {/*<Route exact path="/sign-up" component={SignUpForm}/>*/}
                    <Route exact path="/sign-up/:id" component={SignUpForm}/>
                    <Route exact path="/profile-edit" component={ProfileEditForm}/>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/admin/login" component={AdminLogin}/>
                    <Route exact path="/admin/home" component={AdminDashboard}/>
                    <Route exact path="/domains/main" component={DomainSetupMain}/>
                    <Route exact path="/domains/new-domain" component={NewDomainPurchaseMain}/>
                    <Route exact path="/domains/existing-domain" component={ExistingDomainDetails}/>
                    {/*<Route exact path="/domains/new-domain-reserve" component={NewDomainReserve}/>*/}
                    <Route exact path="/domains/completed" component={SetupCompleted}/>
                    <Route exact path="/domains/setup" component={DomainConfigurations}/>
                    <Route exact path="/payment/stripe" component={StripeConnectInstruction}/>
                    <Route exact path="/user/home" component={UserDashboard}/>
                    <Route exact path="/member-files/dashboard" component={MemberFileCategoryDashboard}/>
                    <Route exact path="/member-files/exercise-videos" component={ExerciseVideoDashboard}/>
                    <Route exact path="/member/video-processing/:id" component={VideoProcessing}/>
                    <Route component={NotFound}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
