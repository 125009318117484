import React, {Component} from 'react';
import {connect} from "react-redux";
import VideoUploader from "./uploader/VideoUploader";
import VideoList from "./VideoList";
import 'react-toastify/dist/ReactToastify.css';


class ExerciseVideoUpload extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            show_account_manager_details: false
        };
    }

    render() {
        return (
            <div>
                <div className="card-header m-0 p-0">
                    <div className="wrapper m-0 p-0">
                        <div className="arrow-steps clearfix d-flex flex-row justify-content-around align-items-center">
                            <div className="step col-4"><span>1- Instructions</span></div>
                            <div className="step col-4"><span>2- Exercise list to record</span></div>
                            <div className="step current col-4"><span>3- Upload</span></div>
                        </div>
                    </div>
                </div>
                <VideoUploader />

                <VideoList memberId={this.props.user.data.auth.id}/>
            </div>
        );
    }


    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }
}


const actions = {
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        exercise_video_uploader: state.exercise_video_uploader
    }
};


export default connect(mapStateToProps, actions)(ExerciseVideoUpload);
