import {VideoProcessingApiService} from "../../core/api/VideoProcessingApiService";
import Logger from "../../core/util/Logger";

export const initiateVideoProcessingAction = (token, jobId, type) => async dispatch => {

    Logger.info("initiating video processing action ", type);
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let formData = new FormData();

    formData.append("type", type);

    let response = await VideoProcessingApiService.post("/projects/"+jobId+"/jobs", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("process "+type+" is initiated");
        dispatch({
            type: "INITIATE_VIDEO_PROCESSING_SUCCESS",
            payload: {
                message: "Job initiated successfully!"
            }
        });
    } else {
        Logger.info("process "+type+" is NOT initiated");
        dispatch({
            type: "INITIATE_VIDEO_PROCESSING_FAILED",
            payload: {
                message: response.data.errors
            }
        });
    }
    dispatch({type: "HIDE_LOADER_SPINNER"});
};