import React, {Component} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Logger from "../../../core/util/Logger";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";


const defaultEmailHeader = 'Welcome to MacroActive';

const defaultEmailBody = 'Hey  {user.name},\n' +
    '\n' +
    'Congratulations, it is GREAT to have you onboard with MacroActive!!!\n' +
    '\n' +
    'I am your account manager and will be your first point of contact for your new app.\n' +
    '\n' +
    'We are super excited to get started on your app. Thank you for accepting the proposal. The next steps are:\n' +
    '\n' +
    'Complete the signup process - <a href="{invitation_url}">{invitation_url}</a> \n' +
    'Join the MacroActivists Facebook Group - https://www.facebook.com/groups/macroactive/\n' +
    'Pay the invoice - Accounts will email this to you (Please email me if you do not receive it within 48 hours).\n' +
    'Hear from you soon.\n' +
    '\n' +
    'Warm Regards,\n' +
    '{admin.name} \n' +
    '{admin.phone}';

export default class MemberInvitationForm extends Component {

    render() {
        return (
            <Formik
                ref={this.props.formRef}
                initialValues={{
                    name: '',
                    email: '',
                    instagram_handle: '',
                    trello_card: '',
                    service_fee: '',
                    app_customer: '',
                    subject: defaultEmailHeader,
                    message: defaultEmailBody,
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .required("Name is required"),
                    email: Yup.string().email("Invalid email")
                        .required('Email is required'),
                    subject: Yup.string()
                        .required("Subject is required"),
                    message: Yup.string()
                        .required("Message is required")
                })}
                onSubmit={fields => {
                    Logger.info("handle submission");
                    this.props.handleInviteMember(fields);
                }}
                render={({errors, status, touched}) => (
                    <div className={'invite-popup-wrap'}>

                        <Form>
                            <Container>

                                <Row className={"mb-2"}>
                                    <Col md={2}>
                                        <div className="form-group">
                                            <label>Name</label>
                                        </div>
                                    </Col>
                                    <Col md={10}>
                                        <Field name="name" type="text"
                                               style={{paddingRight: 'none !important'}}
                                               className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="name" component="div"
                                                      className="invalid-feedback"/>
                                    </Col>
                                </Row>


                                <Row className={"mb-2"}>
                                    <Col md={2}>
                                        <div className="form-group">
                                            <label>Email</label>
                                        </div>
                                    </Col>
                                    <Col md={10}>
                                        <Field name="email" type="text"
                                               style={{paddingRight: 'none !important'}}
                                               className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="email" component="div"
                                                      className="invalid-feedback"/>
                                    </Col>
                                </Row>


                                <Row className={"mb-2"}>
                                    <Col md={2}>
                                        <div className="form-group">
                                            <label>Subject</label>
                                        </div>
                                    </Col>
                                    <Col md={10}>
                                        <Field name="subject" type="text"
                                               style={{paddingRight: 'none !important'}}
                                               className={'form-control' + (errors.subject && touched.subject ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="subject" component="div"
                                                      className="invalid-feedback"/>
                                    </Col>
                                </Row>


                                <Row className={"mb-2"}>
                                    <Col md={2}>
                                        <div className="form-group">
                                            <label>Message</label>
                                        </div>
                                    </Col>
                                    <Col md={10}>
                                        <Field name="message" component="textarea"
                                               style={{
                                                   paddingRight: 'none !important',
                                                   width: '100%',
                                                   height: '150px',
                                                   fontSize: '12px'
                                               }}
                                               className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')}/>

                                        <ErrorMessage name="message" component="div"
                                                      className="invalid-feedback"/>
                                    </Col>
                                </Row>


                                <Row className={"mb-2"} style={{marginTop: '20px'}}>
                                    <Col md={2}>
                                        <div className="form-group">
                                            <label>Instagram Handle</label>
                                        </div>
                                    </Col>
                                    <Col md={10}>

                                        <Field name="instagram_handle" type="text"
                                               style={{paddingRight: 'none !important'}}
                                               className={'form-control' + (errors.instagram_handle && touched.instagram_handle ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="instagram_handle"
                                                      component="div"
                                                      className="invalid-feedback"/>
                                    </Col>
                                </Row>



                                <Row className={"mb-2"} style={{marginTop: '20px'}}>
                                    <Col md={2}>
                                        <div className="form-group">
                                            <label>Trello Card URL</label>
                                        </div>
                                    </Col>
                                    <Col md={10}>
                                        <Field name="trello_card" type="text"
                                               style={{paddingRight: 'none !important'}}
                                               className={'form-control' + (errors.trello_card && touched.trello_card ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="trello_card" component="div"
                                                      className="invalid-feedback"/>
                                    </Col>
                                </Row>


                                <Row className={"mb-2"} style={{marginTop: '20px'}}>
                                    <Col md={2}>
                                        <div className="form-group">
                                            <label>Service Fee</label>
                                        </div>
                                    </Col>
                                    <Col md={10}>
                                        <Field name="service_fee" type="text"
                                               style={{paddingRight: 'none !important'}}
                                               className={'form-control' + (errors.service_fee && touched.service_fee ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="service_fee" component="div"
                                                      className="invalid-feedback"/>
                                    </Col>
                                </Row>


                                <Row className={"mb-2"} style={{marginTop: '20px'}}>
                                    <Col md={2}>
                                        <div className="form-group">
                                            <label>App Customer</label>
                                        </div>
                                    </Col>
                                    <Col md={10}>
                                        <Field name="app_customer" component="input"
                                               type="checkbox"/>
                                    </Col>
                                </Row>


                                <Button
                                    type={"submit"}
                                    variant="primary mb-2 mt-2 float-right">Send</Button>
                            </Container>
                        </Form>
                    </div>
                )}
            />
        );
    }
}
