import Logger from "../core/util/Logger";

export const removeEmailForwarderReducer = (state = null, action) => {

    if (action.type === 'EMAIL_FORWARDER_DELETE_SUCCESS') {
        Logger.info("handling the redux store");
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'EMAIL_FORWARDER_DELETE_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    } else if (action.type === 'EMAIL_FORWARDER_DELETE_RESET') {
        return null;
    }
    return state;
};
