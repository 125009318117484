import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const getDnsProviderByIdAction = (dnsProviderId) => async dispatch => {
    Logger.info("getting DNS provider details by id [" + dnsProviderId + "] ");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await RestApiService.get("/dns-providers/" + dnsProviderId);
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("DNS details received successfully");
        dispatch({
            type: "DNS_PROVIDER_DETAILS_RECEIVED_SUCCESS",
            payload: response.data
        });
    }
    else {
        Logger.info("error in getting DNS provider details [" + response.data + "]");
        dispatch({type: "DNS_PROVIDER_DETAILS_RECEIVED_FAILED",payload:response.data});
    }

    //loader need to be hidden, if the displayLoader is set yo true
    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch({type: "HIDE_LOADER_SPINNER"});
};