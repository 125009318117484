import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const getMemberExerciseVideosAction = (userId, token, popupDetails = null) => async dispatch => {

    Logger.info("getting member exercise videos");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});


    let response = await RestApiService.get("members/"+userId+"/exercise_videos", {Authorization: "Bearer " + token});
    Logger.info(response);

    //wait for 2 seconds
    await new Promise(resolve => setTimeout(resolve, 2000));

    if (response.status === 200) {
        Logger.info("member exercise videos received");
        dispatch({
            type: "GET_MEMBER_EXERCISE_VIDEOS_SUCCESS",
            payload: response.data
        });
        //display the related popup once the data is received
        if (popupDetails != null) {
            dispatch({
                type: "POPUP_HANDLER",
                payload: popupDetails
            });
        }
    } else {
        Logger.info("error in getting member exercise videos");
        dispatch({
            type: "GET_MEMBER_EXERCISE_VIDEOS_FAILED",
            payload: {
                message: "Error occurred while getting exercise videos. Please try again shortly"
            }
        });
    }
    dispatch({type: "HIDE_LOADER_SPINNER"});
};