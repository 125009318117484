import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const completeStripeConnectAction = (code, token) => async dispatch => {

    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let formData = new FormData();
    formData.append("code", code);

    let response = await RestApiService.post("/stripe/connect/complete", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        dispatch({
            type: "COMPLETE_STRIPE_CONNECT_SUCCESS",
            payload: {
                message: "Well done! Now your stripe account is connected"
            }
        });
    }
    else {
        dispatch({
            type: "COMPLETE_STRIPE_CONNECT_FAILED",
            payload: response.data
        });
        //loader need to be hidden, if the displayLoader is set yo true
        //wait for 1 second
        await new Promise(resolve => setTimeout(resolve, 1000));
        dispatch({type: "HIDE_LOADER_SPINNER"});
    }
};