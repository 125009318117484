import {VideoProcessingApiService} from "../../core/api/VideoProcessingApiService";
import Logger from "../../core/util/Logger";


export const getVideoProcessingActionQueueAction = (token, projectId, filter = {}, showLoadingScreen = true) => async dispatch => {
    Logger.info("getting queue for job", projectId);
    if (showLoadingScreen) {
        dispatch({type: "DISPLAY_LOADER_SPINNER"});
    }
    let limit = 15;
    if (filter.limit) {
        limit = filter.limit
    }
    let path = "/projects/"+projectId+"/jobs?limit="+limit;
    if (filter.type && filter.type !== "") {
        path += "&type="+filter.type
    }

    let response = await VideoProcessingApiService.get(path, {Authorization: "Bearer " + token});
    Logger.info(response.data);

    if (response.status === 200) {
        Logger.info("data for job queue received successfully");
        dispatch({
            type: "GET_VIDEO_PROCESSING_ACTION_QUEUE_SUCCESS",
            payload: response.data
        });
    } else {
        Logger.info("error in getting queue for job");
        dispatch({type: "GET_VIDEO_PROCESSING_ACTION_QUEUE_FAILED", payload: response.data});
    }

    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (showLoadingScreen) {
        dispatch({type: "HIDE_LOADER_SPINNER"});
    }
};