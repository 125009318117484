    import React, {Component} from 'react';
import {connect} from "react-redux";
import AuthenticatedComponent from '../../common/AuthenticatedComponent';
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import TopMenuBar from '../../top-menu-bar/TopMenuBar';
import '../domain-setup-main.css';
import Logger from "../../../core/util/Logger";
import BackToHome from "../../user-dahsboard/BackToHome";
import AccountManagerHelp from "../../top-menu-bar/AccountManagerHelp";
// import DomainNameSearch from "./search/DomainSearchForm";
// import NewDomainSearch from "./search/NewDomainSearch";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import BuyNewDomainConfirmation from "./BuyNewDomainConfirmation";
import NewDomainSearch from "./NewDomainSearch";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {reserveNewDomainAction} from "../../../actions/user/user-reserve-new-domain-action";
import {purchaseNewDomainAction} from "../../../actions/user/purchase-new-domain-action";


class NewDomainReserve extends Component {

    constructor() {
        super();
        this.state = {
            new_domain_selected: false,
            existing_domain_selected: false,
            show_account_manager_details: false
        }
    }


    // componentDidMount() {
    //     if (this.props.domain_availability == null) {
    //         this.props.history.push("/domains/new-domain-search");
    //     }
    // }


    render() {
        return (
            <div  style={this.props.display_form ? {display: 'block'} : {display: 'none'}}>
                <div className={'new-sub-domain'}>
                    <div id="new-domain-reserve">

                        <Formik
                            ref={this.props.parent_context.formik}
                            initialValues={{
                                sub_domain: 'members',
                            }}
                            validationSchema={Yup.object().shape({
                                sub_domain: Yup.string()
                                    .required('sub domain is required').matches(/[a-zA-Z0-9\\-]/, 'Invalid sub domain')
                            })}
                            onSubmit={data => {
                                this.handleReserveNewDomain(data.sub_domain);
                            }}
                            render={({errors, status, touched}) => (
                                <div>
                                    <Form>
                                        <div className="form align-items-center">
                                            <div className={"row new-domain-wrap"}>
                                                <div className={"col-md-12 change-domain "}>
                                                    <div className={"change-domain-input"}>
                                                        <div className="text-center text-input">
                                                            {
                                                                this.props.domain_availability != null && this.props.domain_availability.data.domain
                                                            }
                                                            &nbsp;
                                                            <button type={"button"}
                                                                    onClick={this.handleChangeDomain.bind(this)}
                                                                    className="btn btn-primary mb-2">Change
                                                                Domain
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <p className={"new-sub-select text-center"}> Select
                                                a name for your members area.
                                                ie:
                                                members.{this.props.domain_availability != null && this.props.domain_availability.data.domain}
                                            </p>
                                            <div className={"new-sun-domain-wrap"}>


                                                <div className={"sub-domain-input"}>
                                                    <Field name="sub_domain" type="text"
                                                           className={errors.sub_domain && touched.sub_domain ? 'form-control is-invalid' : 'form-control'}/>
                                                </div>
                                                <div className={"sub-domain-text"}>
                                                    .{this.props.domain_availability != null && this.props.domain_availability.data.domain}
                                                </div>
                                                <ErrorMessage name="sub_domain" component="div"
                                                              className="invalid-feedback"/>

                                            </div>
                                        </div>
                                        <div className="dns-footer">
                                            <button type="submit" className="btn btn-primary">Next</button>
                                        </div>
                                    </Form>
                                </div>
                            )}
                        />
                    </div>
                    <BuyNewDomainConfirmation domain_details={{
                        domain: this.props.domain_availability !== null ? this.props.domain_availability.data.domain : '',
                        sub_domain: this.state.sub_domain
                    }}/>
                </div>
                {/*<div className="dns-footer">*/}
                {/*    <button type="submit"*/}
                {/*            className="btn btn-primary">Submit Form*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
        );
    }

    // onClickDomainOption = (option) => {
    //     Logger.info("selected option [" + option + "]");
    //     if (option === 'new_domain') {
    //         this.setState({new_domain_selected: true, existing_domain_selected: false});
    //     } else if (option === 'existing_domain') {
    //         this.setState({existing_domain_selected: true, new_domain_selected: false});
    //     }
    // };


    // onClickShowAccountManagerDetails(event, display) {
    //     if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
    //         event.stopPropagation();
    //     }
    //     if (display !== false) {
    //         let currentState = this.state.show_account_manager_details;
    //         this.setState({show_account_manager_details: !currentState});
    //     } else {
    //         this.setState({show_account_manager_details: display});
    //     }
    // }


    handleReserveNewDomain(subDomain) {
        let domain = this.props.domain_availability.data.domain;
        Logger.info("proceeding with getting confirmation for purchasing domain [" + domain + "] and set up sub domain [" + subDomain + "] - page view");
        this.setState({sub_domain: subDomain});
        let payload = {
            id: "buy_new_domain_confirmation",
            shouldDisplay: true
        };
        this.props.handlePopupVisibility(payload);
    }


    handleChangeDomain() {
        Logger.info("changing the selected domain");
        // this.props.reserveNewDomain("reset");
        // this.props.history.push("/domains/new-domain-search");
        this.props.parent_context.setState({
            show_domain_search_view: true,
            show_domain_reserve_view: false
        });
    }



    // onClickNextBtn() {
    //     Logger.info("===on click next btn");
    //     if (this.props.domain_availability == null) {
    //         Logger.info("display alert");
    //     } else {
    //         Logger.info("next view");
    //     }
    // }
}


const mapStateToProps = (state) => {
    return {
        purchased_domain: state.purchased_domain,
        domain_availability: state.domain_availability,
    }
};


const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    reserveNewDomain: reserveNewDomainAction,
    purchaseNewDomain: purchaseNewDomainAction
};


export default connect(mapStateToProps, actions)(NewDomainReserve);
