import React, {Component} from 'react';

import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {userLogoutAction} from "../../actions/logout-action";

class AccountManagerHelp extends Component {

    // constructor(props, context) {
    //     super(props, context);
    //     this.state = {show_account_manager_details: false};
    // }


    render() {
        return (
            <div>
                <a href="#"
                   onClick={this.props.showAccountManager}
                   className={'support-btn'}><i className="fas fa-question-circle"/></a>
                <div style={this.props.show === true ? {display: 'block'} : {display: 'none'}}
                     className={'support-btn-popup support-box'}>
                    <p className={'support-box-title'}>Need Help?</p>
                    <p className={'support-box-subtext support-popup-subtext mb-2'}>Contact your account manager</p>
                    <p className={'support-box-subtext mb-2'}>{this.props.account_manager && this.props.account_manager.name}</p>
                    <hr/>
                    <p className={' support-box-title-popup '}>{this.props.account_manager && this.props.account_manager.email}</p>
                    <hr/>
                    <p className={'support-box-subtext mt-1 mb-1'}>{this.props.account_manager && this.props.account_manager.contact_number}</p>
                </div>
            </div>
        );
    }

    // onClickShowAccountManagerDetails() {
    //     let currentState = this.state.show_account_manager_details;
    //     this.setState({show_account_manager_details: !currentState})
    // }
}

const mapStateToProps = (state) => {
    return {
        account_manager: state.user.data.account_manager_details
    }
};

const actions = {
    userSignOut: userLogoutAction
};

export default withRouter(connect(mapStateToProps, actions)(AccountManagerHelp));
