import React, {Component} from 'react';
import Button from "react-bootstrap/Button";

export default class NewDomainVerificationEmailView extends Component {

    render() {
        return (
            <div style={this.props.show ? {display: 'block'} : {display: 'none'}}>
                                    <span>
                                        This domain is owned by MacroActive.
                                        Click Send Verification Email below and we'll initiate an email to verifications@macroactive.com
                                    </span>

                <Button
                    onClick={this.props.sendNewDomainVerification}
                    variant="primary">
                    Send Verification Email
                </Button>

            </div>
        );
    }

}
