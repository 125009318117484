import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const validateMemberRegistrationLinkAction = (invitation_hash) => async dispatch => {

    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let formData = new FormData();
    formData.append("invitation_hash", invitation_hash);

    let response = await RestApiService.post("/member-invitation/validate", formData);
    Logger.info(response);


    if (response.status === 200) {
        dispatch({
            type: "member_invitation_link_SUCCESS",
            payload: response.data
        });
    } else if (response.status === 404) {
        dispatch({
            type: "member_invitation_link_FAILED",
            payload: {
                message: 'Invalid registration link'
            }
        });
    } else if (response.status === 422) {
        dispatch({
            type: "member_invitation_link_FAILED",
            payload: {
                message: 'Registration link is expired'
            }
        });
    } else {
        dispatch({
            type: "member_invitation_link_FAILED",
            payload: {
                message: 'Something went wrong! Please try again shortly'
            }
        });
    }

    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    dispatch({type: "HIDE_LOADER_SPINNER"});
};