import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {archiveUserAction} from "../../../actions/admin/archive-user-action";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";
import {getAllRegisteredMembersAction} from "../../../actions/admin/get-registered-members-action";
import Modal from "react-bootstrap/Modal";
import MessageBoxAlert from "../../common/MessageBoxAlert";
import ExistingDomainVerificationEmailView from "./mandrill-verification/ExistingDomainVerificationEmailView";
import NewDomainVerificationEmailView from "./mandrill-verification/NewDomainVerificationEmailView";
import ProgressLoader from "../../common/progress-loader/ProgressLoader";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";

let defaultState = {start_archive_user: false, user_archived_success: false, message: null};

class ArchiveMemberConfirmationPopup extends Component {


    constructor() {
        super();
        this.state = defaultState;
    }

    componentDidMount() {
        this.setState(defaultState);
    }

    componentDidUpdate() {
        if (this.props.user_archive != null && this.state.start_archive_user === true) {
            if (this.props.user_archive.success === true) {
                this.setState({
                    start_archive_user: false,
                    user_archived_success: true,
                    message: this.props.user_archive.message
                });
            } else {
                this.setState({
                    start_archive_user: false,
                    user_archived_success: false,
                    message: this.props.user_archive.message
                });
            }
        }
    }

    render() {
        return (
            <Modal
                show={(this.props.popup.data.id === 'archive_member_confirmation_popup' && this.props.popup.data.shouldDisplay)}
                backdrop="static"
                onHide={this.onClosePopupHandler.bind(this)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Archive
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/*<div*/}
                    {/*    style={this.state.message != null && this.state.message.type === 'success' ? {display: 'block'} : {display: 'none'}}*/}
                    {/*    className={"alert alert-success"} role="alert">*/}
                    {/*    {this.state.message !== null ? this.state.message.text : ''}*/}
                    {/*</div>*/}

                    {/*<div*/}
                    {/*    style={this.state.message != null && this.state.message.type === 'error' ? {display: 'block'} : {display: 'none'}}*/}
                    {/*    className={"alert alert-danger"} role="alert">*/}
                    {/*    {this.state.message !== null ? this.state.message.text : ''}*/}
                    {/*</div>*/}


                    <MessageBoxAlert show={this.state.message !== null}
                                     type={this.state.message != null && this.state.message.type}
                                     message={this.state.message !== null ? this.state.message.text : ''}/>

                    <div
                        style={this.state.start_archive_user === false && this.state.user_archived_success === false ? {display: 'block'} : {display: 'none'}}>
                        Are you sure that you want to
                        archive {this.props.member_profile != null ? this.props.member_profile.data.full_name : ''}?
                    </div>

                    {/*<div*/}
                    {/*    style={this.state.start_archive_user === false && this.state.user_archived_success === false ? {display: 'block'} : {display: 'none'}}*/}
                    {/*    className="modal-content">*/}
                    {/*    <div className="modal-header">*/}
                    {/*        <h5 className="modal-title" id="exampleModalLabel">Archive</h5>*/}
                    {/*        <button type="button"*/}
                    {/*                onClick={this.onClosePopupHandler.bind(this)}*/}
                    {/*                className="close" data-dismiss="modal" aria-label="Close">*/}
                    {/*            <span aria-hidden="true">&times;</span>*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*    <div className="modal-body">*/}
                    {/*        Are you sure that you want to*/}
                    {/*        archive {this.props.member_profile != null ? this.props.member_profile.data.full_name : ''}?*/}
                    {/*    </div>*/}

                    {/*    /!*<div className="modal-footer">*!/*/}
                    {/*    /!*    <button type="button"*!/*/}
                    {/*    /!*            onClick={this.onClosePopupHandler.bind(this)}*!/*/}
                    {/*    /!*            className="btn btn-secondary" data-dismiss="modal">Cancel*!/*/}
                    {/*    /!*    </button>*!/*/}
                    {/*    /!*    <button type="button"*!/*/}
                    {/*    /!*            onClick={this.onClickArchiveMember.bind(this)}*!/*/}
                    {/*    /!*            className="btn btn-primary">Archive*!/*/}
                    {/*    /!*    </button>*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*    */}
                    {/*</div>*/}

                    <ProgressLoader
                        text={"Please wait... user is being archived"}
                        display={this.state.start_archive_user === true}/>

                </Modal.Body>

                <Modal.Footer>
                    <div style={this.state.start_archive_user === false &&
                        this.state.user_archived_success === true ? {display: 'block'} : {display: 'none'}}>
                        <Button
                            type={"button"}
                            onClick={this.onClosePopupHandler.bind(this)}
                            variant="secondary">Close</Button>
                    </div>

                    <div style={(this.state.start_archive_user === false &&
                        this.state.user_archived_success === false) ? {display: 'block'} : {display: 'none'}}>
                        <Button
                            type={"button"}
                            onClick={this.onClosePopupHandler.bind(this)}
                            variant="secondary mr-2">Cancel</Button>

                        <Button
                            type={"button"}
                            onClick={this.onClickArchiveMember.bind(this)}
                            variant="primary">Archive</Button>

                    </div>

                </Modal.Footer>
            </Modal>
        );
    }

    onClosePopupHandler() {
        Logger.info("member archive verification popup is closed");
        this.setState(defaultState);
        this.props.resetReduxStore("MEMBER_PROFILE_RESET");

        let payload = {
            id: "archive_member_confirmation_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);

        //this is to reflect the latest archived changes in the list
        if (this.props.user_archive != null && this.props.user_archive.success === true) {
            Logger.info("reloading the latest member list");
            this.props.resetReduxStore("MEMBER_ARCHIVE_RESET");
            this.props.getAllMembers(this.props.user.data.auth.token, this.props.filter.type, this.props.filter.status);
        }
    }


    onClickArchiveMember() {
        this.props.resetReduxStore("MEMBER_ARCHIVE_RESET");
        this.setState({start_archive_user: true});
        let userId = this.props.member_profile.data.user_id;
        let token = this.props.user.data.auth.token;
        Logger.info("request to archive member  [" + userId + "] ");
        // this.onClosePopupHandler();
        this.props.archiveUser(userId, token);
    }
}


const mapStateToProps = (state) => {
    return {
        member_profile: state.member_profile,
        popup: state.popup,
        user: state.user,
        user_archive: state.user_archive
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    archiveUser: archiveUserAction,
    resetReduxStore: resetReduxStoreAction,
    getAllMembers: getAllRegisteredMembersAction
};


export default withRouter(connect(mapStateToProps, actions)(ArchiveMemberConfirmationPopup));
