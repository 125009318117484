import React, {Component} from 'react';
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';

class InstructionsVideo extends Component {

    stepName = 'uploadStep';

    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div className="card card border-0 border-top">
                <div className="card-header m-0 p-0">
                        <div className="wrapper m-0 p-0">
                            <div className="arrow-steps clearfix d-flex flex-row justify-content-around align-items-center">
                                <div className="step current col-4"><span>1- Instructions</span></div>
                                <div className="step col-4"><span>2- Exercise list to record</span></div>
                                <div className="step col-4"><span>3- Upload</span></div>
                            </div>
                        </div>
                </div>
                <div className="card-body  my-n2">
                    <p style={{fontSize: '1.4rem'}}>
                        Watch this video to learn the best way to record your exercise videos.
                    </p>
                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%'}}>
                        <iframe
                            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                            src='https://player.vimeo.com/video/365194717' frameBorder='0' allowFullScreen/>
                    </div>
                </div>
                <div className="card-footer p-3">
                    <button
                        onClick={() => {this.next()}}
                        className="btn btn-lg btn-secondary float-right" type="button" id="toright">Next &gt;</button>
                </div>
            </div>
        );
    }

    next() {
        this.props.action(
            this.props.user.data.auth.token,
            this.stepName,
            {record_own_video_step: 1}
        )
    }
}


const actions = {
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};


export default connect(mapStateToProps, actions)(InstructionsVideo);
