import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";


export const createNewMember = (requestObject) => async dispatch => {
    Logger.info("trying to create new member");

    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    //converting request object  to form data
    const formData = new FormData();
    Object.keys(requestObject).forEach(key => formData.append(key, requestObject[key]));

    let response = await RestApiService.post("/members", formData);
    Logger.info(response);

    if (response.status === 200) {
        await handleNewMemberRegistrationSuccess(requestObject, dispatch, response);
    }
    else {
        await  handleNewMemberRegistrationFailure(dispatch, response);
    }
};


const handleNewMemberRegistrationSuccess = async (requestData, dispatch, newMemberResponse) => {

    Logger.info("complete the member registration successfully");
    //wait 2 seconds
    await new Promise(resolve => setTimeout(resolve, 2000));
    dispatch({type: "HIDE_LOADER_SPINNER"});

    dispatch({
        type: "REGISTER_MEMBER_SUCCESS",
        payload: newMemberResponse.data
    });

    //setting up user authenticated data and dispatch to reducer
    dispatch({
        type: "USER_AUTHENTICATION_SUCCESS",
        payload: newMemberResponse.data
    });
};


const handleNewMemberRegistrationFailure = async (dispatch, response) => {
    Logger.info("register new member failed");
    //wait 2 seconds
    await new Promise(resolve => setTimeout(resolve, 2000));
    dispatch({type: "HIDE_LOADER_SPINNER"});

    dispatch({
        type: "REGISTER_MEMBER_FAILED",
        payload: {
            type: 'error',
            message: response.data.message
        }
    });
};
