import React, {Component} from 'react'
import {getMemberExerciseVideosAction} from "../../actions/admin/get-member-exercise-videos-action";
import {connect} from "react-redux";
import {deleteMemberExerciseVideoAction} from "../../actions/admin/delete-member-exercise-video-action";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Logger from "../../core/util/Logger";
import {updateMemberExerciseVideoAction} from "../../actions/admin/update-member-exercise-video-action";
import {getMemberTotalVideoSizeByUserIdAction} from "../../actions/admin/get-member-total-exercise-video-size-action";

class VideoListItem extends Component{

    constructor(props) {
        super(props);
        this.state = {editingVideo: null};
        this.formik = React.createRef();
        this.onEsc = this.onEsc.bind(this);
    }

    render() {
        return (
            <tr key={this.props.key}>
                <td>
                    {this.state.editingVideo && this.state.editingVideo.name === this.props.video.name
                        ?  <div>

                            <Formik
                                ref={this.formik}
                                initialValues={
                                    {
                                        oldName: this.props.video.name,
                                        newName: this.props.video.name,
                                    }
                                }
                                validationSchema={Yup.object().shape({
                                    oldName: Yup.string()
                                        .required('Old Name is required'),
                                    newName: Yup.string()
                                        .required('New Video Name is required'),
                                })}
                                onSubmit={fields => {
                                    Logger.info("handle submission");
                                    this.onSubmitHandler(fields);
                                }}
                                render={({values, errors, status, touched}) => (
                                    <div>
                                        <Form className={"form-inline"}>

                                            <div className="form-group mr-1">
                                                <Field onKeyDown={this.onEsc} autoFocus required name="newName" type="text"
                                                       className={'form-control' + (errors.newName ? ' is-invalid' : '')}/>
                                                <Field hidden required name="oldName" type="text"
                                                       className={'form-control' + (errors.oldName ? ' is-invalid' : '')}/>
                                                <ErrorMessage name="newName" component="div"
                                                              className="invalid-feedback"/>
                                            </div>
                                            <button type="submit" className="d-none">
                                                <i className="fas fa-check"></i>
                                            </button>

                                        </Form>
                                    </div>
                                )}
                            />
                        </div>
                        :
                        <span><a href={this.props.video.url} target="_blank">{this.props.video.name}.{this.props.video.extension}</a> </span>
                    }


                </td>
                <td><i onClick={() => this.showVideoRename(this.props.video)} className="text-muted mr-3 fas fa-edit"></i> <i onClick={() => this.deleteVideo(this.props.video)} className="text-muted fas fa-trash"></i></td>
            </tr>
        )
    }


    deleteVideo(video) {
        if (window.confirm("Do you really want to delete '"+video.name+"' video?")) {
            this.props.deleteMemberExerciseVideo(
                this.props.memberId,
                video.name+'.'+video.extension,
                this.props.user.data.auth.token
            ).then(() => {
                this.props.getMemberTotalVideoSize(this.props.memberId, this.props.user.data.auth.token);
                this.props.getMemberExerciseVideos(this.props.memberId, this.props.user.data.auth.token);
            });
        }
    }

    onEsc(event) {
        if(event.keyCode === 27) {
            this.removeFileRenameInput();
        }
    }

    removeFileRenameInput() {
        this.setState({editingVideo: null});
    }

    showVideoRename(video) {
        this.setState({editingVideo: video});
    }

    onSubmitHandler(fields) {
        if (fields.oldName !== fields.newName) {
            if (window.confirm("Do you really want to update '" + fields.oldName + "' video?")) {
                fields.oldName += '.' + this.state.editingVideo.extension;
                fields.newName += '.' + this.state.editingVideo.extension;
                this.props.updateMemberExerciseVideo(
                    this.props.memberId,
                    fields,
                    this.props.user.data.auth.token
                ).then(() => {
                    this.setState({editingVideo: null});
                    this.props.getMemberExerciseVideos(this.props.memberId, this.props.user.data.auth.token);
                });
            }
        }
    }

}

const actions = {
    getMemberExerciseVideos: getMemberExerciseVideosAction,
    deleteMemberExerciseVideo: deleteMemberExerciseVideoAction,
    updateMemberExerciseVideo: updateMemberExerciseVideoAction,
    getMemberTotalVideoSize: getMemberTotalVideoSizeByUserIdAction
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        member_exercise_videos: state.member_exercise_videos,
        delete_member_exercise_video: state.delete_member_exercise_video,
        update_member_exercise_video: state.update_member_exercise_video
    }
};
export default connect(mapStateToProps, actions)(VideoListItem);
