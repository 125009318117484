export const getFileUploadMenuDetailsReducer = (state = null, action) => {

    if (action.type === 'FILE_UPLOAD_MENU_DETAILS_RECEIVED_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'FILE_UPLOAD_MENU_DETAILS_RECEIVED_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    } else if (action.type === 'RESET_FILE_UPLOAD_MENU_DETAILS') {
        return null;
    }
    return state;
};
