export const addMemberHostingDetailsReducer = (state = null, action) => {

    if (action.type === 'ADD_HOSTING_DETAILS_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'ADD_HOSTING_DETAILS_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    }
    else if (action.type === 'RESET_MEMBER_HOSTING_DETAILS') {
        return null;
    }
    return state;
};
