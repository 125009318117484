import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const adminResetMemberPasswordAction = (token, userId, data) => async dispatch => {

    Logger.info("resetting he password of member [" + userId + "]   with token [" + token + "]");

    let formData = new FormData();
    formData.append("password", data.password);
    formData.append("confirm_password", data.confirm_password);

    let response = await RestApiService.post("/admin/members/" + userId + "/password-reset", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    //wait 3 seconds
    await new Promise(resolve => setTimeout(resolve, 3000));

    if (response.status === 200) {
        Logger.info("password successfully changed");
        dispatch({
            type: "ADMIN_RESET_MEMBER_PASSWORD_SUCCESS",
            payload: {
                message: "Password has been successfully changed"
            }
        });
    } else {
        dispatch({
            type: "ADMIN_RESET_MEMBER_PASSWORD_FAILED",
            payload: {
                message: "Error occurred while changing the password. Please try again shortly"
            }
        });
    }
};