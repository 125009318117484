import React, {Component} from 'react';
import '../member-file-uploading.css';
import MemberFileCategory from "./MemberFileCategory";
import Logger from "../../../core/util/Logger";

export default class MemberFileCategoryList extends Component {

    constructor() {
        super();
        this.state = {
            // selected_category_data: null,
            selected_category_key: null
        };
    }

    componentDidMount() {

        if (this.props.file_upload_categories != null && this.state.selected_category_key === null) {
            this.selectFileCategory('logo');
        }
    }

    componentDidUpdate() {

        if(this.props.file_upload_categories != null && this.state.selected_category_key === null){
            this.selectFileCategory('logo');
        }
        // if (this.props.user.authenticated && this.props.file_upload_categories === null) {
        //     this.props.getFileUploadMenuDetailsForUser(this.props.user.data.auth.token);
        // }

        // //selecting the default file category
        // if (this.props.file_upload_categories != null && this.state.selected_category_data === null) {
        //     Logger.info("selecting file category");
        //     this.selectFileCategory(this.state.selected_category_key);
        // }
    }

    render() {
        return (
            this.props.file_upload_categories != null && this.props.file_upload_categories.data.map((menu, index) => {
                return (
                    <MemberFileCategory
                        selectFileCategory={this.selectFileCategory.bind(this)}
                        selected={menu.key === this.state.selected_category_key}
                        key={menu.id}
                        menu={menu}/>
                );
            })
        )
    }


    selectFileCategory(categoryKey) {
        Logger.info(" select file category id [" + categoryKey + "] ");
        Logger.info(this.props.file_upload_categories);
        let category = this.props.file_upload_categories.data.find(category => category.key === categoryKey);
        Logger.info("selected file category", category);
        this.props.selectedCategoryCallback(category);
        this.setState({selected_category_key: categoryKey});
    };
}
