import Logger from "../core/util/Logger";

export const getMemberExerciseVideosReducer = (state = null, action) => {

    if (action.type === 'GET_MEMBER_EXERCISE_VIDEOS_SUCCESS') {
        Logger.info("handling the success of data for member exercise videos in reduces");
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'GET_MEMBER_EXERCISE_VIDEOS_FAILED') {
        Logger.info("handling the failure of data for member exercise videos in reduce");
        return {
            success: false,
            data: action.payload
        };
    }
    else if (action.type === 'GET_MEMBER_EXERCISE_VIDEOS_RESET') {
        Logger.info("handling the reset of data for member exercise videos in reduce");
        return {
            success: false,
            data: []
        };
    }
    return state;
};