import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";
import {createNewEmailForwarderAction} from "../../../actions/admin/create-new-email-forwarder-action";
import EmailForwardersList from "./EmailForwardersList";

let defaultState = {
    submit_create_email_forwarder_request: false,
    show_create_email_forwarder_form: true,
    message: '',
    refresh_email_forwarders_list: false
};

let showForm = false;
let domainAdded = null;
let newDomain = null;

class ManageEmailForwardersPopup extends Component {

    constructor() {
        super();
        this.state = defaultState;
        this.formik = React.createRef();
    }


    componentDidUpdate() {
        //success message is received
        if (this.props.new_email_forwarder !== null &&
            this.props.new_email_forwarder.success === true &&
            this.state.submit_create_email_forwarder_request === true) {
            Logger.info("handling the success message");
            this.formik.current.resetForm();
            this.props.resetReduxStore("EMAIL_FORWARDER_LIST_RESET");
            this.setState({
                submit_create_email_forwarder_request: false,
                message_type: 'success',
                message: this.props.new_email_forwarder.data.message,
                refresh_email_forwarders_list: true
            });
        }

        // //error message is received
        if (this.props.new_email_forwarder !== null &&
            this.props.new_email_forwarder.success === false &&
            this.state.submit_create_email_forwarder_request === true) {
            Logger.info("handling the error message");
            this.setState({
                submit_create_email_forwarder_request: false,
                message_type: 'error',
                message: this.props.new_email_forwarder.data.message
            });
        }
    }

    render() {
        return (
            <div>
                <div tabIndex="-1" role="dialog"
                     style={this.props.popup.data.id === 'email_forwarders_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                     className="modal fade show"
                     aria-modal="true">
                    <div className="overlay">
                        <div role="document" className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 id="exampleModalLabel" className="modal-title text-center">
                                        Manage Email forwarders
                                    </h5>

                                    <button type="button" data-dismiss="modal" aria-label="Close"
                                            onClick={this.onClosePopupHandler.bind(this)}
                                            className="close">
                                        <span aria-hidden="true">×</span></button>
                                </div>

                                <div className="modal-body">

                                    <div
                                        style={this.state.message !== '' ? {display: 'block'} : {display: 'none'}}
                                        className={this.state.message_type !== '' && this.state.message_type === 'success' ? "alert alert-success" : "alert alert-danger"}
                                        role="alert">
                                        {
                                            this.state.message
                                        }
                                    </div>

                                    {
                                        domainAdded = (this.props.member_profile != null &&
                                            this.props.member_profile.data.domain_details !== undefined)
                                    }

                                    {
                                        newDomain = (this.props.member_profile != null &&
                                            this.props.member_profile.data.domain_details !== undefined &&
                                            this.props.member_profile.data.domain_details.domain_type === "new_domain")
                                    }

                                    {
                                        showForm = (this.state.show_create_email_forwarder_form === true &&
                                            this.state.submit_create_email_forwarder_request === false && domainAdded && newDomain)
                                    }


                                    <div className="modal-body jrf-popup"
                                         style={this.state.submit_create_email_forwarder_request === true ? {display: 'block'} : {display: 'none'}}>
                                        <p className="text-center">
                                            Please wait... sub domain is being changed
                                        </p>
                                        <img src="../../../images/spinner.gif" className="loder"/>
                                    </div>


                                    <div id={"manage-email-forwarders"}
                                         style={showForm ? {display: 'block'} : {display: 'none'}}>
                                        <div className="form-inline">
                                            <Formik
                                                ref={this.formik}
                                                initialValues={{
                                                    email: '',
                                                    mailbox_id: ''
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    mailbox_id: Yup.string()
                                                        .required('mailbox id is required'),
                                                    email: Yup.string()
                                                        .required('Email is required')
                                                        .email('Invalid email')
                                                })}
                                                onSubmit={fields => {
                                                    Logger.info("handle submission");
                                                    this.onSubmitHandler(fields);
                                                }}
                                                render={({errors, status, touched}) => (
                                                    <div className={"col-md-12"}>
                                                        <Form>
                                                            <div className={"row"}>
                                                                <div className={"form-group col-md-6"}>
                                                                    {/*<label htmlFor="firstName">Add New</label>*/}

                                                                    <Field name="mailbox_id" type="text"
                                                                           style={{paddingRight: 'none !important'}}
                                                                           placeholder="Mailbox"
                                                                           className={'form-control mb-2' + (errors.mailbox_id && touched.mailbox_id ? ' is-invalid' : '')}/>
                                                                    <ErrorMessage name="mailbox_id" component="div"
                                                                                  className="invalid-feedback"/>
                                                                </div>
                                                                <div className={"form-group col-md-6"}>
                                                                    <p className="form-control-plaintext">
                                                                        @{this.props.member_profile != null && this.props.member_profile.data.domain_details != null && this.props.member_profile.data.domain_details.domain}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className={"row"}>
                                                                <div className={"form-group col-md-12"}>
                                                                    <Field name="email" type="text"
                                                                           placeholder="Email address"
                                                                           style={{paddingRight: 'none !important'}}
                                                                           className={'form-control mb-2' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                                                    <ErrorMessage name="email" component="div"
                                                                                  className="invalid-feedback"/>
                                                                </div>
                                                            </div>
                                                            <button className="btn btn-primary mb-2">
                                                                Add
                                                            </button>
                                                        </Form>
                                                    </div>
                                                )}
                                            />

                                        </div>

                                        {/*<EmailForwardersList*/}
                                        {/*    domain={this.props.member_profile != null*/}
                                        {/*    && this.props.member_profile.data.domain_details !== undefined ?*/}
                                        {/*        this.props.member_profile.data.domain_details.domain : ''}*/}
                                        {/*    refresh_list={this.state.refresh_email_forwarders_list}/>*/}

                                        <EmailForwardersList
                                            domain_details={this.props.member_profile != null &&
                                                this.props.member_profile.data.domain_details !== undefined ? this.props.member_profile.data.domain_details : null}
                                            refresh_list={this.state.refresh_email_forwarders_list}/>

                                    </div>


                                    <div style={domainAdded === false ? {display: 'block'} : {display: 'none'}}
                                         className={"alert alert-danger"}>
                                        User has not set the domain details
                                    </div>

                                    <div style={newDomain === false ? {display: 'block'} : {display: 'none'}}
                                         className={"alert alert-danger"}>
                                        This domain is owned by the user
                                    </div>

                                </div>

                                <div className="modal-footer">
                                    <button type="button" data-dismiss="modal"
                                            onClick={this.onClosePopupHandler.bind(this)}
                                            className="btn btn-secondary">Close
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClosePopupHandler() {
        Logger.info("popup is closed");
        this.props.resetReduxStore("MEMBER_DNS_UPDATE_RESET");
        this.props.resetReduxStore("MEMBER_PROFILE_RESET");
        this.props.resetReduxStore("EMAIL_FORWARDER_LIST_RESET");
        this.formik.current.resetForm();
        this.setState(defaultState);

        let payload = {
            id: "update_member_subdomain_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }


    onSubmitHandler(data) {
        Logger.info("adding new email forwarder");
        this.setState({submit_create_email_forwarder_request: true, sub_domain: data.sub_domain});
        let token = this.props.user.data.auth.token;
        //todo add email forwarder
        this.props.createNewEmailForwarder(this.props.member_profile.data.user_id, token, data);
    }
}


const mapStateToProps = (state) => {
    return {
        popup: state.popup,
        member_profile: state.member_profile,
        user: state.user,
        new_email_forwarder: state.new_email_forwarder
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    popUpHandler: popupDisplayHandlerAction,
    createNewEmailForwarder: createNewEmailForwarderAction,
    resetReduxStore: resetReduxStoreAction
};


export default withRouter(connect(mapStateToProps, actions)(ManageEmailForwardersPopup));
