import React, {Component} from 'react';

import './loading-spinner.css';
import {connect} from "react-redux";

class LoadingSpinner extends Component {

    render() {
        return (
            <div className="overlay" style={this.props.display?{display:'block'}:{display:'none'}}>
                <div className="text">Please wait....
                    <div className="text-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        display: state.loader_spinner.display
    }
};

export default connect(mapStateToProps, {})(LoadingSpinner);