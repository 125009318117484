import Logger from "../../core/util/Logger";
import {RestApiService} from "../../core/api/RestApiService";

export const userEmailExerciseVideosFileAction = (token, email) => async dispatch => {

    Logger.info("emailing exercise video list");
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let formData = new FormData();
    formData.append("email", email);
    let result = false;
    let response = await RestApiService.post("/members/exercise_videos/step/emailExerciseList", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("Exercise Video List Emailed");
        result = response.data;
    } else {
        Logger.info("Exercise Video List Not Emailed");
    }
    dispatch({type: "HIDE_LOADER_SPINNER"});
    return result;
};