import React from 'react';

class RadionButtonGroup extends React.Component {

    render() {
        const {input, meta, options} = this.props;
        const hasError = meta.touched && meta.error;

        return (
            <div>
                {options.map(o =>
                    <div key={o.value} className="form-check">
                        <input type="radio" {...input} value={o.value} checked={o.value === input.value}
                               className="form-check-input"/>
                        <label htmlFor="exampleRadios1"
                               className="form-check-label">
                            {o.title}
                        </label>
                    </div>
                )
                }
                {hasError && <span className="validation-error">{meta.error}</span>}
            </div>
        );
    }
}


export default RadionButtonGroup;