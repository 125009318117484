import React, {Component} from 'react'
import {getMemberExerciseVideosAction} from "../../actions/admin/get-member-exercise-videos-action";
import {connect} from "react-redux";
import VideoListItem from "./VideoListItem";

class VideoList extends Component{

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user.authenticated && this.props.memberId !== null && this.props.member_exercise_videos === null) {
            console.log("getting exercise videos");
            this.props.getMemberExerciseVideos(this.props.memberId, this.props.user.data.auth.token);
        }
    }

    componentDidMount() {
        if (this.props.user.authenticated && this.props.memberId !== null) {
            console.log("getting exercise videos");
            this.props.getMemberExerciseVideos(this.props.memberId, this.props.user.data.auth.token);
        }
    }

    render() {
        return (
            <div className={"mb-3"}>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th width="80">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.displayVideos()}
                    </tbody>
                </table>
            </div>
        )
    }

    displayVideos() {
        if (this.props.member_exercise_videos
        && this.props.member_exercise_videos.success) {
            if (!this.props.member_exercise_videos.data.length) {
                return <tr>
                    <td className="text-center" colSpan="5">No videos uploaded yet</td>
                </tr>
            }
            return this.props.member_exercise_videos.data.map((video, index) => {
                return <VideoListItem memberId={this.props.memberId} key={index} video={video} />
            });
        } else {
            return <tr>
                <td className="text-center" colSpan="5">Please wait...</td>
            </tr>
        }
    }
}

const actions = {
    getMemberExerciseVideos: getMemberExerciseVideosAction
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        member_exercise_videos: state.member_exercise_videos
    }
};
export default connect(mapStateToProps, actions)(VideoList);