import React, {Component} from 'react';
import {connect} from "react-redux";
import {getVideoProcessingActionQueueAction} from "../../actions/admin/get-video-processing-action-queue-action";
import Logger from "../../core/util/Logger";
import {getVideoProcessingJobLogsAction} from "../../actions/admin/get-video-processing-job-logs-action";
import QueueJobLogPopup from "./QueueJobLogPopup";
class Queue extends Component {

    queuePolling = 0;

    constructor() {
        super();
        this.state = {queueType: null, queueLimit: 15}
    }


    componentWillUnmount() {
        clearInterval(this.queuePolling);
    }

    componentDidMount() {
        Logger.info('queue loaded', this.props);
        if (this.props.user.authenticated && this.props.projectId) {
            Logger.info("getting video processing project", this.props);
            this.props.getVideoProcessingActionQueue(this.props.user.data.auth.token, this.props.projectId, {type: this.state.queueType})
            this.queuePolling = setInterval(() => {
                this.props.getVideoProcessingActionQueue(
                    this.props.user.data.auth.token,
                    this.props.projectId,
                    {type: this.state.queueType, limit: this.state.queueLimit},
                    false
                    )
            }, 20000);
        }
    }

    render() {
        return (
            <div>
                <h4 className="d-inline">Request Queue</h4>
                <div className="float-right ml-1">
                    <select className="custom-select" onChange={this.changeQueueType} name="queue-type" id="queue-type">
                        <option value="">All Report Types</option>
                        <option value="PrepareMatchReport">PrepareMatchReport</option>
                        <option value="UpdateMatchReport">UpdateMatchReport</option>
                        <option value="ProcessMatchReport">ProcessMatchReport</option>
                        <option value="CountReport">CountReport</option>
                        <option value="UploadVideosToVimeo">UploadVideosToVimeo</option>
                    </select>
                </div>
                <div className="float-right mb-2">
                    <select className="custom-select" onChange={this.changeQueueLimit} name="queue-limit" id="queue-limit">
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                        <option value="60">60</option>
                    </select>
                </div>
                <hr />
                <table className="table">
                    <thead className="bg-light">
                    <tr>
                        <th scope="col">Request Type</th>
                        <th scope="col">Requested At</th>
                        <th scope="col">Status</th>
                        <th scope="col">Logs</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.showQueueItems()}
                    </tbody>
                </table>
                <QueueJobLogPopup />
            </div>
    );
    }

    showQueueItems() {
        let monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];
        if (this.props.queue != null && this.props.queue.success) {
            return this.props.queue.data.data.map((item, index) => {
                let created_at = new Date(item.created_at);
                return <tr key={item.id}>
                    <td>{item.type}</td>
                    <td>{monthNames[created_at.getMonth()]} {created_at.getDate()}, {created_at.getFullYear()}</td>
                    <td>{item.status}</td>
                    <td>
                        <a className="btn btn-link" onClick={() => this.onClickViewLogsAction(item)}>View</a>
                    </td>
                </tr>
            })
        }
    }


    onClickViewLogsAction(job) {
        let jobId = job.id;
        Logger.info("click view logs for job id [" + jobId + "]");
        let token = this.props.user.data.auth.token;
        let popupDetails = {
            id: "member_view_video_processing_job_logs",
            shouldDisplay: true,
            queue_job_item: job
        };
        this.props.getVideoProcessingJobLogsAction(token, jobId, popupDetails);
    };

    changeQueueType = (event) => {
        console.log(event.target.value);
        this.setState({queueType: event.target.value});
        this.props.getVideoProcessingActionQueue(
            this.props.user.data.auth.token,
            this.props.projectId,
            {type: event.target.value, limit: this.state.queueLimit}
            )
    };

    changeQueueLimit = (event) => {
        console.log(event.target.value);
        this.setState({queueLimit: event.target.value});
        this.props.getVideoProcessingActionQueue(
            this.props.user.data.auth.token,
            this.props.projectId,
            {type: this.state.queueType, limit: event.target.value}
            )
    }
}


const actions = {
    getVideoProcessingActionQueue: getVideoProcessingActionQueueAction,
    getVideoProcessingJobLogsAction: getVideoProcessingJobLogsAction
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        queue: state.video_processing_action_queue
    }
};


export default connect(mapStateToProps, actions)(Queue);
