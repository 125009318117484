import Logger from "../core/util/Logger";

export const uploadMemberFilesReducer = (state = null, action) => {

    if (action.type === 'UPLOAD_MEMBER_FILES_SUCCESS') {
        Logger.info("handling the redux store");
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'UPLOAD_MEMBER_FILES_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    } else if (action.type === 'UPLOADED_MEMBER_FILES_RESET') {
        return null;
    }
    return state;
};
