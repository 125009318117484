import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const addMemberHostingDetailsAction = (data, token) => async dispatch => {

    let formData = new FormData();
    formData.append("hosting_provider", data.hosting_provider);
    formData.append("username", data.username);
    formData.append("password", data.password);

    let response = await RestApiService.post("/members/hosting_details", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        dispatch({
            type: "ADD_HOSTING_DETAILS_SUCCESS",
            payload: {
                message: "We received the hosting details. Will be in touch with you if anything is needed. You can close out this page."
            }
        });
    }
    else {
        dispatch({
            type: "ADD_HOSTING_DETAILS_FAILED",
            payload: {
                message: response.data.message
            }
        });
    }
};
