import Logger from "../core/util/Logger";

export const handleSpinnerLoadingViewReducer = (state = {display: false}, action) => {

    if (action.type === 'DISPLAY_LOADER_SPINNER') {
        Logger.info("display loader spinner");
        return {
            display: true
        };
    }
    else if (action.type === 'HIDE_LOADER_SPINNER') {
        Logger.info("hide loader spinner");
        return {
            display: false
        };
    }
    return state;
};
