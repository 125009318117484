import {VideoProcessingApiService} from "../../core/api/VideoProcessingApiService";
import Logger from "../../core/util/Logger";


export const getVideoProcessingJobLogsAction = (token, jobId, popupDetails = null, page = 1, showLoadingScreen = true) => async dispatch => {
    Logger.info("getting details of project", jobId);
    if (showLoadingScreen) {
        dispatch({type: "DISPLAY_LOADER_SPINNER"});
    }

    let response = await VideoProcessingApiService.get("/jobs/"+jobId+"/log?page="+page,{Authorization: "Bearer " + token});
    Logger.info(response.data);

    if (response.status === 200) {
        Logger.info("data for job queue received successfully");
        dispatch({
            type: "GET_VIDEO_PROCESSING_JOB_LOGS_SUCCESS",
            payload: response.data
        });
        //display the related popup once the data is received
        if (popupDetails != null) {
            dispatch({
                type: "POPUP_HANDLER",
                payload: popupDetails
            });
        }
    } else {
        Logger.info("error in getting queue for job");
        dispatch({type: "GET_VIDEO_PROCESSING_JOB_LOGS_FAILED", payload: response.data});
    }

    //wait for 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (showLoadingScreen) {
        dispatch({type: "HIDE_LOADER_SPINNER"});
    }
};