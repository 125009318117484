import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const adminMemberSelfInvitationAction = (token, data) => async dispatch => {

    Logger.info("inviting new member by admin with token [" + token + "]");

    let formData = new FormData();
    formData.append("email", data.email);

    let response = await RestApiService.post("/admin/members/self-invitation", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    // wait 3 seconds
    await new Promise(resolve => setTimeout(resolve, 3000));

    if (response.status === 200) {
        dispatch({
            type: "ADMIN_SELF_MEMBER_INVITATION_SUCCESS",
            payload: {
                message: "Self member invitation successfully completed",
                url: response.data.url
            }
        });
    } else {
        dispatch({
            type: "ADMIN_SELF_MEMBER_INVITATION_FAILED",
            payload: {
                message: "Error occurred while making a member invitation. Please try again shortly"
            }
        });
    }
};