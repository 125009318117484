import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const addExistingDomainDetailsAction = (data, token) => async dispatch => {

    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let formData = new FormData();
    formData.append("domain", data.domain);
    formData.append("sub_domain", data.sub_domain);
    formData.append("landing_sub_domain", data.landing_sub_domain);
    formData.append("domain_type", "existing_domain");

    let response = await RestApiService.post("/domains/details", formData, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        dispatch({
            type: "ADD_EXISTING_DOMAIN_DETAILS_SUCCESS",
            payload: response.data
        });
    }
    else {
        dispatch({
            type: "ADD_EXISTING_DOMAIN_DETAILS_FAILED",
            payload: response.data
        });
        //loader need to be hidden, if the displayLoader is set yo true
        //wait for 1 second
        await new Promise(resolve => setTimeout(resolve, 1000));
        dispatch({type: "HIDE_LOADER_SPINNER"});
    }
};