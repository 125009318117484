import Logger from "../core/util/Logger";

export const getVideoProcessingPrepareMatchReportReducer = (state = null, action) => {

    if (action.type === 'GET_VIDEO_PROCESSING_PREPARE_MATCH_REPORT_SUCCESS') {
        Logger.info("handling the success of data for video processing prepare match report in reducer");
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'GET_VIDEO_PROCESSING_PREPARE_MATCH_REPORT_FAILED') {
        Logger.info("handling the failure of data for video processing prepare match report in reduce");
        return {
            success: false,
            data: action.payload
        };
    }
    return state;
};

