export const initiateVideoProcessingReducer = (state = null, action) => {

    if (action.type === 'INITIATE_VIDEO_PROCESSING_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    } else if (action.type === 'INITIATE_VIDEO_PROCESSING_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    } else if (action.type === 'INITIATE_VIDEO_PROCESSING_RESET') {
        return null;
    }
    return state;
};
