import Logger from "../../core/util/Logger";
import React, {Component} from 'react';
import {popupDisplayHandlerAction} from "../../actions/popup-display-action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";


class SizeGuidePopUp extends Component {

    render() {
        return (
            <div tabIndex="-1" role="dialog"
                 style={this.props.popup.data.id === 't_shirt_size_guide_popup' && this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                 className="modal fade show"
                 aria-modal="true">
                <div className="overlay">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" data-dismiss="modal" aria-label="Close"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="close">
                                    <span aria-hidden="true">×</span></button>
                            </div>

                            <div className="modal-body modal-shirt">
                                <img src={this.props.popup.data.url}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClosePopupHandler() {
        Logger.info("T-Shirt Size guide popup is closed");
        let payload = {
            id: "t_shirt_size_guide_popup",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }
}


const mapStateToProps = (state) => {
    return {
        popup: state.popup,
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction
};


export default withRouter(connect(mapStateToProps, actions)(SizeGuidePopUp));
