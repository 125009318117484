export const addExistingDomainDetailsReducer = (state = null, action) => {

    if (action.type === 'ADD_EXISTING_DOMAIN_DETAILS_SUCCESS') {
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'ADD_EXISTING_DOMAIN_DETAILS_FAILED') {
        return {
            success: false,
            data: action.payload
        };
    }
    else if (action.type === 'RESET_EXISTING_DOMAIN_DETAILS') {
        return null;
    }
    return state;
};
