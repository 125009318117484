import React, {Component} from 'react';
import {connect} from "react-redux";
import {initiateVideoProcessingAction} from "../../actions/admin/initiate-video-processing-action"
import MessageBoxAlert from "../common/MessageBoxAlert";
import Logger from "../../core/util/Logger";
import {getVideoProcessingActionQueueAction} from "../../actions/admin/get-video-processing-action-queue-action";

const initialState = {message: undefined, type: undefined, initiated: false};

class Action extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.initiate_video_processing_action != null && this.state.initiated) {
            Logger.info("video processing action initiation response received");
            let newState = {
                initiated: false,
                type: this.props.initiate_video_processing_action.success ? 'success' : 'danger',
                message: this.props.initiate_video_processing_action.data.message
            };
            this.setState(newState);
            setTimeout(() => {
                this.setState(initialState)
            }, 3500);
            if (this.props.initiate_video_processing_action.success) {
                this.props.getVideoProcessingActionQueue(this.props.user.data.auth.token, this.props.video_processing_project.data.id)
            }
        }
    }

    render() {
        return (
            <div className="col-md-4 col">
                <div>
                    <button aria-disabled={this.props.disabled} disabled={this.props.disabled} onClick={() => this.initiateActionOnClick(this.props.action)}
                       className={"btn btn-block btn-"+(this.props.btnColor ? this.props.btnColor : 'primary')}>
                        {this.props.label}
                        </button>
                    <p className="fc-ternary">{this.props.description}</p>
                    <small>{this.props.generatedAt}</small>
                </div>
                <MessageBoxAlert show={this.state.message !== undefined}
                                 type={this.state.type}
                                 message={this.state.message}/>
            </div>
        );
    }


    initiateActionOnClick(type) {
        if (this.props.handleClick !== undefined) {
            this.props.handleClick();
            void(0);
            return false;
        }
        if (window.confirm(this.props.confirmMessage || "Are You Sure?") == true) {
            let token = this.props.user.data.auth.token;
            this.props.initiateAction(token, this.props.video_processing_project.data.id, type)
                .then(() => {
                    this.setState({initiated: true});
                });
        }
    }

}


const actions = {
    initiateAction: initiateVideoProcessingAction,
    getVideoProcessingActionQueue: getVideoProcessingActionQueueAction,
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        video_processing_project: state.video_processing_project,
        initiate_video_processing_action: state.initiate_video_processing_action
    }
};


export default connect(mapStateToProps, actions)(Action);
