import React, {Component} from 'react';
import {connect} from "react-redux";
import AuthenticatedComponent from '../../../common/AuthenticatedComponent';
import LoadingSpinner from "../../../loading-spinner/LoadingSpinner";
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import Logger from "../../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../../actions/popup-display-action";
import {reserveNewDomainAction} from "../../../../actions/user/user-reserve-new-domain-action";
import {purchaseNewDomainAction} from "../../../../actions/user/purchase-new-domain-action";
import {withRouter} from "react-router-dom";
import {resetReduxStoreAction} from "../../../../actions/reset-redux-store-action";

class PurchaseSelectedDomainPopup extends Component {

    constructor() {
        super();
        this.formik = React.createRef();
        this.state = {sub_domain: '', purchase_domain_confirmed: false, message: ''};
    }


    async componentDidUpdate() {
        if (this.props.purchased_domain !== null
            && this.state.purchase_domain_confirmed === true
            && this.props.purchased_domain.success === false) {
            Logger.info("displaying the purchase domain confirmation view to display error", this.props.purchased_domain);
            this.setState({
                message: this.props.purchased_domain.data.message,
                purchase_domain_confirmed: false
            });
        }

        //redirect to success view
        if (this.props.purchased_domain !== null
            && this.props.purchased_domain.success === true
            && this.state.purchase_domain_confirmed === true) {
            Logger.info("well done! DNS set up is completed ");
            this.props.history.push("/domains/completed");
        }
    }


    render() {
        return (

            <div>
                <div
                    style={(this.props.reserve_domain != null && this.state.purchase_domain_confirmed === false) ? {display: 'block'} : {display: 'none'}}>

                    <AuthenticatedComponent display_loading_screen={false}/>

                    {/*<LoadingSpinner/>*/}


                    <div id="new-domain-reserve">

                        <Formik
                            ref={this.formik}
                            initialValues={{
                                sub_domain: '',
                            }}
                            validationSchema={Yup.object().shape({
                                sub_domain: Yup.string()
                                    .required('sub domain is required').matches(/[a-zA-Z0-9\\-]/, 'Invalid sub domain')
                            })}
                            onSubmit={data => {
                                this.handlePurchaseDomain(data.sub_domain);
                            }}
                            render={({errors, status, touched}) => (
                                <div>
                                    <div style={this.state.message !== '' ? {display: 'block'} : {display: 'none'}}
                                         className="alert alert-danger" role="alert">
                                        {this.state.message}
                                    </div>

                                    <p>
                                        If you select a brand new domain, we will purchase it on behalf of you and setup
                                        it up. You can always transfer it back.
                                    </p>
                                    <Form>

                                        <div className="form align-items-center">
                                            <div className={"popup-new-domain"}>
                                                <div className="poup-new-domain-text">
                                                    <div>
                                                        <i className="fas fa-check-circle"></i>
                                                        {
                                                            this.props.domain_availability != null && this.props.domain_availability.data.domain
                                                        } &nbsp;
                                                        <button type={"button"}
                                                                onClick={this.handleChangeDomain.bind(this)}
                                                                className="btn btn-primary mb-2 mt-3">Change Domain
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <p> Select a name for your members area.
                                                    ie:
                                                    members.{this.props.domain_availability != null && this.props.domain_availability.data.domain}
                                                </p>
                                            </div>


                                            <div className={"text-center"}>
                                                <div className="new-setup-domain-input">
                                                    <Field name="sub_domain" type="text"
                                                           className={+ (errors.sub_domain && touched.sub_domain ? ' is-invalid' : '')}/>
                                                </div>
                                                <div className="new-setup-domain-text">
                                                    .{this.props.domain_availability != null && this.props.domain_availability.data.domain}

                                                    <ErrorMessage name="sub_domain" component="div"
                                                                  className="invalid-feedback"/>
                                                </div>
                                            </div>





                                            <div className="col-md-12 text-center mt-4">
                                                <button type="submit"
                                                        className="btn btn-primary">Confirm
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            )}
                        />
                    </div>

                </div>

                <div className="modal-body jrf-popup"
                     style={(this.props.purchased_domain == null && this.state.purchase_domain_confirmed === true) ? {display: 'block'} : {display: 'none'}}>
                    <p className="text-center">
                        Please wait... domain is being purchased
                    </p>
                    <img src="../../../images/spinner.gif" className="loder"/>
                </div>
            </div>
        );
    }

    handlePurchaseDomain(subDomain) {
        this.props.resetReduxStore('RESET_BUYING_NEW_DOMAIN');
        let domain = this.props.domain_availability.data.domain;
        let token = this.props.user.data.auth.token;

        Logger.info("proceeding with  purchasing domain [" + domain + "] and set up sub domain [" + subDomain + "]");
        this.setState({purchase_domain_confirmed: true, message: ''});
        this.props.purchaseNewDomain(domain, subDomain, token);
    }


    handleChangeDomain() {
        Logger.info("changing the selected domain");
        this.props.reserveNewDomain("reset");
    }

}

const mapStateToProps = (state) => {
    return {
        domain_availability: state.domain_availability,
        user: state.user,
        reserve_domain: state.reserve_domain,
        purchased_domain: state.purchased_domain
    }
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    reserveNewDomain: reserveNewDomainAction,
    purchaseNewDomain: purchaseNewDomainAction,
    resetReduxStore: resetReduxStoreAction
};

export default withRouter(connect(mapStateToProps, actions)(PurchaseSelectedDomainPopup));