import Logger from "../../core/util/Logger";
import {RestApiService} from "../../core/api/RestApiService";

export const userStoreExerciseVideosStepAction = (token, step, data) => async dispatch => {

    Logger.info("storing information for  ", step);
    dispatch({type: "DISPLAY_LOADER_SPINNER"});

    let response = await RestApiService.post("/members/exercise_videos/step/"+step, data, {Authorization: "Bearer " + token});
    Logger.info(response);

    if (response.status === 200) {
        Logger.info("information related to "+step+" is stored");
        dispatch({
            type: "STORE_EXERCISE_VIDEO_STEP_SUCCESS",
            payload: {
                message: "Information stored successfully!",
                data: response.data
            }
        });
    } else {
        Logger.info("process "+step+" is NOT initiated");
        dispatch({
            type: "STORE_EXERCISE_VIDEO_STEP_FAILED",
            payload: {
                message: response.data.errors
            }
        });
    }
    dispatch({type: "HIDE_LOADER_SPINNER"});
};