import React, {Component} from 'react';
import {connect} from "react-redux";
import AuthenticatedComponent from '../common/AuthenticatedComponent';
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import TopMenuBar from '../top-menu-bar/TopMenuBar';
import './domain-setup-main.css';
import BackToHome from "../user-dahsboard/BackToHome";
import AccountManagerHelp from "../top-menu-bar/AccountManagerHelp";
import {Link} from "react-router-dom";


class DomainSetupMain extends Component {

    constructor() {
        super();
        this.state = {
            new_domain_selected: false,
            existing_domain_selected: false,
            show_account_manager_details: false
        }
    }


    render() {
        return (
            <div id="app" onClick={() => this.onClickShowAccountManagerDetails(this, false)}>

                <AuthenticatedComponent/>

                <LoadingSpinner/>

                <TopMenuBar/>

                <div className={'container'}>
                    <div className="card content-box">

                        <BackToHome/>

                        <AccountManagerHelp showAccountManager={this.onClickShowAccountManagerDetails.bind(this)}
                                            show={this.state.show_account_manager_details}/>

                        <main>

                            <div className="container text-center">
                                <div className="introing-domain">
                                    <div className="first-section">
                                        <p>Your members area will be set up under a domain. Select the type of domain
                                            you want to use. Getting a new domain will <br/>
                                            simplify the setup process for you.
                                            Domain <span>ownership will be under you</span> and you can always transfer
                                            back.</p>
                                    </div>
                                    <div className="second-section">
                                        {/*<a className="btn btn-primary" href="#">New Domain</a>*/}
                                        <Link to={"/domains/new-domain"} className="btn btn-primary">New Domain</Link>
                                        <small>Get a new domain on MacroActive</small>

                                    </div>
                                    <div className="third-section">
                                        <p>or</p>
                                        <Link to={"/domains/existing-domain"}>Use an existing domain</Link>
                                        {/*<a href="#">Use an existing domain</a>*/}
                                    </div>
                                    <div className="dns-footer">
                                        <Link to={"/user/home"} className="btn back-btn">Back</Link>
                                    </div>
                                </div>
                            </div>


                        </main>
                    </div>
                </div>
            </div>
        );
    }

    onClickShowAccountManagerDetails(event, display) {
        if (typeof event === 'object' && event.hasOwnProperty('isPropagationStopped')) {
            event.stopPropagation();
        }
        if (display !== false) {
            let currentState = this.state.show_account_manager_details;
            this.setState({show_account_manager_details: !currentState});
        } else {
            this.setState({show_account_manager_details: display});
        }
    }
}

const mapStateToProps = (state) => {
    return {
        purchased_domain: state.purchased_domain
    }
};

export default connect(mapStateToProps, {})(DomainSetupMain);
