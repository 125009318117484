import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Logger from "../../../core/util/Logger";
import {popupDisplayHandlerAction} from "../../../actions/popup-display-action";
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {resetReduxStoreAction} from "../../../actions/reset-redux-store-action";
import {shareDnsSetUpInstructionsAction} from "../../../actions/admin/share-dns-setup-instructions-action";

let initialState = {message_type: '', message: '', share_dns_instructions_submitted: false};

class ShareDnsSetupInstructionsPopup extends Component {

    constructor() {
        super();
        this.state = initialState;
        this.formik = React.createRef();
    }


    componentDidUpdate() {
        if (this.props.share_dns_instructions != null && this.state.share_dns_instructions_submitted === true) {

            //get the success message
            if (this.props.share_dns_instructions.success === true) {
                Logger.info("handling the success message");
                this.setState({
                    message_type: 'success',
                    message: this.props.share_dns_instructions.data.message,
                    share_dns_instructions_submitted: false
                });
            }
            //get the error message
            if (this.props.share_dns_instructions.success === false) {
                Logger.info("handling the error message", this.props.share_dns_instructions);
                this.setState({
                    message_type: 'error',
                    message: this.props.share_dns_instructions.data.message,
                    share_dns_instructions_submitted: false
                });
            }
        }
    }


    render() {
        return (
            <div tabIndex="-1" role="dialog"
                 style={this.props.popup.data.id === 'share_dns_setup_instructions' &&
                 this.props.popup.data.shouldDisplay ? {display: 'block'} : {display: 'none'}}
                 className="modal fade show"
                 aria-modal="true">
                <div className="overlay">
                    <div role="document" className="modal-dialog help-dns-modal share-dns-instruction-modal">


                        <div
                            style={this.state.share_dns_instructions_submitted === true ? {display: 'none'} : {display: 'block'}}
                            className="modal-content">
                            <div className="modal-header share-dns-instruction-head">
                                <h5 id="exampleModalLabel" className="modal-title text-center">
                                    Email instructions to your tech person
                                </h5>
                                <button type="button" data-dismiss="modal" aria-label="Close"
                                        onClick={this.onClosePopupHandler.bind(this)}
                                        className="close">
                                    <span aria-hidden="true">×</span></button>
                            </div>
                            {/*<div className={"share-dns-instruction-wrap"}>*/}
                            {/*    <p>*/}
                            {/*    Share the DNS setup instructions with your tech person.*/}
                            {/*</p>*/}
                            {/*</div>*/}


                            <div
                                style={this.state.share_dns_instructions_submitted === false ? {display: 'block'} : {display: 'none'}}>


                                <div
                                    style={(this.state.message !== '') ? {display: 'block'} : {display: 'none'}}
                                    className={this.state.message_type === 'success' ? 'help-dns-modal-text alert alert-success' : 'help-dns-modal-text alert alert-danger'}
                                    role="alert">
                                    {this.state.message}

                                    <div
                                        style={(this.state.message_type === 'success') ? {display: 'block'} : {display: 'none'}}>
                                    </div>
                                </div>

                                <div
                                    style={this.state.message_type !== '' && this.state.message_type === 'success' ? {display: 'none'} : {display: 'block'}}>

                                    <Formik
                                        ref={this.formik}
                                        initialValues={{
                                            email: '',
                                            note: '',
                                        }}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string()
                                                .required('email is required')
                                                .email("invalid email")
                                        })}
                                        onSubmit={data => {
                                            this.onFormSubmitHandler(data);
                                        }}
                                        render={({errors, status, touched}) => (

                                            <Form className={"mt-5"}>
                                                <div className="form-group align-items-center">
                                                    <div className={"row form-sec"}>
                                                        <div className="col-md-4">
                                                            <label htmlFor="hosting_provider">Email</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <Field name="email" type="text"
                                                                   className={'form-control mb-2' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                                            <ErrorMessage name="email" component="div"
                                                                          className="invalid-feedback"/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group align-items-center">
                                                    <div className={"row form-sec"}>
                                                        <div className="col-md-4">
                                                            <label htmlFor="username">Note (Optional)</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <Field name="note" component={"textarea"}
                                                                   className={'form-control mb-2'}/>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="modal-footer">
                                                    <button type="button" data-dismiss="modal"
                                                            onClick={this.onClosePopupHandler.bind(this)}
                                                            className="btn btn-secondary">Close
                                                    </button>
                                                    <button type="submit" className="btn btn-primary">
                                                        Send
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            style={this.state.share_dns_instructions_submitted === true ? {display: 'block'} : {display: 'none'}}
                            className="modal-content">

                            <div className="modal-body jrf-popup">
                                <p className="text-center">
                                    Please wait... your request is being submitted
                                </p>
                                <img src="../../../images/spinner.gif" className="loder"/>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }


    async onFormSubmitHandler(data) {
        Logger.info(" form submitted ", data);
        await this.props.resetReduxStore('RESET_SHARE_DNS_SETUP_INSTRUCTIONS');
        this.setState({share_dns_instructions_submitted: true, message: '', message_type: ''});
        await new Promise(resolve => setTimeout(resolve, 2000));
        let token = this.props.user.data.auth.token;
        this.props.shareDnsSetupInstructions(this.props.user_id, data, token);
    }

    onClosePopupHandler() {
        Logger.info("sharing screen is closed");
        this.formik.current.resetForm();
        this.setState(initialState);
        let payload = {
            id: "share_dns_setup_instructions",
            shouldDisplay: false
        };
        this.props.handlePopupVisibility(payload);
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        member_profile: state.member_profile,
        popup: state.popup,
        share_dns_instructions: state.share_dns_instructions,
    };
};

const actions = {
    handlePopupVisibility: popupDisplayHandlerAction,
    resetReduxStore: resetReduxStoreAction,
    shareDnsSetupInstructions: shareDnsSetUpInstructionsAction
};


export default withRouter(connect(mapStateToProps, actions)(ShareDnsSetupInstructionsPopup));
