import React, {Component} from 'react';
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import './steps.css'
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Logger from "../../../core/util/Logger";
import {userDownloadExerciseVideosFileAction} from "../../../actions/user/user-download-exercise-videos-file-action";
import {toast} from "react-toastify";
import {userEmailExerciseVideosFileAction} from "../../../actions/user/user-email-exercise-videos-file-action";

class InstructionsVideo extends Component {

    stepName = 'uploadStep';


    constructor(props, context) {
        super(props, context);
        this.state = {nextAllowed: false}
    }

    render() {
        return (
            <div className="card card border-0 border-top">
                <div className="card-header m-0 p-0">
                    <div className="wrapper m-0 p-0">
                        <div className="arrow-steps clearfix d-flex flex-row justify-content-around align-items-center">
                            <div className="step col-4"><span>1- Instructions</span></div>
                            <div className="step current col-4"><span>2- Exercise list to record</span></div>
                            <div className="step col-4"><span>3- Upload</span></div>
                        </div>
                    </div>
                </div>
                <div className="card-body  my-n2">
                    <p style={{fontSize: '1.4rem'}}>
                        Download the list of MacroActive default exercises
                    </p>
                    <div className="d-flex flex-column align-items-center py-5 px-5 text-center">
                        <div>
                            <h5>Download</h5>
                            <div className="d-flex flex-row">
                                <button onClick={() => this.downloadFile('pdf')} className="btn btn-info mr-2 px-5 text-white">PDF</button>
                                <button onClick={() => this.downloadFile('xlx')} className="btn btn-info px-5 text-white">Excel</button>
                            </div>
                        </div>
                        <div className="d-flex flex-row col-3 align-items-center text-center py-5">
                            <span className="bg-dark col-4" style={{height: '1px'}}/>
                            <span className="col-3 text-dark">OR</span>
                            <span className="bg-dark col-4" style={{height: '1px'}}/>
                        </div>
                        <div>

                            <Formik
                                ref={this.formik}
                                initialValues={{
                                    userEmail: ''
                                }}
                                validationSchema={Yup.object().shape({
                                    userEmail: Yup.string()
                                        .required("Email is required"),
                                })}
                                onSubmit={fields => {
                                    Logger.info("handle submission");
                                    this.emailFile(fields);
                                }}
                                render={({errors, status, touched}) => (
                                    <Form>
                                        <div className="second-section">
                                            <h5>Send to my email</h5>
                                            <div className="input-feild-width">
                                                <div className="input-group mb-3 mandrill-input">

                                                    <Field name="userEmail" type="text"
                                                           className={'form-control' + (errors.userEmail && touched.userEmail ? ' is-invalid' : '')}/>

                                                    <ErrorMessage name="userEmail" component="div"
                                                                  className="invalid-feedback"/>
                                                </div>
                                            </div>

                                            <button type={"submit"} className="btn btn-info px-5 text-white">
                                                Send
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                    </div>

                </div>
                <div className="card-footer p-3">
                    <button
                        onClick={() => {this.next()}}
                        className="btn btn-lg btn-secondary float-right" type="button">Next &gt;</button>
                </div>
            </div>
        );
    }

    downloadFile(type) {
        this.props.downloadExerciseVideoList(
            this.props.user.data.auth.token,
            type
        ).then((response) => {
            if (response) {
                let link = document.createElement('a');
                link.href = response.file;
                link.setAttribute('download', 'exerciseList.'+type); //any other extension
                link.setAttribute('target', '_blank'); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
                this.setState({nextAllowed: true})
            } else {
                toast.warn('Some error occurred, try some other option');
            }
        });
    }

    emailFile(fields) {
        this.props.emailExerciseVideoList(
            this.props.user.data.auth.token,
            fields.userEmail
        ).then((response) => {
            if (response) {
                this.setState({nextAllowed: true});
                toast.info('Email is sent to "'+fields.userEmail+'"')
            } else {
                toast.warn('Some error occurred, try some other option');
            }
        });
    }

    next() {
        if (this.state.nextAllowed) {
            this.props.action(
                this.props.user.data.auth.token,
                this.stepName,
                {record_own_video_step: 2}
            )
        } else {
            toast.warn('Please get list of exercise videos list first.')
        }
    }
}


const actions = {
    downloadExerciseVideoList : userDownloadExerciseVideosFileAction,
    emailExerciseVideoList : userEmailExerciseVideosFileAction
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};


export default connect(mapStateToProps, actions)(InstructionsVideo);
