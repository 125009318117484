import Logger from "../core/util/Logger";

export const deleteMemberExerciseVideoReducer = (state = null, action) => {

    if (action.type === 'DELETE_MEMBER_EXERCISE_VIDEOS_SUCCESS') {
        Logger.info("handling the success of delete for member exercise videos in reducer");
        return {
            success: true,
            data: action.payload
        };
    }
    else if (action.type === 'DELETE_MEMBER_EXERCISE_VIDEOS_FAILED') {
        Logger.info("handling the failure of delete for member exercise videos in reducer");
        return {
            success: false,
            data: action.payload
        };
    }
    return state;
};