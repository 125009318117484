import {RestApiService} from "../../core/api/RestApiService";
import Logger from "../../core/util/Logger";

export const storeVideoProcessingProjectAction = (token, userId, projectId) => async dispatch => {

    Logger.info("storing video processing project id", projectId);

    let formData = new FormData();
    formData.append("project_id", projectId);

    let response = await RestApiService.post("admin/members/add_video_processing/"+userId, formData, {Authorization: "Bearer " + token});
    Logger.info(response);
    //wait for 2 seconds
    await new Promise(resolve => setTimeout(resolve, 2000));

    if (response.status === 200) {
        Logger.info("video processing project id stored");
        dispatch({
            type: "STORE_VIDEO_PROCESSING_PROJECT_ID_SUCCESS",
            payload: {
                message: response.data.message
            }
        });
    } else {
        Logger.info("error in storing processing project id");
        dispatch({
            type: "STORE_VIDEO_PROCESSING_PROJECT_ID_FAILED",
            payload: response.data.message
        });
    }
};